import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import agent from '../../../app/api/agent';
import { Confirmation } from '../../../app/models/confitmation';
import { OrderApproveDetails } from '../../../app/models/orderApproveDetails';
import i18n from '../../../i18Next';
import ConfirmModal from '../../common/ConfirmModal';
import LoadingDataComponent from '../../common/LoadingDataComponent';
import OrderDetailsPanelApproveWinner from './OrderDetailsPanelApproveWinner';

export default function OPage() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const { t } = useTranslation();
    const [model, setModel] = useState<OrderApproveDetails | null>(null);
    const [loadingData, setLoading] = useState(true);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [confirmationType, setConfirmationType] = useState<'accept' | 'reject'>('accept');
    const qs = query.get('qs')?.toString();

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [])

    const loadData = () => {
        agent.ManageDirectlyFromEmail.orderDetailsForApprove(qs!)
            .then((reponse) => {
                setModel(reponse);
                i18n.changeLanguage(reponse.langId.toLowerCase());
            })
            .finally(() => setLoading(false))
    }

    const showConfirmationModal = (confirmationType: 'accept' | 'reject') => {
        setConfirmationType(confirmationType);
        setShowConfirmPopup(true);
    }

    const confirmChoice = () => {
        var dto: Confirmation = {
            IsConfirmed: confirmationType === 'accept'
        }

        agent.ManageDirectlyFromEmail.approveOrder(qs!, dto)
            .then(() => {
                dto.IsConfirmed ? toast.success(t("priceApprovedSuccessfully")) : toast.success(t("priceRejectedSuccessfully"));
                setShowConfirmPopup(false);
                loadData();
            })
            .catch(() => {
                dto.IsConfirmed ? toast.error(t("priceApprovedUnSuccessfully")) : toast.error(t("priceRejectedUnSuccessfully"));
            })
    }

    return (
        <>
            <Container fluid className='my-3'>
                <Row className='justify-content-center g-3 mt-2'>
                    {loadingData ?
                        <Col xs={12}>
                            <LoadingDataComponent height="300px" />
                        </Col>
                        :
                        <Col xs={12} xl={11} xxl={10}>
                            <div className="shadow rounded px-3 pt-3 h-100 bg-white text-center">
                                {model ?
                                    <div>
                                        <Row>
                                            <Col xs={12}>
                                                <OrderDetailsPanelApproveWinner model={model} />
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} className="text-center">
                                                <Button
                                                    variant="success"
                                                    className="mx-1"
                                                    disabled={model.userAlreadyMadeDecision || model.isCanceled}
                                                    onClick={() => showConfirmationModal('accept')}>
                                                    {t("accept")}
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    className="mx-1"
                                                    disabled={model.userAlreadyMadeDecision || model.isCanceled}
                                                    onClick={() => showConfirmationModal('reject')}>
                                                    {t("reject")}
                                                </Button>
                                            </Col>
                                        </Row>
                                        {model.userAlreadyMadeDecision ?
                                            <Row className="my-3">
                                                <Col xs={12} className="text-center text-success fw-bold">
                                                    <p>{t("thankForDecision")}</p>
                                                </Col>
                                            </Row>
                                            : model.isCanceled ?
                                                <Row className="my-3">
                                                    <Col xs={12} className="text-center text-danger fw-bold">
                                                        <p>{t("orderCancelled")}</p>
                                                    </Col>
                                                </Row>
                                                :
                                                undefined
                                        }
                                    </div>
                                    : null}
                            </div>
                        </Col>
                    }
                </Row>
            </Container>
            <ConfirmModal confirmationButtonVariant={confirmationType === 'accept' ? "success" : "danger"} message={confirmationType === 'accept' ? t("acceptConfirm") : t("rejectConfirm")} show={showConfirmPopup} confirmAction={confirmChoice} setShow={setShowConfirmPopup} />
        </>
    )
}