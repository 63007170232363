import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';

interface Props {
    errorList: string[]
}

export default function ValidationErrorList({ errorList }: Props) {

    return (
        <ListGroup variant="flush">
            {errorList.map((error: any, i) => (
                <ListGroup.Item key={i} className="text-danger fw-bold text-center">
                    <span style={{ fontSize: 22 }}><ExclamationTriangle /></span>
                    <span className="ms-1">{error}</span>
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}