import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../app/store/store';
import PlayOffForm from './PlayOffForm';
import PriceTable from './PricesTable';

export default observer(function PlayOffPanel() {
    const { finishedAuctionsStore } = useStore();
    const { t } = useTranslation();

    const submitDisabled = finishedAuctionsStore.selectedPlayOffPrices.length < 2;

    return (
        <>
            <Row>
                <PriceTable selectingMode="playOff" />
            </Row>
            <Row>
                <PlayOffForm SubmitDisabled={submitDisabled} />
            </Row>
            {submitDisabled ? 
            <Row className="mt-3">
                <Col xs={12} className="text-center fw-bold text-info">
                    <span style={{ fontSize: 22 }}><InfoCircle /></span>
                    <span className="ms-1">{t("makePlayoffInfo")}</span>
                </Col>
                </Row>
                
                :undefined} 
        </>
    )
})