import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    height?: string | undefined
}

export default function LoadinDataComponent({ height = undefined }: Props) {
    const { t } = useTranslation();

    return (
        <div className="pt-2 text-center" >
            <div style={{ height: height }} className="d-flex flex-column">
                <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                    <Row>
                        <Col xs={12}>
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                        <Col xs={12}>
                            {t('loadingData')}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}