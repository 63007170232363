import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import agent from '../../app/api/agent';
import { CancellationReason } from '../../app/models/cancellationReason';
import storage from '../../app/store/storage';
import { useStore } from '../../app/store/store';

export default function CancelAuctionPanel() {
    const { finishedAuctionsStore } = useStore();
    const [cancellationReasons, setCancellationReasons] = useState<CancellationReason[]>([]);
    const [selectedReason, setSelectedReason] = useState<string>("X")

    const { t } = useTranslation();

    function getCancellationReasons() {
        agent.Selections.cancellationReason().then(response => {
            setCancellationReasons(response);
        });
    }

    function handleSelectChange(e: any) {
        setSelectedReason(e.target.value);
    }

    useEffect(() => {
        getCancellationReasons();
    }, [])

    function handleCancelAuction() {
        finishedAuctionsStore.cancelAuction(selectedReason)
            .then(() => toast.success(t("auctionCancelledSuccessfully")))
            .catch(() => toast.error(t("auctionCancelledUnsuccessfully")))
    }

    const lang = storage.Lang.get();
    const getCancellationReasonText = (reason: CancellationReason) => {
        switch (lang) {
            case "en":
                return reason.cancelationReasonEn;
            case "pl":
                return reason.cancelationReasonPl;
            case "de":
                return reason.cancelationReasonDe;
            default:
                return reason.cancelationReasonEn;
        }
    }

    return (
        <>
            <Row>
                <Col xs={12} className="text-center">
                    <p className="my-4 fs-5 fw-bold text-danger">{t("askConfirmCancelAuction")}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label htmlFor="cancellationReason">{t("selectCancelationReason")}:</Form.Label>
                    <Form.Select id="cancellationReason" aria-label="Cancellation reasons" onChange={handleSelectChange}>
                        <option value="X">{t("chooseReason")}</option>
                        {cancellationReasons.map(cr => (
                            <option key={cr.cancelationReasonId} value={cr.cancelationReasonId}>{getCancellationReasonText(cr)}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="text-center">
                    <Button
                        disabled={selectedReason === "X"}
                        variant="danger"
                        onClick={() => handleCancelAuction()}
                        className="mx-1" >
                        {t("yes")}
                    </Button>
                    <Button
                        variant="secondary"
                        className="mx-1"
                        onClick={() => finishedAuctionsStore.setSelectedAuction(null)}>
                        {t("cancel")}
                    </Button>
                </Col>
            </Row>
        </>
    )
}
