import { makeAutoObservable } from "mobx";
import moment from "moment";
import agent from "../api/agent";
import { downloadFile } from "../api/downloadFile";
import { OrderDetailsForHistory } from "../models/orderDetailsForHistory";
import { OrderHistoryListItem } from "../models/orderHistoryListItem";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsHistoryTable } from "../models/queryParamsHistoryTable";

export class HistoryOrdersStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingData = true;
    setLoadingData = (loadinData: boolean) => this.loadingData = loadinData;

    historyOrders: OrderHistoryListItem[] = [];
    setHistoryOrders = (orders: OrderHistoryListItem[]) => this.historyOrders = orders;

    pagination: PaginationModel | null = null
    setPagination = (pagination: PaginationModel) => this.pagination = pagination;

    pagingParams: QueryParamsHistoryTable = new QueryParamsHistoryTable(1, 10, "CreateDateTime", "DESC");
    setPagingParams = (params: QueryParamsHistoryTable) => this.pagingParams = params;

    selectedOrderId: number | null = null;
    setSelectedOrderId = (orderId: number | null) => this.selectedOrderId = orderId;   

    attachmentsModalOrderID: number | null = null;
    setAttachmentsModalOrderId = (selectedOrderId: number | null) => this.attachmentsModalOrderID = selectedOrderId;

    loadingDetails: Boolean = false;
    setLoadingDetails = (val: Boolean) => this.loadingDetails = val;

    orderDetails: OrderDetailsForHistory | null = null;
    setOrderDetails = (order: OrderDetailsForHistory | null) => this.orderDetails = order;

    generatingFile: Boolean = false;
    setGeneratingFile = (val: Boolean) => this.generatingFile = val;

    loadHistoryOrders = () => {
        try {
            return agent.Orders.history(this.getAxiosParams())
                .then(response => {
                    this.setHistoryOrders(response.data);
                    this.setPagination(response.pagination);
                })
                .then(() => this.setLoadingData(false))
        } catch (e) {
            console.log(e);
        }
    }

    loadOrderDetails = (id: number | null) => {
        if (id !== null) {
            this.setLoadingDetails(true);

            agent.Orders.historyDetails(id)
                .then((response) => {
                    this.setOrderDetails(response);
                })
                .catch(() => this.setSelectedOrderId(null))
                .finally(() => this.setLoadingDetails(false))
        }
    }

    getXLSHistoryReport = (lang: string) => {
        this.setGeneratingFile(true);
        agent.Documents.orderHistoryXLS(this.getXLSReportParams(), lang)
            .then((response) => downloadFile(response, 'report.xls'))
            .then(() => this.setGeneratingFile(false))
    }

    getXLSMailingReport = (lang: string) => {
        this.setGeneratingFile(true);
        agent.Documents.orderMailingReportXLS(this.orderDetails?.orderId!, lang)
            .then((response) => downloadFile(response, 'mailingHistory.xls'))
            .then(() => this.setGeneratingFile(false))
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }

        if (this.pagingParams.searchPhrase !== undefined) {
            params.append('searchPhrase', this.pagingParams?.searchPhrase.toString());
        }

        if (this.pagingParams.searchIn !== undefined) {
            params.append('searchIn', this.pagingParams?.searchIn.toString());
        }

        if (this.pagingParams.status !== undefined) {
            params.append('status', this.pagingParams?.status.toString());
        }

        if (this.pagingParams.dateField !== undefined) {
            params.append('dateField', this.pagingParams?.dateField.toString());
        }

        if (this.pagingParams.dateFrom !== undefined) {
            if (moment(this.pagingParams?.dateFrom, moment.ISO_8601, true).isValid() === false) {
                params.append('dateFrom', this.pagingParams?.dateFrom.toString());
            }
            else {
                params.append('dateFrom', new Date(this.pagingParams?.dateFrom).toISOString());
            }
        }

        if (this.pagingParams.dateTo !== undefined) {
            if (moment(this.pagingParams?.dateTo, moment.ISO_8601, true).isValid() === false) {
                params.append('dateTo', this.pagingParams?.dateTo.toString());
            }
            else {
                params.append('dateTo', new Date(this.pagingParams?.dateTo).toISOString());
            }
        }
        return params;
    }

    getXLSReportParams = () => {
        const params = new URLSearchParams();

        if (this.pagingParams.searchPhrase !== undefined) {
            params.append('searchPhrase', this.pagingParams?.searchPhrase.toString());
        }

        if (this.pagingParams.searchIn !== undefined) {
            params.append('searchIn', this.pagingParams?.searchIn.toString());
        }

        if (this.pagingParams.status !== undefined) {
            params.append('status', this.pagingParams?.status.toString());
        }

        if (this.pagingParams.dateField !== undefined) {
            params.append('dateField', this.pagingParams?.dateField.toString());
        }

        if (this.pagingParams.status !== undefined) {
            params.append('status', this.pagingParams?.status.toString());
        }

        if (this.pagingParams.dateFrom !== undefined) {
            if (moment(this.pagingParams?.dateFrom, moment.ISO_8601, true).isValid() === false) {
                params.append('dateFrom', this.pagingParams?.dateFrom.toString());
            }
            else {
                params.append('dateFrom', new Date(this.pagingParams?.dateFrom).toISOString());
            }
        }

        if (this.pagingParams.dateTo !== undefined) {
            if (moment(this.pagingParams?.dateTo, moment.ISO_8601, true).isValid() === false) {
                params.append('dateTo', this.pagingParams?.dateTo.toString());
            }
            else {
                params.append('dateTo', new Date(this.pagingParams?.dateTo).toISOString());
            }
        }

        return params;
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsHistoryTable(this.pagingParams.pageNumber, this.pagingParams.pageSize, fieldName, sortDirection, this.pagingParams.searchPhrase, this.pagingParams.searchIn, this.pagingParams.dateFrom, this.pagingParams.dateTo, this.pagingParams.dateField, this.pagingParams.status)
        );
        this.loadHistoryOrders();
    }

    changePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsHistoryTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection, this.pagingParams.searchPhrase, this.pagingParams.searchPhrase, this.pagingParams.dateFrom, this.pagingParams.dateTo, this.pagingParams.dateField, this.pagingParams.status))
        this.loadHistoryOrders();
    }
}