import React, { ChangeEvent, useEffect, useState } from 'react';
import agent from '../../app/api/agent';
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { useStore } from '../../app/store/store';
import { useTranslation } from 'react-i18next';
import { pl, enGB, de } from "date-fns/locale";
import { toast } from 'react-toastify';
import AddingPartsModule from '../common/AddingPartsModule';
import AddingPackageModule from '../common/AddingPackageModule';
import IntegerInput from '../common/IntegerInput';
import SelectSupplierModal from './SelectSupplierModal';
import UploadFiles from '../../app/api/UploadFileModule';
import ValidationErrorList from '../error/ValidationErrorList';
import { fixTimezoneForOnChangeCalendarEvent, fixTimezoneForSelectedCalendarDate } from '../common/functions/dateTimeManipulation';
import { getBillToName, getModeName, getOrderReasonName, getResponsiblePartyName, getTransportTypeName } from '../common/functions/langObjectTranslation';
import { getNewGuid } from '../common/functions/GUID';
import { Formik, FormikHelpers } from 'formik';
import AuctionCreateValidator from '../../app/models/validators/auctionCreateDtoValidator';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AuctionCreateDto } from '../../app/models/auctionCreateDto';
import { BillTo } from '../../app/models/billTo';
import { Currency } from '../../app/models/currency';
import { Dock } from '../../app/models/dock';
import { FollowUpListItem } from '../../app/models/FollowUpListItem';
import { Mode } from '../../app/models/mode';
import { OrderReason } from '../../app/models/orderReasonDto';
import { PackageCreateDto } from '../../app/models/packageCreateDto';
import { PartCreateDto } from '../../app/models/partCreateDto';
import { ResponsibleParty } from '../../app/models/responsibleParty';
import { SupplierCountry } from '../../app/models/supplierCountry';
import { TransportType } from '../../app/models/transportType';
import storage from '../../app/store/storage';

export default function NewAuctionPage() {
    const { userStore } = useStore();
    const { t } = useTranslation();
    registerLocale("pl", pl);
    registerLocale("en", enGB);
    registerLocale("de", de);
    const lang = storage.Lang.get();

    const [sessionId, setSessionId] = useState<string>(getNewGuid());

    // ddl states
    const [billToItems, setBillToItems] = useState<BillTo[]>([]);
    const [countries, setCountries] = useState<SupplierCountry[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([])
    const [docks, setDocks] = useState<Dock[]>([]);
    const [followUps, setFollowUps] = useState<FollowUpListItem[]>([]);
    const [orderPrimaryReasons, setOrderPrimaryReasons] = useState<OrderReason[]>([]);
    const [orderSecondaryReasons, setOrderSecondaryReasons] = useState<OrderReason[]>([]);
    const [orderSecondaryReasonsForSelectedPrimary, setOrderSecondaryReasonsForSelectedPrimary] = useState<OrderReason[]>([]);
    const [responsibibleParties, setReponsibleParties] = useState<ResponsibleParty[]>([]);
    const [transportTypes, setTransportTypes] = useState<TransportType[]>([]);
    const [typeOfTrucks, setTypeOfTrucks] = useState<Mode[]>([]);

    const loadBillToItems = () => {
        agent.Selections.billToList()
            .then(response => setBillToItems(response));
    }

    const loadCountries = () => {
        agent.Selections.countries()
            .then(response => setCountries(response));
    }

    const loadCurrencies = () => {
        agent.Selections.currencies().then(response => {
            setCurrencies(response);
        })
    }

    const loadDocks = () => {
        agent.Docks.ownerList().then(
            response => setDocks(response));
    }

    const loadFollowUps = () => {
        agent.FollowUp.getSelectionList()
            .then(response => setFollowUps(response))
    }

    const loadModes = () => {
        agent.Selections.modes().then(response => {
            setTypeOfTrucks(response);
        })
    }

    const loadPrimaryReasons = () => {
        agent.Selections.primaryReasons()
            .then((response) => { setOrderPrimaryReasons(response); });
    }

    const loadResponsibibleParties = () => {
        agent.Selections.responsibleParties()
            .then(response => setReponsibleParties(response));
    }

    const loadTransportTypes = () => {
        agent.Selections.transportTypes()
            .then(response => setTransportTypes(response));
    }

    const initialnewAuctionData: AuctionCreateDto = {
        duration: null,
        modeId: 'D', // Standard
        transportTypeId: "",

        primaryReasonId: '',
        secondaryReasonId: '',
        followUpId: undefined,

        responsiblePartyId: '',
        billToId: '',
        billToValue: '',

        weight: 0,
        supplierDuns: "",
        supplierName: "",
        supplierCountryCode: "",
        supplierAddress: "",
        supplierZipCode: "",
        supplierCity: "",
        pickUpDateTime: null,
        deliveryDateTime: null,
        currencyId: "",
        remarks: "",
        unloadingDock: "",
        packages: [],
        parts: [],
        uploadedFiles: [],

        userId: userStore.user?.userId!,
        userSource: userStore.user?.source!,
    }

    const initialNewPackageData: PackageCreateDto = {
        length: null,
        width: null,
        height: null,
        weight: null,
        qty: null
    }

    const initialNewPartData: PartCreateDto = {
        partNo: "",
        partQty: null,
        partName: "",
        unloadingDock: ""
    }

    const [newAuction] = useState<AuctionCreateDto>(initialnewAuctionData)
    const [showSelectSupplierModal, setShowSelectSupplierModal] = useState<boolean>(false);
    const [apiErrors, setApiErrors] = useState(null)
    const [validateOnChange, setValidateOnChange] = useState(false)
    const [autoSumPackages, setAutoSumPackages] = useState(false)
    const [newPackage, setNewPackage] = useState<PackageCreateDto>(initialNewPackageData)
    const [newPart, setNewPart] = useState<PartCreateDto>(initialNewPartData)

    useEffect(() => {
        loadCountries();
        loadModes()
        loadTransportTypes();
        loadPrimaryReasons();
        loadFollowUps();
        loadResponsibibleParties();
        loadBillToItems();
        loadCurrencies();
        loadDocks();
    }, [])

    // Primary reason
    const handleSelectPrimaryReason = (e: React.ChangeEvent<any>, setFieldValue: any, setFieldTouched: any) => {
        if (e.target.value !== '') {
            loadSecondaryReasons(e.target.value);
        }

        setFieldValue("primaryReasonId", e.target.value)
        setFieldValue("secondaryReasonId", '')

        validateOnChange && setTimeout(() => setFieldTouched("responsiblePartyId", true));
    }

    const loadSecondaryReasons = (id: string) => {
        if (!orderSecondaryReasons.some(reason => reason.parentID === id)) {
            agent.Selections.secondaryReasons(id)
                .then((response) => {
                    const newReasons = orderSecondaryReasons.concat(response);

                    setOrderSecondaryReasons(newReasons);
                    setOrderSecondaryReasonsForSelectedPrimary(newReasons.filter(sr => sr.parentID === id));
                })
        } else {
            setOrderSecondaryReasonsForSelectedPrimary(orderSecondaryReasons.filter(sr => sr.parentID === id));
        }
    }

    // Responsible party
    const handleResponsiblePartyChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any, setFieldTouched: any) => {
        const newResonsiblePartyId = e.target.value;

        setFieldValue("responsiblePartyId", newResonsiblePartyId);

        const responsibleParty = responsibibleParties.find(r => r.responsiblePartyID === newResonsiblePartyId)
        const newBillToID = responsibleParty ? responsibleParty.defaultBillToID : "CC";

        setFieldValue("billToId", newBillToID);
        validateOnChange && setTimeout(() => setFieldTouched("responsiblePartyId", true));
    }

    // BillTo
    const getBillToLabelName = (id: string) => {
        if (id === '') return "CISCO/COFOR";

        const billTo = billToItems.find(b => b.billToID === id)

        if (billTo === undefined) return "CISCO/COFOR";

        return getBillToName(billTo, lang);
    }

    const handleSupplierSelectFromList = (supplierDuns: string, setFieldValue: any, setFieldTouched: any) => {
        agent.Supplier.byDuns(supplierDuns).then(response => {
            setFieldValue("supplierDuns", response.supplierDuns)
            setFieldValue("supplierName", response.supplierName)
            setFieldValue("supplierAddress", response.supplierStreet)
            setFieldValue("supplierCity", response.supplierCity)
            setFieldValue("supplierZipCode", response.supplierZipCode)
            setFieldValue("supplierCountryCode", response.supplierCountryCode)
            setShowSelectSupplierModal(false);

            validateOnChange && setTimeout(() => {
                setFieldTouched("supplierDuns", true)
                setFieldTouched("supplierName", true)
                setFieldTouched("supplierAddress", true)
                setFieldTouched("supplierCity", true)
                setFieldTouched("supplierZipCode", true)
                setFieldTouched("supplierCountryCode", true)
            });

        })
    }

    const getCountryName = (country: SupplierCountry) => {
        switch (lang) {
            case "en":
                return country.supplierCountryCodeEn;
            case "pl":
                return country.supplierCountryCodePl;
            case "de":
                return country.supplierCountryCodeDe;
            default:
                return country.supplierCountryCodeEn;
        }
    }

    const handleRecalculateWeightButtonClick = (packages: PackageCreateDto[], setFieldValue: any) => {
        const sumWeight: number = packages.reduce(function (pv, cv) {
            return parseInt(pv.toString()) + (parseInt(cv.qty!.toString()) * parseInt(cv.weight!.toString()));
        }, 0);

        setFieldValue("weight", sumWeight);
        setAutoSumPackages(true);
    }

    // FORM
    function handleSubmit(val: AuctionCreateDto, actions: FormikHelpers<AuctionCreateDto>) {
        actions.setSubmitting(true);
        {
            const nullableData = JSON.parse(JSON.stringify(val), (key, value) => {
                return value === '' ? null : value;
            });

            agent.Auctions.create(nullableData).then(() => {
                toast.success(t("auctionaddedsuccessfully"));
                actions.resetForm({ values: initialnewAuctionData });
            }).catch(err => {
                toast.warning(t("auctionnotadded"));
                setApiErrors(err);
            })
            .finally(() => {
                actions.setSubmitting(false);
            })
        }
    }

    const clearForm = (actions: FormikHelpers<AuctionCreateDto>) => {
        setValidateOnChange(false);
        setAutoSumPackages(false);
        setNewPart(initialNewPartData);
        setNewPackage(initialNewPackageData);
        setSessionId(getNewGuid());
    }

    return (
        <Container fluid className='mt-2'>

            <Row className="justify-content-center">
                <Col xs={12} lg={11} xl={10} xxl={9}>
                    <Formik
                        enableReinitialize
                        validateOnChange={validateOnChange}
                        validateOnBlur={true}
                        validate={formValues => new AuctionCreateValidator().validate(formValues)}
                        initialValues={newAuction}
                        onSubmit={(val, actions) => handleSubmit(val, actions)}
                        onReset={(val, actions) => clearForm(actions)}>
                        {({ values: newAuction, handleChange, handleSubmit, setFieldValue, errors, setErrors, isSubmitting, resetForm, setFieldTouched }) => (
                            <Form className='row shadow mt-2  p-1 p-sm-2 p-md-3 rounded g-3 bg-white' onSubmit={handleSubmit} >
                                <Col md={4}>
                                    <Form.Label htmlFor="duration" className="required">{t('duration')} [min]:</Form.Label>
                                    <IntegerInput
                                        id="duration"
                                        name="duration"
                                        value={newAuction.duration}
                                        change={handleChange}
                                        isInvalid={!!errors.duration}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.duration}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="modeId" className="required">{t('mode')}:</Form.Label>
                                    <Form.Select
                                        id="modeId"
                                        aria-label="mode"
                                        name="modeId"
                                        value={newAuction.modeId}
                                        onChange={handleChange}
                                        isInvalid={!!errors.modeId}>
                                        <option value={''} >{t('choosetype')}</option>
                                        {typeOfTrucks.map(tt => (
                                            <option key={tt.modeId} value={tt.modeId}>{getModeName(tt, lang)}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid' >{errors.modeId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="transportTypeId" className="required">{t("transportType")}:</Form.Label>
                                    <Form.Select
                                        id="transportTypeId"
                                        aria-label="Transport type"
                                        name="transportTypeId"
                                        value={newAuction.transportTypeId}
                                        onChange={handleChange}
                                        isInvalid={!!errors.transportTypeId}>
                                        <option value={''} >{t("choosetype")}</option>
                                        {transportTypes.map(tt => (
                                            <option key={tt.transportTypeId} value={tt.transportTypeId} >{getTransportTypeName(tt, lang)}</option>
                                        ))}

                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid' >{errors.transportTypeId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="primaryReasonId" className="required">{t("primaryReason")}:</Form.Label>
                                    <Form.Select
                                        id="primaryReasonId"
                                        aria-label="primary reason"
                                        name="primaryReasonId"
                                        value={newAuction.primaryReasonId}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => { handleSelectPrimaryReason(e, setFieldValue, setFieldTouched) }}
                                        isInvalid={!!errors.primaryReasonId}>
                                        <option value={''} >{t("chooseReason")}</option>
                                        {orderPrimaryReasons.map((reason, index) =>
                                        (
                                            <option key={index} value={reason.reasonID} > {getOrderReasonName(reason, lang)}</option>
                                        ))}

                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid' >{errors.primaryReasonId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="secondaryReasonId" >{t("secondaryReason")}:</Form.Label>
                                    <Form.Select
                                        id="secondaryReasonId"
                                        aria-label="secondary reason"
                                        name="secondaryReasonId"
                                        value={newAuction.secondaryReasonId}
                                        onChange={handleChange}
                                        isInvalid={!!errors.secondaryReasonId}>
                                        <option value={''} >{t("chooseReason")}</option>
                                        {orderSecondaryReasonsForSelectedPrimary.map(reason => (
                                            <option key={reason.reasonID} value={reason.reasonID} >{getOrderReasonName(reason, lang)}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid' >{errors.secondaryReasonId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="followUpId" className="required">{t("requestedBy")}:</Form.Label>
                                    <Form.Select
                                        id="followUpId"
                                        aria-label="followUpId"
                                        name="followUpId"
                                        value={newAuction.followUpId || undefined}
                                        onChange={handleChange}
                                        isInvalid={!!errors.followUpId}>
                                        <option value={undefined} >{t("choosePerson")}</option>
                                        {followUps.map(fu => (
                                            <option key={fu.userId} value={fu.userId}>{fu.name}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid' >{errors.followUpId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="responsiblePartyId" className="required">{t("responsibleParty")}:</Form.Label>
                                    <Form.Select
                                        id="responsiblePartyId"
                                        aria-label="responsible party"
                                        name="responsiblePartyId"
                                        value={newAuction.responsiblePartyId}
                                        onChange={(e) => { handleResponsiblePartyChange(e, setFieldValue, setFieldTouched) }}
                                        isInvalid={!!errors.responsiblePartyId}>
                                        <option value={''} >{t("chooseOption")}</option>
                                        {responsibibleParties.map(rp => (
                                            <option key={rp.responsiblePartyID} value={rp.responsiblePartyID}>{getResponsiblePartyName(rp, lang)}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid' >{errors.responsiblePartyId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="billToId" className="required">{t("billTo")}:</Form.Label>
                                    <Form.Select
                                        id="billToId"
                                        aria-label="bill to"
                                        name="billToId"
                                        value={newAuction.billToId}
                                        onChange={handleChange}
                                        isInvalid={!!errors.billToId}>
                                        <option value={''} >{t("chooseOption")}</option>
                                        {billToItems.map(item => (
                                            <option key={item.billToID} value={item.billToID}>{getBillToName(item, lang)}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid' >{errors.billToId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="billToValue" className="required">{getBillToLabelName(newAuction.billToId)}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="billToValue"
                                        name="billToValue"
                                        value={newAuction.billToValue}
                                        onChange={handleChange}
                                        maxLength={10}
                                        isInvalid={!!errors.billToValue}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.billToValue}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}></Col>
                                <Col className="mt-0 mt-md-4 px-0" xs={12}>
                                    <h6 className="fw-bolder">{t('addparts')}:</h6>
                                    <AddingPartsModule
                                        parts={newAuction.parts}
                                        ownerDocks={docks}
                                        onChange={setFieldValue}
                                        newPart={newPart}
                                        setNewPart={setNewPart}
                                        initialNewPartData={initialNewPartData}
                                    />
                                </Col>
                                <Col className="mt-0 mt-md-3" xs={12}>
                                    <h6 className="fw-bolder">{t('addpackages')}:</h6>
                                    <AddingPackageModule
                                        packages={newAuction.packages}
                                        onChange={setFieldValue}
                                        autoSumPackages={autoSumPackages}
                                        newPackage={newPackage}
                                        setNewPackage={setNewPackage}
                                        initialNewPackageData={initialNewPackageData}
                                        setAutoSumPackages={setAutoSumPackages}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="totalWeight" className="required">{t('totalweight')} [kg]:
                                    </Form.Label>
                                    {autoSumPackages &&
                                        <Form.Label className="ms-2 text-success fw-bold">
                                            <InfoCircleFill className="pb-1 fw-bold" height={20} width={20} />
                                            {t('sumPackagesWeight')}
                                        </Form.Label>}
                                    <InputGroup>
                                        <IntegerInput
                                            id="weight"
                                            name="weight"
                                            value={newAuction.weight}
                                            change={(val) => { handleChange(val); setAutoSumPackages(false); }}
                                            isInvalid={!!errors.weight}
                                        />
                                        <Button variant="outline-secondary" id="recalculateWeight" onClick={() => handleRecalculateWeightButtonClick(newAuction.packages, setFieldValue)}>
                                            {t("recalculate")}
                                        </Button>
                                    </InputGroup>
                                    <Form.Control.Feedback type='invalid'>{errors.weight}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="supplierDunsCofor">{t('supplierduns')}:</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            id="supplierDuns"
                                            name="supplierDuns"
                                            value={newAuction.supplierDuns}
                                            onChange={handleChange}
                                            maxLength={10}
                                            isInvalid={!!errors.supplierDuns}
                                        />
                                        <Button variant="outline-secondary" id="selectSupplier" onClick={() => setShowSelectSupplierModal(true)}>
                                            {t('select')}
                                        </Button>
                                    </InputGroup>
                                    <Form.Control.Feedback type='invalid'>{errors.supplierDuns}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="supplierName" className="required">{t('name')}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="supplierName"
                                        name="supplierName"
                                        value={newAuction.supplierName}
                                        onChange={handleChange}
                                        maxLength={100}
                                        isInvalid={!!errors.supplierName}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.supplierName}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="street">{t('street')}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="supplierStreet"
                                        name="supplierStreet"
                                        value={newAuction.supplierAddress}
                                        onChange={handleChange}
                                        maxLength={100}
                                        isInvalid={!!errors.supplierAddress}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.supplierAddress}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="zipCode" className="required">{t('zipcode')}:</Form.Label>
                                    <Form.Control
                                        id="supplierZipCode"
                                        type="text"
                                        name="supplierZipCode"
                                        value={newAuction.supplierZipCode}
                                        onChange={handleChange}
                                        maxLength={10}
                                        isInvalid={!!errors.supplierZipCode}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.supplierZipCode}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="city" className="required">{t('city')}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="supplierCity"
                                        name="supplierCity"
                                        value={newAuction.supplierCity}
                                        onChange={handleChange}
                                        maxLength={35}
                                        isInvalid={!!errors.supplierCity}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.supplierCity}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="country" className="required">{t('country')}:</Form.Label>
                                    <Form.Select
                                        id="supplierCountryCode"
                                        aria-label="Supplier cuntry"
                                        name="supplierCountryCode"
                                        value={newAuction.supplierCountryCode}
                                        onChange={handleChange}
                                        isInvalid={!!errors.supplierCountryCode}
                                    >
                                        <option value="">{t('choosecountry')}</option>
                                        {countries && countries.map((country, index) => (
                                            <option key={index} value={country.supplierCountryCode}>
                                                {getCountryName(country)}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>{errors.supplierCountryCode}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="pickupDatetime" className="required">{t('pickupdatetime')}:</Form.Label>
                                    <DatePicker
                                        name="pickUpDateTime"
                                        className={!!errors.pickUpDateTime ? "form-control is-invalid" : "form-control"}
                                        selected={fixTimezoneForSelectedCalendarDate(newAuction.pickUpDateTime)}
                                        onChange={(date: Date) => setFieldValue('pickUpDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                        todayButton={t('today')}
                                        locale={lang}
                                        dateFormat="dd.MM.yyyy HH:mm"
                                        placeholderText="__.__.____ __:__"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        autoComplete="off"
                                    />
                                    <div className="text-danger fs-14px mt-4px" >{errors.pickUpDateTime}</div>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="deliveryDatetime" className="required">{t('deliverydatetime')}:</Form.Label>
                                    <DatePicker
                                        name="deliveryDateTime"
                                        className={!!errors.deliveryDateTime ? "form-control is-invalid" : "form-control"}
                                        selected={fixTimezoneForSelectedCalendarDate(newAuction.deliveryDateTime)}
                                        onChange={(date: any) => setFieldValue('deliveryDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                        todayButton={t('today')}
                                        locale={lang}
                                        dateFormat="dd.MM.yyyy HH:mm"
                                        placeholderText="__.__.____ __:__"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        minDate={newAuction.pickUpDateTime}
                                        autoComplete="off"
                                    />
                                    <div className="text-danger fs-14px mt-4px" >{errors.deliveryDateTime}</div>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="currencyId" className="required">{t('currency')}:</Form.Label>
                                    <Form.Select
                                        id="currencyId"
                                        name="currencyId"
                                        value={newAuction.currencyId}
                                        onChange={handleChange}
                                        isInvalid={!!errors.currencyId}
                                    >
                                        <option value="">{t('choosecurrency')}</option>
                                        {currencies.map((c, index) => (
                                            <option key={index} value={c.currencyId}>{c.currencySymbol}</option>))}
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>{errors.currencyId}</Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="unloadingDock">{t('unloadingdock')}:</Form.Label>
                                    <Form.Select
                                        id="unloadingDock"
                                        name="unloadingDock"
                                        aria-label="unloading dock"
                                        value={newAuction.unloadingDock}
                                        onChange={handleChange}>
                                        <option value="">{t('choosedock')}</option>
                                        {docks.map((dock, index) => (
                                            <option key={index} value={dock.name}>{dock.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                <Col md={12}>
                                    <Form.Label htmlFor="remarks">{t('remarks')}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={3}
                                        maxLength={250}
                                        id="remarks"
                                        name="remarks"
                                        value={newAuction.remarks}
                                        onChange={handleChange}
                                    />
                                    <div className="text-danger fs-14px mt-4px" >{errors.remarks}</div>
                                </Col>
                                <Col xs={12} >
                                    <UploadFiles sessionId={sessionId} setFieldValue={setFieldValue} uploadedfileNames={newAuction.uploadedFiles} />
                                </Col>
                                <Col xs={12} className="text-center">
                                    <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={() => setValidateOnChange(true)}>
                                        {isSubmitting ? <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-1"
                                        /> : null}
                                        {isSubmitting ? (t("saving")) : t("save")}
                                    </Button>
                                    <Button variant="secondary" className="mx-1" type="reset" onClick={() => {
                                        resetForm({ values: initialnewAuctionData });
                                    }}>
                                        {t("clear")}
                                    </Button>
                                </Col>
                                <Col>
                                    {apiErrors &&
                                        <ValidationErrorList errorList={apiErrors} />}
                                </Col>
                                <SelectSupplierModal
                                    show={showSelectSupplierModal}
                                    setShow={(val) => setShowSelectSupplierModal(val)}
                                    setValue={setFieldValue}
                                    supplierSelectFunction={(duns) => handleSupplierSelectFromList(duns, setFieldValue, setFieldTouched)}
                                />
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}