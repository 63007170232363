import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FollowUpFormModel } from '../../../app/models/FollowUpFormModel';
import FollowUpFormModelValidator from '../../../app/models/validators/followUpFormModelValidator';
import { useStore } from '../../../app/store/store';
import ValidationErrorList from '../../error/ValidationErrorList';

export default function FollowUpForm() {
    const { t } = useTranslation();
    const { followUpStore } = useStore();
    const { selectedTab, selectedFollowUp, addFollowUp, updateFollowUp } = followUpStore;

    const [validateOnChange, setValidateOnChange] = useState(false);
    const [apiErrors, setApiErrors] = useState(null);

    const initValue: FollowUpFormModel = selectedFollowUp ? selectedFollowUp : {
        name: '',
        mobilePhone: '',
        email: '',
        langId: ''
    }

    const handleSubmit = (val: FollowUpFormModel, actions: FormikHelpers<FollowUpFormModel>) => {
        if (selectedTab === "addFollowUp") {
            addFollowUp(val)
                .then(() => {
                    setApiErrors(null);
                    toast.success(t("followupAddedSuccessfully"));
                })
                .catch((err) => {

                    console.log(err);
                    setApiErrors(err);
                    toast.error(t("followupAddedUnuccessfully"));
                })
                .finally(() => actions.setSubmitting(false));
        }
        else {
            updateFollowUp(val)
                .then(() => {
                    setApiErrors(null);
                    toast.success(t("followupUpdatedSuccessfully"))
                })
                .catch((err) => {
                    setApiErrors(err);
                    toast.error(t("followupUpdatedUnsuccessfully"))
                })
                .finally(() => actions.setSubmitting(false));
        }
    }

    const clearForm = (actions: FormikHelpers<FollowUpFormModel>) => {
        setApiErrors(null);
    }

    return (
        <Formik
            enableReinitialize
            validateOnChange={validateOnChange}
            validateOnBlur={true}
            validate={formValues => new FollowUpFormModelValidator().validate(formValues)}
            initialValues={initValue}
            onSubmit={(val, actions) => handleSubmit(val, actions)}
            onReset={(val, actions) => clearForm(actions)}
        >
            {({ values: user, handleChange, handleSubmit, resetForm, errors, isSubmitting, setSubmitting }) => (
                <Form>
                    <Row className="mt-3">
                        <Col xs={12} md={6} lg={12} xxl={6}>
                            <Form.Label htmlFor="name" className="required">{t('contactName')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                value={user.name}
                                onChange={handleChange}
                                maxLength={50}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.name}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                            <Form.Label htmlFor="mobilePhone">{t('contactPhone')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="mobilePhone"
                                name="mobilePhone"
                                value={user.mobilePhone || ''}
                                onChange={handleChange}
                                maxLength={15}
                                isInvalid={!!errors.mobilePhone}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.mobilePhone}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={6} lg={12} xxl={6}>
                            <Form.Label htmlFor="email" className="required">{t('contactemail')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                value={user.email}
                                onChange={handleChange}
                                maxLength={50}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.email}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                            <Form.Label htmlFor="langId" className="required">{t('language')}:</Form.Label>
                            <Form.Select
                                id="langId"
                                name="langId"
                                value={user.langId}
                                onChange={handleChange}
                                isInvalid={!!errors.langId}
                            >
                                <option value="">{t("chooseLanguage")}</option>
                                <option value="EN">{t("EN")}</option>
                                <option value="DE">{t("DE")}</option>
                                <option value="PL">{t("PL")}</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid' >{errors.langId}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12} className="text-center">
                            <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={(e: any) => { e.preventDefault(); setSubmitting(true); setValidateOnChange(true); handleSubmit(); }}>
                                {isSubmitting ? <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                /> : null}
                                {isSubmitting ? (t("saving")) : t("save")}
                            </Button>
                            {selectedTab === "editFollowUp" ?
                                <Button
                                    variant="secondary"
                                    className="mx-1"
                                    onClick={() => {
                                        followUpStore.setSelectedTab("addFollowUp");
                                        followUpStore.setSelectedFollowUp(null);
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                                :
                                <Button
                                    variant="secondary"
                                    className="mx-1"
                                    onClick={() => resetForm()}
                                >
                                    {t("clear")}
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12} className="text-center">
                            { apiErrors !== null ?
                                <ValidationErrorList errorList={apiErrors} /> : null }
                            </Col>
                        </Row>
                </Form>
            )}
        </Formik>
    )
}

