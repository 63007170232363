import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, PencilSquare, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { CarrierUserListItemDto } from '../../../app/models/carrierUserListItemDto';
import PaginationModel from '../../../app/models/paginatedResult';
import { QueryParamsTable } from '../../../app/models/queryParamsTable';

interface Props {
    users: CarrierUserListItemDto[],
    handleRowCommandButtonClick: (action: "addUser" | "editUser" | "deleteUser", selectedUser: CarrierUserListItemDto | null) => void,
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsTable,
    selectedUser: CarrierUserListItemDto | null,
    selectUser: (user: CarrierUserListItemDto | null) => void,
    selectedTab: "addUser" | "editUser" | "deleteUser"
    isCarrierColumnsHidden: boolean
}

export default function UsersTable({ users, pagination, sort, queryParams, selectedUser, handleRowCommandButtonClick, selectedTab, isCarrierColumnsHidden }: Props) {
    const { t } = useTranslation();

    const columns = [
        { labelPhrase: "cofor", accessor: "CompanyCofor", hideWithCarrierColumn: true },
        { labelPhrase: "carrierName", accessor: "CompanyName", hideWithCarrierColumn: true },
        { labelPhrase: "contactName", accessor: "Name", hideWithCarrierColumn: false },
        { labelPhrase: "contactPhone", accessor: "MobilePhone", hideWithCarrierColumn: false },
        { labelPhrase: "contactemail", accessor: "Email", hideWithCarrierColumn: false },
        { labelPhrase: "language", accessor: "LangId", hideWithCarrierColumn: false },
        { labelPhrase: "send", accessor: "Auctions", hideWithCarrierColumn: false },
        { labelPhrase: "", accessor: "", hideWithCarrierColumn: false }
    ];

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            {isCarrierColumnsHidden ?
                Array(columns.length - 2).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)
                :
                Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)
            }
        </tr>
    )

    return (
        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => {
                        if (!column.hideWithCarrierColumn || (!isCarrierColumnsHidden || !column.hideWithCarrierColumn)) {
                            return (<th key={index}
                                onClick={column.accessor
                                    ? () => sort(column.accessor)
                                    : undefined}
                                className={queryParams.sortBy === column.accessor
                                    ? "th-sorted-by"
                                    : undefined}
                                style={column.accessor
                                    ? { cursor: "pointer" }
                                    : undefined}
                            >
                                {t(column.labelPhrase)}
                                {queryParams.sortBy === column.accessor
                                    ? queryParams.sortDirection === "ASC"
                                        ? <CaretUpFill className="ms-1" />
                                        : <CaretDownFill className="ms-1" />
                                    : null}
                            </th>)
                        }
                        return null;
                    })}
                </tr>
            </thead>
            <tbody>
                {users.map(user => (
                    <tr
                        key={user.userId}
                        className={user.userId === selectedUser?.userId
                            ? selectedTab === "editUser" ? "selected-table-row" : "selected-table-row-delete"
                            : undefined}
                    >
                        {!isCarrierColumnsHidden && <td>{user.companyCofor}</td>}
                        {!isCarrierColumnsHidden && <td>{user.companyName}</td>}
                        <td>{user.name}</td>
                        <td>{user.mobilePhone}</td>
                        <td>{user.email}</td>
                        <td>{t(user.langId)}</td>
                        <td>
                            <Form.Check
                                checked={user.auctions}
                                disabled={true} />
                        </td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('editUser', user)}>
                                    <PencilSquare className='text-info' height={21} width={21} />
                                </span>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('deleteUser', user)}>
                                    <XCircleFill className='text-danger' height={21} width={21} />
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
                {emptyRows}
            </tbody>
        </Table>
    )
}