import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { BeginAgainFinishedAuction } from "../beginAgainFinishedAuction";
import { isIntegerGreatherThan, isNumericFieldEmpty } from "./validateFunctions";

class BeginAgainFinishedAuctionValidator extends Validator<BeginAgainFinishedAuction>
{
    constructor() {
        super();

        this.ruleFor('duration')
            .must(x => isNumericFieldEmpty(x)!)
            .withMessage(i18next.t("valRequiredField"))
            .when(x => x !== undefined)
            .must(x => isIntegerGreatherThan(x, 0)!)
            .withMessage(i18next.t("valValueGreatherThan").replace("#value#", "0"))

        this.ruleFor('pickUpDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('deliveryDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('remarks')
            .maxLength(250).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "250"));

    }
}

export default BeginAgainFinishedAuctionValidator