import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { PriceListItemDto } from '../../app/models/priceListItemDto';
import { useStore } from '../../app/store/store';

interface Props {
    selectingMode: "winner" | "playOff";
}

export default observer(function PriceTable({ selectingMode }: Props) {
    const { finishedAuctionsStore } = useStore();
    const { t } = useTranslation();

    const columns = [
        { labelPhrase: "", accessor: "" },
        { labelPhrase: "price", accessor: "Price" },
        { labelPhrase: "companyName", accessor: "CompanyName" },
        { labelPhrase: "realPickupDateTime", accessor: "RealPickUpDateTime" },
        { labelPhrase: "maxLoadingDateTime", accessor: "MaxLoadingDateTime" },
        { labelPhrase: "remarks", accessor: "Remarks" }
    ];

    const handleCheckPrice = (e: React.ChangeEvent<HTMLInputElement>, price: PriceListItemDto) => {
        if (selectingMode === "winner") {
            if (e.target.checked) {
                finishedAuctionsStore.setSelectedWinnerPrice(price);
            } else {
                finishedAuctionsStore.setSelectedWinnerPrice(null);
            }
        }
        else {
            if (e.target.checked) {
                finishedAuctionsStore.setSelectedPlayOffPrices([...finishedAuctionsStore.selectedPlayOffPrices, price]);
            } else {
                finishedAuctionsStore.setSelectedPlayOffPrices(finishedAuctionsStore.selectedPlayOffPrices.filter(p => p !== price));
            }
        }
    }

    const isPriceSelected = (price: PriceListItemDto) => {
        if (selectingMode === "winner") {
            return finishedAuctionsStore.selectedWinnerPrice === price;
        }
        else {
            return finishedAuctionsStore.selectedPlayOffPrices.some(p => p === price);
        }
    }

    const isChecked = (price: PriceListItemDto) => {
        if (selectingMode === "winner") {
            return finishedAuctionsStore.selectedWinnerPrice === price
        }
    }

    return (
        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => (
                        <th
                            key={index}
                            onClick={column.accessor
                                ? () => finishedAuctionsStore.handlePricesSort(column.accessor)
                                : undefined}
                            className={finishedAuctionsStore.pricesPagingParams.sortBy === column.accessor
                                ? "th-sorted-by"
                                : undefined}
                            style={column.accessor
                                ? { cursor: "pointer" }
                                : undefined}
                        >
                            {t(column.labelPhrase)}
                            {finishedAuctionsStore.pricesPagingParams.sortBy === column.accessor
                                ? finishedAuctionsStore.pricesPagingParams.sortDirection === "ASC"
                                    ? <CaretUpFill className="ms-1" />
                                    : <CaretDownFill className="ms-1" />
                                : null}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {finishedAuctionsStore.selectedAuctionPrices.map((price, index) => (

                    <tr key={index} className={isPriceSelected(price) ? "selected-table-row" : undefined}
                    >
                        <td>
                            <Form.Check
                                type="checkbox"
                                id="selectPrice"
                                onChange={(e) => handleCheckPrice(e, price)}
                                checked={isChecked(price) }
                            />
                        </td>
                        <td>{price.price}</td>
                        <td>{price.userCompany}</td>
                        <td className={finishedAuctionsStore.selectedAuction?.pickUpDateTime?.toString() !== price.realPickUpDateTime.toString() ?
                            "text-danger fw-bold" : undefined} >
                            {moment(new Date(price.realPickUpDateTime!)).format("DD.MM.YYYY HH:mm")}
                        </td>
                        <td>{moment(new Date(price.maxLoadingDateTime!)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{price.remarks}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
})