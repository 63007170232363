import React, { ChangeEvent } from 'react';
import { Form, Table } from 'react-bootstrap';
import { PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Dock } from '../../app/models/dock';
import { PartCreateDto } from '../../app/models/partCreateDto';
import IntegerInput from '../common/IntegerInput';

interface Props {
    parts: PartCreateDto[],
    ownerDocks: Dock[];
    onChange: (fieldName: string, val: any) => void;
    initialNewPartData: PartCreateDto;
    newPart: PartCreateDto;
    setNewPart: (prt: PartCreateDto) => void;
}

export default function AddingPartsModule({ parts, ownerDocks, onChange, initialNewPartData, newPart, setNewPart }: Props) {
    
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewPart({ ...newPart, [name]: value })
    }

    const handleUnloadingDockChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setNewPart({ ...newPart, [name]: value })
    }

    const handleAddNewPart = (newPart: PartCreateDto) => {
        parts.push(newPart)
        onChange("parts", parts);
        setNewPart(initialNewPartData)
    }

    const handleAddKeyPress = (e: any) => {
        if (e.key === "Enter") {
            isNewPartCorrect() && handleAddNewPart(newPart)
        }
    }

    const handleDeletePart = (index: number) => {
        var partToRemove = parts[index];
        var newParts = parts.filter(item => item !== partToRemove)
        onChange("parts", newParts);
    }

    function isNewPartCorrect() {
        return newPart.partNo &&
            newPart.partQty;
    }

    const { t } = useTranslation();
    const inputClassName = "input-min-width-80px";


    return (
        <div className="table-responsive table-content-centered mt-0">
            <Table size="sm" className="pt-0">
                <thead className="gray-bg">
                    <tr>
                        <th className="ps-2 ps-md-4">#</th>
                        <th className="ps-2">{t('partno')}</th>
                        <th className="ps-2">{t('quantity')}</th>
                        <th className="ps-2">{t('name')}</th>
                        <th className="ps-2">{t('unloadingdock')}</th>
                        <th className="ps-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {parts.map((part, i) => (
                        <tr key={i + 1}>
                            <td className="ps-2 ps-md-4">{i + 1}</td>
                            <td className="ps-2">{part.partNo}</td>
                            <td className="ps-2">{part.partQty}</td>
                            <td className="ps-2">{part.partName}</td>
                            <td className="ps-2">{part.unloadingDock}</td>
                            <td>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <span className='cursor-pointer mx-1' onClick={() => handleDeletePart(i)}>
                                        <XCircleFill className='text-danger' height={22} width={22} />
                                    </span>
                                </span>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td>
                            <Form.Control
                                id="partNo"
                                name="partNo"
                                size="sm"
                                type="text"
                                className={inputClassName}
                                value={newPart.partNo}
                                onChange={handleInputChange}
                                maxLength={10} />
                        </td>
                        <td>
                            <IntegerInput
                                id="partQty"
                                name="partQty"
                                size="sm"
                                step={1}
                                className={inputClassName}
                                value={newPart.partQty}
                                change={handleInputChange} />
                        </td>
                        <td>
                            <Form.Control
                                id="partName"
                                name="partName"
                                size="sm"
                                type="text"
                                className={inputClassName}
                                value={newPart.partName}
                                onChange={handleInputChange}
                                maxLength={50} />
                        </td>
                        <td>
                            <Form.Select
                                id="unloadingDock"
                                name="unloadingDock"
                                size="sm"
                                className={inputClassName}
                                aria-label="New part unloading dock"
                                onChange={handleUnloadingDockChange}
                                value={ newPart.unloadingDock } >
                                <option value="">{t('choosedock')}</option>
                                {ownerDocks.map((dock, index) => (
                                    <option key={index} value={dock.name}>{dock.name}</option>
                                    ))}
                            </Form.Select>
                        </td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span
                                    className={isNewPartCorrect() ? 'cursor-pointer mx-1' : 'mx-1'}
                                    onKeyPress={(e) => handleAddKeyPress(e)}
                                    onClick={() => isNewPartCorrect() && handleAddNewPart(newPart)}
                                >
                                    <PlusCircleFill
                                        className={isNewPartCorrect() ? 'text-success smooth-color-trans' : 'text-secondary smooth-color-trans'}
                                        height={22}
                                        width={22}
                                        tabIndex={0}
                                    />
                                </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}