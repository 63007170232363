import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { pl } from "date-fns/locale";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { NewPriceDirectlyFromEmail } from '../../../app/models/newPriceDirectlyFromEmail';
import NewPriceDirectlyFromEmailValidator from '../../../app/models/validators/newPriceDirectlyFromEmailValidator';
import PriceInput from '../../common/PriceInput';
import ValidationErrorList from '../../error/ValidationErrorList';
import agent from '../../../app/api/agent';
import { fixTimezoneForOnChangeCalendarEvent, fixTimezoneForSelectedCalendarDate } from '../../common/functions/dateTimeManipulation';

interface Props {
    currencySymbol: string,
    isAuctionBlocked: boolean,
    modeId: string,
    loadData: () => void;
}

export default function AddPriceForm({ currencySymbol, isAuctionBlocked, modeId, loadData }: Props) {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [apiErrors, setApiErrors] = useState(null)
    const { t } = useTranslation();

    const initData: NewPriceDirectlyFromEmail = {
        price: null,
        realPickUpDateTime: undefined,
        maxLoadingDateTime: undefined,
        remarks: undefined,
        modeID: modeId
    }

    const qs = query.get('qs')?.toString();

    const handleSubmit = (newPrice: NewPriceDirectlyFromEmail, actions: FormikHelpers<NewPriceDirectlyFromEmail>) => {
        agent.ManageDirectlyFromEmail.addPrice(qs!, newPrice)
            .then(() => { toast.success(t("priceAddedSuccessfully")) })
            .catch((errors) => { setApiErrors(errors); toast.error(t("priceAddedUnSuccessfully")) })
            .finally(() => { actions.setSubmitting(false); loadData(); });
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initData}
            onSubmit={(val, actions) => handleSubmit(val, actions)}
            validateOnBlur={true}
            validateOnChange={validateOnChange}
            validate={formValues => new NewPriceDirectlyFromEmailValidator().validate(formValues)}
        >
            {({ values: newPrice, handleChange, handleSubmit, setFieldValue, errors, resetForm, isSubmitting }) => (
                <Form>
                    <Row className="text-start">
                        <Col md={4} className="mt-2">
                            <Form.Label htmlFor="price" className="required">{t("price")}:</Form.Label>
                            <PriceInput
                                id="price"
                                name="price"
                                value={newPrice.price}
                                change={handleChange}
                                currency={currencySymbol}
                                disabled={isAuctionBlocked}
                                isInvalid={!!errors.price}
                            />
                            <div className="text-danger fs-14px mt-4px" >{errors.price}</div>
                        </Col>
                        <Col sm={6} md={4} className="mt-2">
                            <Form.Label htmlFor="realPickUpDateTime" className="required">{t('pickupdatetime')}:</Form.Label>
                            <DatePicker
                                name="realPickUpDateTime"
                                className={!!errors.realPickUpDateTime ? "form-control is-invalid" : "form-control"}
                                selected={fixTimezoneForSelectedCalendarDate(newPrice.realPickUpDateTime)}
                                onChange={(date: any) => setFieldValue('realPickUpDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                todayButton={t('today')}
                                locale={pl}
                                dateFormat="dd.MM.yyyy HH:mm"
                                placeholderText="__.__.____ __:__"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                autoComplete="off"
                                disabled={isAuctionBlocked }
                            />
                            <div className="text-danger fs-14px mt-4px" >{errors.realPickUpDateTime}</div>
                        </Col>
                        <Col sm={6} md={4} className="mt-2">
                            <Form.Label htmlFor="maxLoadingDateTime" className="required">{t('deliverydatetime')}:</Form.Label>
                            <DatePicker
                                name="maxLoadingDateTime"
                                className={!!errors.maxLoadingDateTime ? "form-control is-invalid" : "form-control"}
                                selected={fixTimezoneForSelectedCalendarDate(newPrice.maxLoadingDateTime)}
                                onChange={(date: any) => setFieldValue('maxLoadingDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                todayButton={t('today')}
                                locale={pl}
                                dateFormat="dd.MM.yyyy HH:mm"
                                placeholderText="__.__.____ __:__"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                autoComplete="off"
                                disabled={isAuctionBlocked}
                            />
                            <div className="text-danger fs-14px mt-4px" >{errors.maxLoadingDateTime}</div>
                        </Col>
                        <Col md={12} className="mt-2">
                            <Form.Label htmlFor="remarks">{t('remarks')}:</Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea"
                                rows={3}
                                maxLength={250}
                                id="remarks"
                                name="remarks"
                                value={newPrice.remarks}
                                onChange={handleChange}
                                disabled={isAuctionBlocked}
                            />
                            <div className="text-danger fs-14px mt-4px" >{errors.remarks}</div>
                        </Col>
                        <Row className="mt-2">
                            <Col xs={12} className="text-center">
                                <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" disabled={isAuctionBlocked} onClick={(e) => { setValidateOnChange(true); handleSubmit(); e.preventDefault(); }}>
                                    {isSubmitting ? <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-1"
                                    /> : null}
                                    {isSubmitting ? (t("saving")) : t("save")}
                                </Button>
                                <Button variant="secondary" className="mx-1" type="reset" disabled={isAuctionBlocked} onClick={() => {
                                    resetForm({ values: initData }); setFieldValue('price', 0);
                                }}>
                                    {t("clear")}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {apiErrors &&
                                    <ValidationErrorList errorList={apiErrors} />}
                            </Col>
                        </Row>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}