import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useStore } from '../../../app/store/store';

export default function DeleteUserPanel() {
    const { t } = useTranslation();
    const { carrierUsersStore } = useStore();
    const { selectedUser, setSelectedUser, deleteUser,
        loadUsers, setSelectedTab } = carrierUsersStore;
    const [deleting, setDeleting] = useState(false);

    const handleDelete = () => {
        setDeleting(true);
        const id = selectedUser?.userId!;
        deleteUser(id)
            .then(() => toast.success(t("contactDeletedSuccessfully")))
            .catch(() => toast.error(t("contactDeletedUnsuccessfully")))
            .finally(() => {
                setDeleting(false);
                loadUsers();
            })        
    }

    return (
        <Row>
            <Col xs={12} className="text-center">
                <p className="my-4 fs-5 fw-bold text-danger">{t("sureDeleteContact")}</p>
            </Col>
            <Col xs={12} className="mt-3 text-center">
                <Button variant="danger" className="mx-1" onClick={handleDelete}>
                    {deleting ? <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                    /> : null}
                    {deleting ? t("deleting") : t("delete")}
                </Button>
                <Button variant="secondary" className="mx-1" onClick={() => {
                    setSelectedTab("addUser");
                    setSelectedUser(null);
                }} >
                    {t("cancel")}
                </Button>
            </Col>
        </Row>
    )
}