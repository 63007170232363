import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ContractSelectListItem } from '../../app/models/contractSelectListItem';
import { OrderCreateDto } from '../../app/models/orderCreateDto';
import { getCurrencySymbol } from '../common/functions/langObjectTranslation';

interface Props {
    newOrder: OrderCreateDto,
    contract: ContractSelectListItem | undefined
}

export default function PriceSummaryPanel({ newOrder, contract }: Props) {
    const { t } = useTranslation()

    const totalPriceWarning = Number(newOrder.totalPrice) < (Number(newOrder.contractTotalPrice) + Number(newOrder.additionalCostsPrice));

    return (
        <>
                <Col xs={12} md={4} className="pr-0 mt-2">
                    <span className="fw-bold" style={{ textDecoration: "underline" }}>{t("totalPrice")}:</span>
                    <span className={totalPriceWarning ? "ps-2 fw-bold text-danger" : "ps-2 fw-bold text-success"} >
                        {Number(newOrder.totalPrice).toFixed(2)} {getCurrencySymbol(newOrder.currencyId)}
                    </span>
                </Col>
                {newOrder.contractType === "D" ?
                    <Col xs={12} md={4} className="px-0">
                        <span className="fw-bold">{t("priceForDistance")}:</span>
                        <span className="ps-2">{newOrder.contractTotalPrice.toFixed(2)} {contract?.currency.currencySymbol}</span>
                    </Col> : undefined
                }
                {newOrder.contractType === "W" ?
                    <Col xs={12} md={4} className="px-0">
                        <span className="fw-bold">{t("priceForWeight")}:</span>
                        <span className="ps-2">{newOrder.contractTotalPrice.toFixed(2)} {contract?.currency.currencySymbol}</span>
                    </Col>
                    : undefined
                }
                {newOrder.contractType === "F" ?
                    <Col xs={12} md={4} className="px-0">
                        <span className="fw-bold">{t("contractPrice")}:</span>
                        <span className="ps-2">{newOrder.contractTotalPrice.toFixed(2)} {contract?.currency.currencySymbol}</span>
                    </Col>
                    : undefined
                }
                {newOrder.additionalCosts.length > 0 ?
                    <Col xs={12} md={4} className="px-0">
                        <span className="fw-bold">{t("additionalServicesCost")}:</span>
                        <span className="ps-2">{newOrder.additionalCostsPrice.toFixed(2)} {contract?.currency.currencySymbol}</span>
                    </Col>
                    : undefined
                }
        </ >
    )
}