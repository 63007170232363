import React, { useEffect } from 'react';
import { Alert, Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Approver } from '../../../app/models/approver';
import { useStore } from '../../../app/store/store';
import LoadingDataComponent from '../../common/LoadingDataComponent';
import TablePagination from '../../common/TablePagination';
import { observer } from 'mobx-react-lite';
import ApproversTable from './ApproversTable';
import ApproverForm from './ApproverForm';
import DeleteApproverPanel from './DeleteApproverPanel';


export default observer(function ApproversPage() {
    const { t } = useTranslation();
    const { approversStore, commonStore } = useStore();
    const { loadingData, approvers,
        selectedTab, setSelectedTab,
        selectedApprover, setSelectedApprover,
        resetParams,
        loadApprovers, loadCurrencies,
        pagination, pagingParams,
        changePage, handleSort
    } = approversStore;

    const handleTabSelect = (val: "addApprover" | "editApprover" | "deleteApprover") => {
        setSelectedTab(val);
    }

    function handleRowCommandButton(tabName: "addApprover" | "editApprover" | "deleteApprover", selectedApprover: Approver | null) {
        setSelectedTab(tabName);
        setSelectedApprover(selectedApprover);
    }

    useEffect(() => {
        loadApprovers();
        setSelectedTab(selectedTab);
        // eslint-disable-next-line
    }, [approversStore, commonStore.selectedOwner])

    useEffect(() => {
        resetParams();
        setSelectedApprover(null);
        setSelectedTab("addApprover");
        // eslint-disable-next-line
    }, [commonStore.selectedOwner])

    useEffect(() => {
        loadCurrencies();
        // eslint-disable-next-line
    }, [])


    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3'>
                {loadingData ?
                    <LoadingDataComponent height="300px" />
                    :
                    <>
                        <Col xs={12} lg={8} >
                            <div className="shadow p-3 h-100 rounded bg-white">
                                {
                                    approvers.length === 0 ?
                                        <Col xs={12} className='mt-3 text-center'>
                                            <Alert variant='info' className='text-center shadow'>
                                                <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                                <br />
                                                {t('approversNotFound')}
                                            </Alert>
                                        </Col>
                                        :
                                        <>
                                <ApproversTable
                                    approvers={approvers}
                                    handleRowCommandButtonClick={handleRowCommandButton}
                                    pagination={pagination}
                                    sort={(val) => handleSort(val)}
                                    queryParams={pagingParams}
                                    selectedApprover={selectedApprover}
                                    selectApprover={(val) => setSelectedApprover(val)}
                                    selectedTab={selectedTab}
                                />
                                {pagination &&
                                    <TablePagination
                                        pagination={pagination}
                                        changePage={changePage}
                                    />
                                            }
                                        </>
                                }
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="shadow p-3 rounded h-100 bg-white">
                                {selectedApprover ?
                                    <>
                                        <Tab.Container defaultActiveKey="editUser">
                                            <Row>
                                                <Col xs={12}>
                                                    <Nav
                                                        variant="tabs"
                                                        justify={true}
                                                        onSelect={(val: any) => handleTabSelect(val)}
                                                    >
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                active={selectedTab === "editApprover"}
                                                                eventKey="editApprover">
                                                                {t("editcontact")}

                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                active={selectedTab === "deleteApprover"}
                                                                eventKey="deleteApprover"
                                                            >
                                                                {t("deleteContact")}
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col xs={12}>
                                                    <Tab.Content>
                                                        <Tab.Pane
                                                            active={selectedTab === "editApprover"}
                                                            eventKey="editApprover"
                                                        >
                                                            <ApproverForm />
                                                        </Tab.Pane>
                                                        <Tab.Pane
                                                            active={selectedTab === "deleteApprover"}
                                                            eventKey="deleteApprover"
                                                        >
                                                            <DeleteApproverPanel />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <p className="my-4 fs-5 fw-bold text-primary">{t("addNewApprover")}</p>
                                            </Col>
                                            <Col xs={12} >
                                                <ApproverForm />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </div>
                        </Col>
                    </>
                }
            </Row>
        </Container>
    )
})
