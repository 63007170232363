import React, { useEffect, useState } from 'react';
import { ArrowRepeat, PauseCircle, PlayCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { defaultTableAutoRefreshDelay } from '../../config';

interface Props {
    reloadFunction: () => void;
}

export default function RefreshTableRemainingTime({ reloadFunction }: Props) {
    const [pause, setPause] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(defaultTableAutoRefreshDelay);
    const { t } = useTranslation();

    const timer = () => {
        var countdown = setInterval(() => {
            if (secondsLeft <= 0) {
                clearInterval(countdown);
                return;
            }

            if (pause === true) {
                clearInterval(countdown);
                return;
            }

            setSecondsLeft((sec) => sec - 1);
        }, 1000);


        return () => {
            if (secondsLeft === 1) {
                setSecondsLeft(defaultTableAutoRefreshDelay)
                clearInterval(countdown);
                reloadFunction();
            }
            else {
                clearInterval(countdown);
            }
        };
    };

    useEffect(timer, [secondsLeft, pause, reloadFunction]);

    const pauseTimer = () => {
        setPause((pause) => !pause);
    };

    return (
        <div>
            <span style={{lineHeight: 2}}>
                <span className={pause ? "text-danger pe-2" : "pe-2"}>{t("nextRefresh")} {secondsLeft}s</span>
                {pause ?
                    <PlayCircle className="text-success cursor-pointer mx-1" width={22} height={22} onClick={pauseTimer} />
                    :
                    <PauseCircle className="text-danger cursor-pointer mx-1" width={22} height={22} onClick={pauseTimer} />}

                <ArrowRepeat className="text-primary cursor-pointer" width={28} height={28} onClick={() => { reloadFunction(); setSecondsLeft(defaultTableAutoRefreshDelay) }} />
            </span>
        </div>
        )
}