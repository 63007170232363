import { makeAutoObservable } from "mobx";

export class HistoryPageStore {
    constructor() {
        makeAutoObservable(this);
    }

    dataSourceType: "auctions" | "orders" = "auctions";

    setDataSourceType = (val: string) => {
        if (val === "auctions" || val === "orders") {
            this.dataSourceType = val;
        }
    }
}





