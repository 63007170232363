import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, PencilSquare, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Approver } from '../../../app/models/approver';
import PaginationModel from '../../../app/models/paginatedResult';
import { QueryParamsTable } from '../../../app/models/queryParamsTable';

interface Props {
    approvers: Approver[],
    handleRowCommandButtonClick: (action: "addApprover" | "editApprover" | "deleteApprover", selectedApprover: Approver | null) => void,
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsTable,
    selectedApprover: Approver | null,
    selectApprover: (approver: Approver | null) => void,
    selectedTab: "addApprover" | "editApprover" | "deleteApprover"
}

export default function ApproversTable({ approvers, pagination, sort, queryParams, selectedApprover, selectApprover, handleRowCommandButtonClick, selectedTab }: Props) {
    const { t } = useTranslation();

    const columns = [
        { labelPhrase: "amount", accessor: "Amount" },
        { labelPhrase: "email", accessor: "Email" },
        { labelPhrase: "name", accessor: "Name" },
        { labelPhrase: "language", accessor: "LangId" },
        { labelPhrase: "send", accessor: "Active" },
        { labelPhrase: "", accessor: "" }
    ];

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            {Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)}
        </tr>
    )

    return (
        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => (
                        <th
                            key={index}
                            onClick={column.accessor
                                ? () => sort(column.accessor)
                                : undefined}
                            className={queryParams.sortBy === column.accessor
                                ? "th-sorted-by"
                                : undefined}
                            style={column.accessor
                                ? { cursor: "pointer" }
                                : undefined}
                        >
                            {t(column.labelPhrase)}
                            {queryParams.sortBy === column.accessor
                                ? queryParams.sortDirection === "ASC"
                                    ? <CaretUpFill className="ms-1" />
                                    : <CaretDownFill className="ms-1" />
                                : null}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {approvers.map((approver, index) => (
                    <tr
                        key={index}
                        className={(approver.amount === selectedApprover?.amount && approver.currency === selectedApprover?.currency && approver.email === selectedApprover?.email)
                            ? selectedTab === "editApprover" ? "selected-table-row" : "selected-table-row-delete"
                            : undefined}
                    >
                        <td>{approver.amount} {approver.currency}</td>
                        <td>{approver.email}</td>
                        <td>{approver.name}</td>
                        <td>{t(approver.langId)}</td>
                        <td>
                            <Form.Check
                                checked={approver.active}
                                disabled={true} />
                        </td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('editApprover', approver)}>
                                    <PencilSquare className='text-info' height={21} width={21} />
                                </span>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('deleteApprover', approver)}>
                                    <XCircleFill className='text-danger' height={21} width={21} />
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
                {emptyRows}
            </tbody>
        </Table>
    )
}