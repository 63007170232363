import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CarrierUserCreateUpdateDto } from '../../../app/models/carrierUserCreateUpdateDto';
import CarrierUserCreateUpdateValidator from '../../../app/models/validators/carrierUserCreateUpdateValidator';
import { useStore } from '../../../app/store/store';
import ValidationErrorList from '../../error/ValidationErrorList';

export default observer(function CarrierUserForm() {
    const { t } = useTranslation();
    const { carrierUsersStore, commonStore } = useStore();
    const { selectedUser, selectedTab, selectedFormCarrier, setShowSelectCarrierModal,
        setSelectedFormCarrier, setSelectedTab, setSelectedUser, initValue,
        carrierChangedFrom,
        blockAutoChangeFormCarrier, setBlockAutoChangeFormCarrier } = carrierUsersStore;

    const [validateOnChange, setValidateOnChange] = useState(false);
    const [apiErrors, setApiErrors] = useState(null);
    
    const selectedUserValues: CarrierUserCreateUpdateDto = {
        companyId: selectedUser?.companyId,
        carrierName: selectedUser?.companyName!,
        name: selectedUser?.name!,
        mobilePhone: selectedUser?.mobilePhone!,
        email: selectedUser?.email!,
        langId: selectedUser?.langId!,
        auctions: selectedUser?.auctions!
    }

    const handleSubmit = (val: CarrierUserCreateUpdateDto, actions: FormikHelpers<CarrierUserCreateUpdateDto>) => {
        if (selectedTab === "addUser") {
            carrierUsersStore.addUser(val)
                .then(() => {
                    actions.resetForm();
                    toast.success(t("contactAddedSuccessfully"));
                })
                .catch((err) => {
                    setApiErrors(err);
                    toast.error(t("contactAddedUnuccessfully"));
                })
                .finally(() => actions.setSubmitting(false))
        }
        else {
            carrierUsersStore.updateUser(val)
                .then(() => {
                    actions.resetForm();
                    toast.success(t("contactAddedSuccessfully"));
                })
                .catch((err) => {
                    setApiErrors(err);
                    toast.error(t("contactAddedUnuccessfully"));
                })
                .finally(() => actions.setSubmitting(false));
        }
    }

    useEffect(() => {
        document.getElementById("resetUserForm")?.click();
    }, [commonStore.selectedOwner])


    useEffect(() => {
        document.getElementById("trigger")?.click();
    }, [selectedFormCarrier])

    const isFormEmpty = (newUser: CarrierUserCreateUpdateDto) => {
        return newUser.name === initValue.name &&
            newUser.mobilePhone === initValue.mobilePhone &&
            newUser.email === initValue.email &&
            newUser.langId === initValue.langId
    }

    const handleTriggerCall = (newUser: CarrierUserCreateUpdateDto, setValue: any, setFieldTouched: any) => {

        const copySelectedCarrierToForm: boolean = (selectedTab === "addUser" &&
            (!blockAutoChangeFormCarrier && isFormEmpty(newUser) && carrierChangedFrom === "select")) ||
            (carrierChangedFrom === "modal")

        if (copySelectedCarrierToForm) {
            setValue("companyId", selectedFormCarrier?.id);
            setValue("carrierName", selectedFormCarrier ? selectedFormCarrier.name : '');
            validateOnChange && setTimeout(() => setFieldTouched("companyId", true));
        }
    }

    const clearForm = () => {
        setApiErrors(null);
        setSelectedFormCarrier(undefined);
        setValidateOnChange(false);
        setBlockAutoChangeFormCarrier(false);
    }

    return (
        <Formik
            enableReinitialize
            validateOnChange={validateOnChange}
            validateOnBlur={true}
            validate={formValues => new CarrierUserCreateUpdateValidator().validate(formValues)}
            initialValues={selectedUser ? selectedUserValues : initValue}
            onSubmit={(val, actions) => handleSubmit(val, actions)}
            onReset={() => clearForm()}
        >
            {({ values: newUser, handleChange, dirty, handleSubmit, setFieldValue, resetForm, errors, isSubmitting, setSubmitting, setFieldTouched }) => (
                <Form>
                    <Row className="mt-3">
                        <Col xs={12}>
                            <Form.Label htmlFor="carrierId" className="required">{t('carrier')}:</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    id="carrierId"
                                    disabled={true}
                                    name="carrierId"
                                    value={newUser.carrierName}
                                    onChange={handleChange}
                                    maxLength={10}
                                    isInvalid={!!errors.companyId}
                                />
                                <Button variant="outline-secondary" disabled={selectedTab === "editUser"} id="selectCarrier" onClick={() => setShowSelectCarrierModal(true)} >
                                    {t('select')}
                                </Button>
                            </InputGroup>
                            <div className="text-danger fs-14px mt-4px" >{errors.companyId}</div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={6} lg={12} xxl={6}>
                            <Form.Label htmlFor="name" className="required">{t('contactName')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                value={newUser.name}
                                onChange={handleChange}
                                maxLength={100}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.name}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                            <Form.Label htmlFor="mobilePhone">{t('contactPhone')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="mobilePhone"
                                name="mobilePhone"
                                value={newUser.mobilePhone || ''}
                                onChange={handleChange}
                                maxLength={15}
                                isInvalid={!!errors.mobilePhone}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.mobilePhone}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={6} lg={12} xxl={6}>
                            <Form.Label htmlFor="email">{t('contactemail')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                value={newUser.email}
                                onChange={handleChange}
                                maxLength={50}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.email}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                            <Form.Label htmlFor="langId">{t('language')}:</Form.Label>
                            <Form.Select
                                id="langId"
                                name="langId"
                                value={newUser.langId}
                                onChange={handleChange}
                                isInvalid={!!errors.langId}
                            >
                                <option value="">{t("chooseLanguage")}</option>
                                <option value="EN">{t("EN")}</option>
                                <option value="DE">{t("DE")}</option>
                                <option value="PL">{t("PL")}</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid' >{errors.langId}</Form.Control.Feedback>
                        </Col>

                    </Row>
                    {selectedTab === "editUser" ?
                        <Row className="mt-3">
                            <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                                <Form.Check
                                    type="checkbox"
                                    id="send"
                                    label={t("send")}
                                    className="mb-2"
                                    onChange={() => setFieldValue("auctions", !newUser.auctions)}
                                    checked={newUser.auctions}
                                />
                            </Col>
                        </Row> : null}
                    <Row className="mt-4">
                        <Col xs={12} className="text-center">
                            <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={(e: any) => {
                                e.preventDefault(); setSubmitting(true); setValidateOnChange(true); handleSubmit();
                            }}>
                                {isSubmitting ? <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                /> : null}
                                {isSubmitting ? (t("saving")) : t("save")}
                            </Button>
                            {selectedTab === "addUser" ?
                                <Button variant="secondary" id="resetUserForm" className="mx-1" type="reset" onClick={() => {
                                    resetForm({ values: initValue });
                                }} >
                                    {t("clear")}
                                </Button>
                                :
                                <Button variant="secondary" className="mx-1" onClick={() => {
                                    setSelectedTab("addUser");
                                    setSelectedUser(null);
                                    setSelectedFormCarrier(undefined);
                                }} >
                                    {t("cancel")}
                                </Button>
                            }
                            <Button
                                id="trigger"
                                name="trigger"
                                style={{ display: "none" }}
                                onClick={() => {
                                    handleTriggerCall(newUser, setFieldValue, setFieldTouched);
                                }}>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center">
                            {apiErrors !== null ?
                                <ValidationErrorList errorList={apiErrors} /> : null}
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
})