import { Formik, FormikHelpers } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { ExclamationCircleFill, InfoCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import agent from '../../app/api/agent';
import { OrderCreateDto } from '../../app/models/orderCreateDto';
import { PackageCreateDto } from '../../app/models/packageCreateDto';
import { PartCreateDto } from '../../app/models/partCreateDto';
import { SupplierCountry } from '../../app/models/supplierCountry';
import { Mode } from '../../app/models/mode';
import OrderCreateValidator from '../../app/models/validators/orderCreateValidator';
import { fixTimezoneForOnChangeCalendarEvent, fixTimezoneForSelectedCalendarDate } from '../common/functions/dateTimeManipulation';
import IntegerInput from '../common/IntegerInput';
import DatePicker, { registerLocale } from 'react-datepicker';
import { pl, enGB, de } from "date-fns/locale";
import ValidationErrorList from '../error/ValidationErrorList';
import { getBillToName, getTransportTypeName, getOrderReasonName, getResponsiblePartyName, getServiceLevel, getSupplierCountryName, getModeName } from '../common/functions/langObjectTranslation';
import AddingPartsModule from '../common/AddingPartsModule';
import AddingPackageModule from '../common/AddingPackageModule';
import AddingAdditionalCostsModule from '../common/AddingAdditionalCostsModule';
import { CreateAdditionalCostDto } from '../../app/models/createAdditionalCostDto';
import PriceSummaryPanel from './PriceSummaryPanel';
import SelectCarrierModal from '../common/SelectCarrierModal';
import SelectSupplierModal from '../newAuction/SelectSupplierModal';
import { TransportType } from '../../app/models/transportType';
import { OrderReason } from '../../app/models/orderReasonDto';
import { ServiceLevel } from '../../app/models/serviceLevel';
import { ResponsibleParty } from '../../app/models/responsibleParty';
import { BillTo } from '../../app/models/billTo';
import { CarrierListItem } from '../../app/models/carrierListItem';
import PriceInput from '../common/PriceInput';
import externalAgent from '../../app/api/externalAgent';
import { toast } from 'react-toastify';
import { ContractSelectListItem } from '../../app/models/contractSelectListItem';
import { Dock } from '../../app/models/dock';
import { FollowUpListItem } from '../../app/models/FollowUpListItem';
import StaticBackDropInfoModal from '../common/StaticBackDropInfoModal';
import UploadFiles from '../../app/api/UploadFileModule';
import { getNewGuid } from '../common/functions/GUID';
import { useStore } from '../../app/store/store';
import storage from '../../app/store/storage';
import { AdditionalCostType } from '../../app/models/additionalCostType';

export default function NewOrderPage() {
    const { t } = useTranslation();
    const { userStore } = useStore();

    registerLocale("pl", pl);
    registerLocale("en", enGB);
    registerLocale("de", de);
    const lang = storage.Lang.get();

    // ddl states
    const [sessionId, setSessionId] = useState<string>(getNewGuid());
    const [transportTypes, setTransportTypes] = useState<TransportType[]>([]);
    const [modes, setModes] = useState<Mode[]>([]);
    const [docks, setDocks] = useState<Dock[]>([]);
    const [orderPrimaryReasons, setOrderPrimaryReasons] = useState<OrderReason[]>([]);
    const [orderSecondaryReasons, setOrderSecondaryReasons] = useState<OrderReason[]>([]);
    const [orderSecondaryReasonsForSelectedPrimary, setOrderSecondaryReasonsForSelectedPrimary] = useState<OrderReason[]>([]);
    const [serviceLevels, setServiceLevels] = useState<ServiceLevel[]>([]);
    const [responsibibleParties, setReponsibleParties] = useState<ResponsibleParty[]>([]);
    const [billToItems, setBillToItems] = useState<BillTo[]>([]);
    const [followUps, setFollowUps] = useState<FollowUpListItem[]>([]);
    const [addistionalCosts, setAdditionalCosts] = useState<AdditionalCostType[]>([]);
    const [countries, setCountries] = useState<SupplierCountry[]>([]);
    const [contracts, setContracts] = useState<ContractSelectListItem[]>([]);
    const [insertedOrderNo, setInsertedOrderNo] = useState<string | undefined>(undefined);

    const loadTransportTypes = () => {
        agent.Selections.transportTypes()
            .then(response => setTransportTypes(response));
    }

    const loadCountries = () => {
        agent.Selections.countries()
            .then(response => setCountries(response));
    }

    const loadModes = () => {
        agent.Selections.modes()
            .then(response => setModes(response));
    }

    const loadPrimaryReasons = () => {
        agent.Selections.primaryReasons()
            .then((response) => { setOrderPrimaryReasons(response); });
    }

    const loadSecondaryReasons = (id: string) => {
        if (!orderSecondaryReasons.some(reason => reason.parentID === id)) {
            agent.Selections.secondaryReasons(id)
                .then((response) => {
                    const newReasons = orderSecondaryReasons.concat(response);

                    setOrderSecondaryReasons(newReasons);
                    setOrderSecondaryReasonsForSelectedPrimary(newReasons.filter(sr => sr.parentID === id));
                })
        } else {
            setOrderSecondaryReasonsForSelectedPrimary(orderSecondaryReasons.filter(sr => sr.parentID === id));
        }
    }

    const loadServiceLevels = () => {
        agent.Selections.serviceLevels().then(
            response => setServiceLevels(response));
    }

    const loadResponsibibleParties = () => {
        agent.Selections.responsibleParties()
            .then(response => setReponsibleParties(response));
    }

    const loadBillToItems = () => {
        agent.Selections.billToList()
            .then(response => setBillToItems(response));
    }

    const loadFollowUps = () => {
        agent.FollowUp.getSelectionList()
            .then(response => setFollowUps(response))
    }

    const loadAdditionalCosts = () => {
        agent.Selections.additionalCosts().then(
            response => setAdditionalCosts(response));
    }

    const loadOwnerDocksList = () => {
        agent.Docks.ownerList().then(
            response => setDocks(response));
    }

    const initialnewOrderData: OrderCreateDto = {
        carrierId: undefined,
        carrierName: "",
        contractId: undefined,
        contractType: "",
        transportTypeId: "",

        pickUpDateTime: null,
        deliveryDateTime: null,
        modeId: '',

        serviceLevelId: '',
        primaryReasonId: '',
        secondaryReasonId: '',

        responsiblePartyId: '',
        billToId: '',
        billToValue: '',

        billAgreedWith: '',
        logisticRefNo: '',
        followUpId: undefined, // requested by

        additionalCosts: [],
        packages: [],
        parts: [],
        uploadedFiles: [],

        weight: 0,

        pickupDuns: '',
        pickupName: '',
        pickupAddress: '',
        pickupZipCode: '',
        pickupCity: '',
        pickupCountryCodeId: '',
        pickupContactName: '',
        pickupContactPhoneNo: '',
        pickupContactEmail: '',
        pickupLatitude: 0,
        pickupLongitude: 0,

        deliveryDuns: '',
        deliveryName: '',
        deliveryAddress: '',
        deliveryZipCode: '',
        deliveryCity: '',
        deliveryCountryCodeId: '',
        deliveryContactName: '',
        deliveryContactPhoneNo: '',
        deliveryContactEmail: '',
        deliveryLatitude: 0,
        deliveryLongitude: 0,

        distance: 0,

        totalPrice: 0,
        contractTotalPrice: 0,
        additionalCostsPrice: 0,
        currencyId: "",

        remarks: "",

        userId: userStore.user?.userId!,
        userSource: userStore.user?.source!,
    }

    const initialNewAdditionalCostData: CreateAdditionalCostDto = {
        additionalCostTypeId: '',
        description: undefined,
        price: null
    }

    const initialNewPackageData: PackageCreateDto = {
        length: null,
        width: null,
        height: null,
        weight: null,
        qty: null
    }

    const initialNewPartData: PartCreateDto = {
        partNo: "",
        partQty: null,
        partName: "",
        unloadingDock: ""
    }

    const [newOrder] = useState<OrderCreateDto>(initialnewOrderData)
    const [validateOnChange, setValidateOnChange] = useState(false)
    const [apiErrors, setApiErrors] = useState(null)

    // Carrier
    const [showSelectCarrierModal, setShowSelectCarrierModal] = useState<boolean>(false);

    const handleCarrierSelect = (carrier: CarrierListItem | undefined, setFieldValue: any, setFieldTouched: any) => {
        if (carrier !== undefined) {
            setFieldValue("carrierName", carrier.name);
            setFieldValue("carrierId", carrier.id);

            agent.Contracts.getCarrierSelectList(carrier.id)
                .then((response) => setContracts(response));

            setShowSelectCarrierModal(false);

            recalculateTotalPrice(setFieldValue, null, selectedAdditionalCosts, distance, weight);

            validateOnChange && setTimeout(() => setFieldTouched("carrierId", true));
        }
    }

    // Contract
    const [selectedContract, setSelectedContract] = useState<ContractSelectListItem | undefined>(undefined);

    const handleContractSelect = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any, setFieldTouched: any) => {
        const id: number | undefined = Number(e.target.value) ? Number(e.target.value) : undefined;
        const contract = contracts.find(c => c.contractId === id);

        setSelectedContract(contract);
        setFieldValue('contractId', id);
        setFieldValue('contractType', contract?.contractType.typeId);
        setFieldValue('contractPrice', contract?.price);
        setFieldValue("currencyId", contract?.currency.currencyId);


        recalculateTotalPrice(setFieldValue, contract, selectedAdditionalCosts, distance, weight);

        validateOnChange && setTimeout(() => setFieldTouched("contractId", true));
    }

    const getContractName = (contract: ContractSelectListItem, currencyId: string) => {
        switch (contract.contractType.typeId) {
            case "D":
                return `${contract.name} (${contract.price}${contract.currency.currencySymbol}/km)`;
            case "W":
                return `${contract.name} (${contract.price}${contract.currency.currencySymbol}/kg)`;
            case "F":
                return `${contract.name} (${t("fixedPrice")}: ${contract.price}${contract.currency.currencySymbol})`;
            default:
                return `${contract.name}`
        }
    }

    // Primary reason
    const handleSelectPrimaryReason = (e: React.ChangeEvent<any>, setFieldValue: any, setFieldTouched: any) => {
        if (e.target.value !== '') {
            loadSecondaryReasons(e.target.value);
        }

        setFieldValue("primaryReasonId", e.target.value)
        setFieldValue("secondaryReasonId", '')

        validateOnChange && setTimeout(() => setFieldTouched("responsiblePartyId", true));
    }

    // Responsible party
    const handleResponsiblePartyChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any, setFieldTouched: any) => {
        const newResonsiblePartyId = e.target.value;

        setFieldValue("responsiblePartyId", newResonsiblePartyId);

        const responsibleParty = responsibibleParties.find(r => r.responsiblePartyID === newResonsiblePartyId)
        const newBillToID = responsibleParty ? responsibleParty.defaultBillToID : "CC";

        setFieldValue("billToId", newBillToID);
        validateOnChange && setTimeout(() => setFieldTouched("responsiblePartyId", true));
    }

    // BillTo
    const getBillToLabelName = (id: string ) => {
        if (id === '') return "CISCO/COFOR";

        const billTo = billToItems.find(b => b.billToID === id)

        if (billTo === undefined) return "CISCO/COFOR";

        return getBillToName(billTo, lang);
    }

    // Followup/Requested by
    const handleRequestedByChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any, setFieldTouched: any) => {
        const id: number | undefined = Number(e.target.value) ? Number(e.target.value) : undefined;

        setFieldValue("followUpId", id);

        const selectedFollowup = followUps.find(f => f.userId === id)
        if (selectedFollowup) {
            setFieldValue("deliveryContactName", selectedFollowup.name ? selectedFollowup.name : '');
            setFieldValue("deliveryContactPhoneNo", selectedFollowup.mobilePhone ? selectedFollowup.mobilePhone : '');
            setFieldValue("deliveryContactEmail", selectedFollowup.email ? selectedFollowup.email : '');
        }

        validateOnChange && setTimeout(() => setFieldTouched("followUpId", true));
    }

    // Additional costs
    const [newAdditionalCost, setNewAdditionalCost] = useState<CreateAdditionalCostDto>(initialNewAdditionalCostData);
    const [selectedAdditionalCosts, setSelectedAdditionalCosts] = useState<CreateAdditionalCostDto[]>([]);

    const handleAdditionalCostsChange = (costs: CreateAdditionalCostDto[], setFieldValue: any) => {
        setFieldValue("selectedAdditionalCosts", costs);
        setSelectedAdditionalCosts(costs);
        setNewAdditionalCost(initialNewAdditionalCostData)

        recalculateTotalPrice(setFieldValue, selectedContract, costs, distance, weight);
    }

    // Packages
    const [newPackage, setNewPackage] = useState<PackageCreateDto>(initialNewPackageData)
    const [autoSumPackages, setAutoSumPackages] = useState(false)

    const handlePackagesChange = (packages: PackageCreateDto[], setFieldValue: any) => {
        const sumWeight: number = packages.reduce(function (pv, cv) {
            return parseInt(pv.toString()) + (parseInt(cv.qty!.toString()) * parseInt(cv.weight!.toString()));
        }, 0);

        recalculateTotalPrice(setFieldValue, selectedContract, selectedAdditionalCosts, distance, sumWeight);
    }

    // Parts
    const [newPart, setNewPart] = useState<PartCreateDto>(initialNewPartData)

    // Weight
    const [weight, setWeight] = useState<number>(0);

    const handleRecalculateWeightButtonClick = (packages: PackageCreateDto[], setFieldValue: any, setFieldTouched: any) => {
        const sumWeight: number = packages.reduce(function (pv, cv) {
            return parseInt(pv.toString()) + (parseInt(cv.qty!.toString()) * parseInt(cv.weight!.toString()));
        }, 0);

        setFieldValue("weight", sumWeight);
        setWeight(sumWeight);

        validateOnChange && setTimeout(() => setFieldTouched("weight", true));

        setAutoSumPackages(true);
        recalculateTotalPrice(setFieldValue, selectedContract, selectedAdditionalCosts, distance, sumWeight);
    }

    const handleTotalWeightChanged = (e: ChangeEvent<HTMLInputElement>, setFieldValue: any, setFieldTouched: any) => {
        const newWeight: number = e.target.value !== '' ? Number(e.target.value) : 0;
        setFieldValue("weight", newWeight);
        setWeight(newWeight);


        setAutoSumPackages(false);
        recalculateTotalPrice(setFieldValue, selectedContract, selectedAdditionalCosts, distance, newWeight);

        validateOnChange && setTimeout(() => setFieldTouched("weight", true));
    }

    //Suppliers modal
    const [showSelectSupplierModal, setShowSelectSupplierModal] = useState<boolean>(false);
    const [supplierDataFor, setSupplierDataFor] = useState<"pickup" | "delivery">("pickup");

    const handleSupplierSelectFromList = (supplierDuns: string, setFieldValue: any, setFieldTouched: any) => {
        agent.Supplier.byDuns(supplierDuns).then(response => {
            if (supplierDataFor === "pickup") {
                setFieldValue("pickupDuns", response.supplierDuns)
                setFieldValue("pickupName", response.supplierName)
                setFieldValue("pickupAddress", response.supplierStreet)
                setFieldValue("pickupCity", response.supplierCity)
                setFieldValue("pickupZipCode", response.supplierZipCode)
                setFieldValue("pickupCountryCodeId", response.supplierCountryCode)
                setFieldValue("pickupLatitude", response.supplierLatitude)
                setFieldValue("pickupLongitude", response.supplierLongitude)
                setPickupLatitude(response.supplierLatitude);
                setPickupLongitude(response.supplierLongitude);

                if (response.supplierLatitude !== 0 &&
                    response.supplierLongitude !== 0 &&
                    deliveryLatitude !== 0 &&
                    deliveryLongitude !== 0) {
                    calculateDistance(response.supplierLatitude, response.supplierLongitude, deliveryLatitude, deliveryLongitude, setFieldValue, setFieldTouched);
                }
            }
            else {
                setFieldValue("deliveryDuns", response.supplierDuns)
                setFieldValue("deliveryName", response.supplierName)
                setFieldValue("deliveryAddress", response.supplierStreet)
                setFieldValue("deliveryCity", response.supplierCity)
                setFieldValue("deliveryZipCode", response.supplierZipCode)
                setFieldValue("deliveryCountryCodeId", response.supplierCountryCode)
                setFieldValue("deliveryLatitude", response.supplierLatitude)
                setFieldValue("deliveryLongitude", response.supplierLongitude)
                setDeliveryLatitude(response.supplierLatitude);
                setDeliveryLongitude(response.supplierLongitude);

                if (pickupLatitude !== 0 &&
                    pickupLongitude !== 0 &&
                    response.supplierLatitude !== 0 &&
                    response.supplierLongitude !== 0) {

                    calculateDistance(pickupLatitude, pickupLongitude, response.supplierLatitude, response.supplierLongitude, setFieldValue, setFieldTouched);
                }
            }
            setShowSelectSupplierModal(false);
        })
    }

    // Distance
    const [distance, setDistance] = useState<number>(0);
    const [isAutoGeneratedDistance, setAutoGeneratedDistance] = useState<true | false | "error">(false);
    const [pickupLatitude, setPickupLatitude] = useState<number>(0);
    const [pickupLongitude, setPickupLongitude] = useState<number>(0);
    const [deliveryLatitude, setDeliveryLatitude] = useState<number>(0);
    const [deliveryLongitude, setDeliveryLongitude] = useState<number>(0);
    const [recalculateDistanceTriggerFlag, setRecalculateDistanceTriggerFlag] = useState<boolean>(false);

    const handleTotalDistanceChanged = (e: ChangeEvent<HTMLInputElement>, setFieldValue: any, setFieldTouched: any) => {
        const newDistance: number = Number(e.target.value);
        setFieldValue("distance", newDistance);
        setDistance(newDistance);

        validateOnChange && setTimeout(() => setFieldTouched("distance", true));

        setAutoGeneratedDistance(false);
        recalculateTotalPrice(setFieldValue, selectedContract, selectedAdditionalCosts, newDistance, weight);
    }

    const handleCalculateDistanceButtonClick = (setFieldValue: (field: string, value: any) => void, setFieldTouched: any, pickupAddress: string, pickupCity: string, pickupCountryCodeId: string, deliveryAddress: string, deliveryCity: string, deliveryCountryCode: string) => {

        if (pickupLatitude !== 0 &&
            pickupLongitude !== 0 &&
            deliveryLatitude !== 0 &&
            deliveryLongitude !== 0) {
            // calculate distance (OLS API)
            calculateDistance(pickupLatitude, pickupLongitude, deliveryLatitude, deliveryLongitude, setFieldValue, setFieldTouched);
        }
        else {
            // get coordinates (Google API)
            if ((pickupLatitude === 0 || pickupLongitude === 0) && pickupAddress && pickupCity && pickupCountryCodeId) {
                externalAgent.Google.getLocationCoordinates(pickupAddress, pickupCity, pickupCountryCodeId)
                    .then((response) => {
                        setPickupLatitude(response.results[0].geometry.location.lng);
                        setPickupLongitude(response.results[0].geometry.location.lat);

                        setRecalculateDistanceTriggerFlag(!recalculateDistanceTriggerFlag)
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }

            if ((deliveryLatitude === 0 || deliveryLongitude === 0) && deliveryAddress && deliveryCity && deliveryCountryCode) {
                externalAgent.Google.getLocationCoordinates(deliveryAddress, deliveryCity, deliveryCountryCode)
                    .then((response) => {
                        setDeliveryLatitude(response.results[0].geometry.location.lng);
                        setDeliveryLongitude(response.results[0].geometry.location.lat);
                        setRecalculateDistanceTriggerFlag(!recalculateDistanceTriggerFlag)

                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        }
    }

    const handleRecalculateTriggerClick = (setFieldValue: any, setFieldTouched: any) => {
        calculateDistance(pickupLatitude, pickupLongitude, deliveryLatitude, deliveryLongitude, setFieldValue, setFieldTouched);
    }

    const calculateDistance = (srcLat: number, srcLon: number, dstLat: number, dstLon: number, setFieldValue: any, setFieldTouched: any) => {
        if (srcLat !== 0 && srcLon !== 0 && dstLat !== 0 && dstLon !== 0) {
            externalAgent.OLSRoute.get(srcLat, srcLon, dstLat, dstLon)
                .then(response => {

                    //setMapRouteModel(response);
                    const distanceKm = Math.round(response.routes[0].distance / 1000);

                    if (distanceKm > 0) {
                        setFieldValue("distance", distanceKm);
                        setDistance(distanceKm);
                        setAutoGeneratedDistance(true);
                        recalculateTotalPrice(setFieldValue, selectedContract, selectedAdditionalCosts, distanceKm, weight);
                    }
                    else {
                        //setMapRouteModel(undefined);
                        setAutoGeneratedDistance("error");
                    }
                })
                .catch((err) => {
                    //setMapRouteModel(undefined);
                    setAutoGeneratedDistance("error");
                });
        }

        validateOnChange && setTimeout(() => setFieldTouched("distance", true));
    }

    const resetPickupCoordinates = () => {
        setPickupLatitude(0);
        setPickupLongitude(0);
    }

    const resetDeliveryCoordinates = () => {
        setDeliveryLatitude(0);
        setDeliveryLongitude(0);
    }

    // Price
    const [autoSumTotalPrice, setAutoSumTotalPrice] = useState(true)
    const recalculateTotalPrice = (setFieldValue: (field: string, value: any) => void, newContract: ContractSelectListItem | undefined | null, selectedAdditionalCosts: CreateAdditionalCostDto[], distance: number, weight: number) => {

        let contractPrice = 0;
        if (newContract) {

            switch (newContract.contractType.typeId) {
                case "D":
                    contractPrice = distance * newContract.price;
                    break;
                case "W":
                    contractPrice = weight * newContract.price;
                    break;
                case "F":
                    contractPrice = newContract.price;
                    break;
                default:
            }
        }

        if (newContract === null) {
            contractPrice = 0;
        }

        let additionalCosts: number = 0;
        selectedAdditionalCosts.forEach(cost => {
            if (cost.price) {
                additionalCosts = Number(additionalCosts) + Number(cost.price);
            }
        });

        const finalPrice = contractPrice + additionalCosts;

        setFieldValue("totalPrice", finalPrice);
        setFieldValue("contractTotalPrice", contractPrice);
        setFieldValue("additionalCostsPrice", additionalCosts);
    }

    // FORM
    function handleSubmit(val: OrderCreateDto, actions: FormikHelpers<OrderCreateDto>) {
        actions.setSubmitting(true);
        {
            const nullableData = JSON.parse(JSON.stringify(val), (key, value) => {
                return value === '' ? null : value;
            });

            agent.Orders.add(nullableData)
                .then((response) => {
                    agent.Orders.getOrderNo(response)
                        .then(response => {
                            if (response) {
                                setInsertedOrderNo(response);
                            }
                        });
                    toast.success(t("orderaddedsuccessfully"));
                    actions.resetForm({ values: initialnewOrderData });
                })
                .catch(err => {
                    toast.warning(t("auctionnotadded"));
                    setApiErrors(err);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                })
        }
    }

    const clearForm = (actions: FormikHelpers<OrderCreateDto>) => {
        setValidateOnChange(false);
        setAutoSumPackages(false);
        setNewPart(initialNewPartData);
        setNewPackage(initialNewPackageData);
        setDistance(0);
        setSelectedContract(undefined);
        setAutoSumTotalPrice(true);
        setSelectedAdditionalCosts([]);
        setPickupLatitude(0);
        setPickupLongitude(0);
        setDeliveryLatitude(0);
        setDeliveryLongitude(0);
        setSessionId(getNewGuid());
        setApiErrors(null);
    }

    useEffect(() => {
        loadCountries();
        loadTransportTypes();
        loadModes();
        loadPrimaryReasons();
        loadServiceLevels();
        loadResponsibibleParties();
        loadBillToItems();
        loadFollowUps();
        loadAdditionalCosts();
        loadOwnerDocksList();
    }, []);

    useEffect(() => {
        document.getElementById("recalculateTrigger")?.click();
    }, [pickupLatitude, pickupLongitude, deliveryLatitude, deliveryLongitude])

    return (
        <Container fluid className='mt-2'>
            <Row className="justify-content-center">
                <Col xs={12} lg={11} xl={10} xxl={9}>
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={validateOnChange}
                        validateOnBlur={true}
                        validate={formValues => new OrderCreateValidator().validate(formValues)}
                        initialValues={newOrder}
                        onSubmit={(val, actions) => handleSubmit(val, actions)}
                        onReset={(val, actions) => clearForm(actions)}>
                        {({ values: newOrder, handleChange, handleSubmit, setFieldValue, errors, setErrors, setFieldTouched, validateForm, isSubmitting, resetForm }) => (
                            <Form onSubmit={handleSubmit} >
                                <Row className='shadow mt-2 p-1 p-sm-2 p-md-3 rounded g-3 bg-white mx-0 new-order-form' >
                                    <Col md={4}>
                                        <Form.Label htmlFor="carrierName" className="required">{t('carrier')}:</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                id="carrierName"
                                                disabled={true}
                                                name="carrierName"
                                                value={newOrder.carrierName}
                                                onChange={handleChange}
                                                maxLength={10}
                                                isInvalid={!!errors.carrierId}
                                            />
                                            <Button variant="outline-secondary" id="selectCarrier" onClick={() => setShowSelectCarrierModal(true)}>
                                                {t('select')}
                                            </Button>
                                        </InputGroup>
                                        <div className="text-danger fs-14px mt-4px" >{errors.carrierId}</div>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="contract" className="required">{t('contract')}:</Form.Label>
                                        <Form.Select
                                            id="contractId"
                                            name="contractId"
                                            value={newOrder.contractId || undefined}
                                            onChange={(e) => handleContractSelect(e, setFieldValue, setFieldTouched)}
                                            disabled={newOrder.carrierId === undefined}
                                            isInvalid={!!errors.contractId}>
                                            {newOrder.carrierId ?
                                                <>
                                                    <option value={undefined} >{t("chooseContract")}</option>
                                                    {contracts.map(contract => (
                                                        <option key={contract.contractId} value={contract.contractId}>{getContractName(contract, newOrder.currencyId)}</option>
                                                    ))}</>
                                                : <option value={''} >{t("chooseCarrierFirst")}</option>
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.contractId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="transportTypeId" className="required">{t("transportType")}:</Form.Label>
                                        <Form.Select
                                            id="transportTypeId"
                                            aria-label="Transport type"
                                            name="transportTypeId"
                                            value={newOrder.transportTypeId}
                                            onChange={handleChange}
                                            isInvalid={!!errors.transportTypeId}>
                                            <option value={''} >{t("choosetype")}</option>
                                            {transportTypes.map(tt => (
                                                <option key={tt.transportTypeId} value={tt.transportTypeId} >{getTransportTypeName(tt, lang)}</option>
                                            ))}

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.transportTypeId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupDatetime" className="required">{t('pickupdatetime')}:</Form.Label>
                                        <DatePicker
                                            name="pickUpDateTime"
                                            className={!!errors.pickUpDateTime ? "form-control is-invalid" : "form-control"}
                                            selected={fixTimezoneForSelectedCalendarDate(newOrder.pickUpDateTime)}
                                            onChange={(date: Date) => setFieldValue('pickUpDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                            todayButton={t('today')}
                                            locale={lang}
                                            dateFormat="dd.MM.yyyy HH:mm"
                                            placeholderText="__.__.____ __:__"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            autoComplete="off"
                                        />
                                        <div className="text-danger fs-14px mt-4px" >{errors.pickUpDateTime}</div>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryDatetime" className="required">{t('deliverydatetime')}:</Form.Label>
                                        <DatePicker
                                            name="deliveryDateTime"
                                            className={!!errors.deliveryDateTime ? "form-control is-invalid" : "form-control"}
                                            selected={fixTimezoneForSelectedCalendarDate(newOrder.deliveryDateTime)}
                                            onChange={(date: any) => setFieldValue('deliveryDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                            todayButton={t('today')}
                                            locale={lang}
                                            dateFormat="dd.MM.yyyy HH:mm"
                                            placeholderText="__.__.____ __:__"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            minDate={newOrder.pickUpDateTime}
                                            autoComplete="off"
                                        />
                                        <div className="text-danger fs-14px mt-4px" >{errors.deliveryDateTime}</div>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="modeId" className="required">{t("mode")}:</Form.Label>
                                        <Form.Select
                                            id="modeId"
                                            aria-label="mode"
                                            name="modeId"
                                            value={newOrder.modeId}
                                            onChange={handleChange}
                                            isInvalid={!!errors.modeId}>
                                            <option value={''} >{t("choosetype")}</option>
                                            {modes.map(mode => (
                                                <option key={mode.modeId} value={mode.modeId} >{getModeName(mode, lang)}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.modeId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="serviceLevelId" className="required">{t("serviceLevel")}:</Form.Label>
                                        <Form.Select
                                            id="serviceLevelId"
                                            aria-label="service level"
                                            name="serviceLevelId"
                                            value={newOrder.serviceLevelId}
                                            onChange={handleChange}
                                            isInvalid={!!errors.serviceLevelId}>
                                            <option value={''} >{t("chooseLevel")}</option>
                                            {serviceLevels.map(serviceLevel => (
                                                <option key={serviceLevel.serviceLevelID} value={serviceLevel.serviceLevelID} >{getServiceLevel(serviceLevel, lang)}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.serviceLevelId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="primaryReasonId" className="required">{t("primaryReason")}:</Form.Label>
                                        <Form.Select
                                            id="primaryReasonId"
                                            aria-label="primary reason"
                                            name="primaryReasonId"
                                            value={newOrder.primaryReasonId}
                                            onChange={(e: ChangeEvent<HTMLSelectElement>) => { handleSelectPrimaryReason(e, setFieldValue, setFieldTouched) }}
                                            isInvalid={!!errors.primaryReasonId}>
                                            <option value={''} >{t("chooseReason")}</option>
                                            {orderPrimaryReasons.map((reason, index) =>
                                            (
                                                <option key={index} value={reason.reasonID} > {getOrderReasonName(reason, lang)}</option>
                                            ))}

                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.primaryReasonId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="secondaryReasonId" >{t("secondaryReason")}:</Form.Label>
                                        <Form.Select
                                            id="secondaryReasonId"
                                            aria-label="secondary reason"
                                            name="secondaryReasonId"
                                            value={newOrder.secondaryReasonId}
                                            onChange={handleChange}
                                            isInvalid={!!errors.secondaryReasonId}>
                                            <option value={''} >{t("chooseReason")}</option>
                                            {orderSecondaryReasonsForSelectedPrimary.map(reason => (
                                                <option key={reason.reasonID} value={reason.reasonID} >{getOrderReasonName(reason, lang)}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.secondaryReasonId}</Form.Control.Feedback>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Label htmlFor="responsiblePartyId" className="required">{t("responsibleParty")}:</Form.Label>
                                        <Form.Select
                                            id="responsiblePartyId"
                                            aria-label="responsible party"
                                            name="responsiblePartyId"
                                            value={newOrder.responsiblePartyId}
                                            onChange={(e) => { handleResponsiblePartyChange(e, setFieldValue, setFieldTouched) }}
                                            isInvalid={!!errors.responsiblePartyId}>
                                            <option value={''} >{t("chooseOption")}</option>
                                            {responsibibleParties.map(rp => (
                                                <option key={rp.responsiblePartyID} value={rp.responsiblePartyID}>{getResponsiblePartyName(rp, lang)}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.responsiblePartyId}</Form.Control.Feedback>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Label htmlFor="billToId" className="required">{t("billTo")}:</Form.Label>
                                        <Form.Select
                                            id="billToId"
                                            aria-label="bill to"
                                            name="billToId"
                                            value={newOrder.billToId}
                                            onChange={handleChange}
                                            isInvalid={!!errors.billToId}>
                                            <option value={''} >{t("chooseOption")}</option>
                                            {billToItems.map(item => (
                                                <option key={item.billToID} value={item.billToID}>{getBillToName(item, lang)}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.billToId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="billToValue" className="required">{getBillToLabelName(newOrder.billToId)}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="billToValue"
                                            name="billToValue"
                                            value={newOrder.billToValue}
                                            onChange={handleChange}
                                            maxLength={10}
                                            isInvalid={!!errors.billToValue}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.billToValue}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="billAgreedWith">{t("billAgreedWith")}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="billAgreedWith"
                                            name="billAgreedWith"
                                            value={newOrder.billAgreedWith || ''}
                                            onChange={handleChange}
                                            maxLength={35}
                                            isInvalid={!!errors.billAgreedWith}
                                        />
                                        <Form.Control.Feedback type='invalid' >{errors.billAgreedWith}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="billAgreedWith">{t("logisticRefNo")}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="logisticRefNo"
                                            name="logisticRefNo"
                                            value={newOrder.logisticRefNo || ''}
                                            onChange={handleChange}
                                            maxLength={10}
                                            isInvalid={!!errors.logisticRefNo}
                                        />
                                        <Form.Control.Feedback type='invalid' >{errors.logisticRefNo}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="followUpName" className="required">{t("requestedBy")}:</Form.Label>
                                        <Form.Select
                                            id="followUpId"
                                            aria-label="followUpId"
                                            name="followUpId"
                                            value={newOrder.followUpId || ''}
                                            onChange={(e) => { handleRequestedByChange(e, setFieldValue, setFieldTouched); }}
                                            isInvalid={!!errors.followUpId}>
                                            <option value={''} >{t("choosePerson")}</option>
                                            {followUps.map(fu => (
                                                <option key={fu.userId} value={fu.userId}>{fu.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid' >{errors.followUpId}</Form.Control.Feedback>
                                    </Col>

                                    <Col md={4}></Col>
                                    <Col className="mt-0 mt-md-4 px-0" xs={12}>
                                        <h6 className="fw-bolder">{t('addAdditionalCosts')}:</h6>
                                        <AddingAdditionalCostsModule
                                            additionalCostsListData={addistionalCosts}
                                            newOrderAdditionalCosts={newOrder.additionalCosts}
                                            currencyId={newOrder.currencyId}
                                            onChange={setFieldValue}
                                            newAdditionalCost={newAdditionalCost}
                                            setNewAdditionalCost={setNewAdditionalCost}
                                            initialNewAdditionalCostData={initialNewAdditionalCostData}
                                            lang={lang}
                                            setSelectedAdditionalCosts={setSelectedAdditionalCosts}
                                            costChange={(costs) => handleAdditionalCostsChange(costs, setFieldValue)}
                                        />
                                    </Col>

                                    <Col className="mt-0 mt-md-4 px-0" xs={12}>
                                        <h6 className="fw-bolder">{t('addparts')}:</h6>
                                        <AddingPartsModule
                                            parts={newOrder.parts}
                                            ownerDocks={docks}
                                            onChange={setFieldValue}
                                            newPart={newPart}
                                            setNewPart={setNewPart}
                                            initialNewPartData={initialNewPartData}
                                        />
                                    </Col>
                                    <Col className="mt-0 mt-md-3" xs={12}>
                                        <h6 className="fw-bolder">{t('addpackages')}:</h6>
                                        <AddingPackageModule
                                            packages={newOrder.packages}
                                            onChange={setFieldValue}
                                            autoSumPackages={autoSumPackages}
                                            newPackage={newPackage}
                                            setNewPackage={setNewPackage}
                                            initialNewPackageData={initialNewPackageData}
                                            getListFunction={(val) => handlePackagesChange(val, setFieldValue)}
                                            setSumOfWeightFunctionOutsideFirmik={(val) => setWeight(val)}
                                            setAutoSumPackages={setAutoSumPackages}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="weight" className={newOrder.contractType === "W" ? "required" : undefined}>{t("totalweight")}:</Form.Label>
                                        {autoSumPackages &&
                                            <Form.Label className="ms-2 text-success fw-bold">
                                                <InfoCircleFill className="pb-1 fw-bold" height={20} width={20} />
                                                {t('sumPackagesWeight')}
                                            </Form.Label>}
                                        <InputGroup>
                                            <IntegerInput
                                                id="weight"
                                                name="weight"
                                                zeroAlwaysVisible={true}
                                                value={newOrder.weight}
                                                change={(e) => { handleTotalWeightChanged(e, setFieldValue, setFieldTouched); }}
                                                isInvalid={!!errors.weight}
                                            />
                                            <Button variant="outline-secondary" id="recalculateWeight" onClick={() => handleRecalculateWeightButtonClick(newOrder.packages, setFieldValue, setFieldTouched)}>
                                                {t("recalculate")}
                                            </Button>
                                        </InputGroup>
                                        <div className="text-danger fs-14px mt-4px" >{errors.weight}</div>
                                    </Col>
                                    <Col xs={12}>
                                        <p className="h5 fw-bold">{t("pickupLocation")}:</p>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="supplierDunsCofor">{t('dunscofor')}:</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                id="pickupDuns"
                                                name="pickupDuns"
                                                value={newOrder.pickupDuns || '' }
                                                onChange={(e) => { handleChange(e);}}
                                                maxLength={10}
                                                isInvalid={!!errors.pickupDuns}
                                            />
                                            <Button variant="outline-secondary" id="selectPickupDuns" onClick={() => { setShowSelectSupplierModal(true); setSupplierDataFor("pickup"); }}>
                                                {t('select')}
                                            </Button>
                                        </InputGroup>
                                        <Form.Control.Feedback type='invalid'>{errors.pickupDuns}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupName" className="required">{t('name')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="pickupName"
                                            name="pickupName"
                                            value={newOrder.pickupName || ''}
                                            onChange={handleChange}
                                            maxLength={50}
                                            isInvalid={!!errors.pickupName}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.pickupName}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupAddress" className="required">{t('street')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="pickupAddress"
                                            name="pickupAddress"
                                            value={newOrder.pickupAddress || ''}
                                            onChange={(val) => { handleChange(val); resetPickupCoordinates(); }}
                                            maxLength={100}
                                            isInvalid={!!errors.pickupAddress}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.pickupAddress}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupZipCode" className="required">{t('zipcode')}:</Form.Label>
                                        <Form.Control
                                            id="pickupZipCode"
                                            type="text"
                                            name="pickupZipCode"
                                            value={newOrder.pickupZipCode || ''}
                                            onChange={handleChange}
                                            maxLength={10}
                                            isInvalid={!!errors.pickupZipCode}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.pickupZipCode}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupCity" className="required">{t('city')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="pickupCity"
                                            name="pickupCity"
                                            value={newOrder.pickupCity || ''}
                                            onChange={(val) => { handleChange(val); resetPickupCoordinates(); }}
                                            maxLength={35}
                                            isInvalid={!!errors.pickupCity}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.pickupCity}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupCountryCodeId" className="required">{t('country')}:</Form.Label>
                                        <Form.Select
                                            id="pickupCountryCodeId"
                                            aria-label="Supplier cuntry"
                                            name="pickupCountryCodeId"
                                            value={newOrder.pickupCountryCodeId || ''}
                                            onChange={(val) => { handleChange(val); resetPickupCoordinates(); }}
                                            isInvalid={!!errors.pickupCountryCodeId}
                                        >
                                            <option value="">{t('choosecountry')}</option>
                                            {countries && countries.map((country, index) => (
                                                <option key={index} value={country.supplierCountryCode}>
                                                    {getSupplierCountryName(country, lang)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors.pickupCountryCodeId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupContactName">{t('contactName')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="pickupContactName"
                                            name="pickupContactName"
                                            value={newOrder.pickupContactName || ''}
                                            onChange={handleChange}
                                            maxLength={50}
                                            isInvalid={!!errors.pickupContactName}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.pickupContactName}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupContactPhoneNo">{t('contactPhone')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="pickupContactPhoneNo"
                                            name="pickupContactPhoneNo"
                                            value={newOrder.pickupContactPhoneNo || ''}
                                            onChange={handleChange}
                                            maxLength={15}
                                            isInvalid={!!errors.pickupContactPhoneNo}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.pickupContactPhoneNo}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="pickupContactEmail">{t('contactemail')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="pickupContactEmail"
                                            name="pickupContactEmail"
                                            value={newOrder.pickupContactEmail || ''}
                                            onChange={handleChange}
                                            maxLength={50}
                                            isInvalid={!!errors.pickupContactEmail}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.pickupContactEmail}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12}>
                                        <p className="h5 fw-bold">
                                            {t('deliveryLocation')}:
                                        </p>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="supplierDunsCofor">{t('dunscofor')}:</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                id="deliveryDuns"
                                                name="deliveryDuns"
                                                value={newOrder.deliveryDuns || ''}
                                                onChange={handleChange}
                                                maxLength={10}
                                                isInvalid={!!errors.deliveryDuns}
                                            />
                                            <Button variant="outline-secondary" id="selectDeliveryDuns" onClick={() => { setShowSelectSupplierModal(true); setSupplierDataFor("delivery"); }}>
                                                {t('select')}
                                            </Button>
                                        </InputGroup>
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryDuns}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryName" className="required">{t('name')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="deliveryName"
                                            name="deliveryName"
                                            value={newOrder.deliveryName || ''}
                                            onChange={handleChange}
                                            maxLength={50}
                                            isInvalid={!!errors.deliveryName}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryName}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryAddress" className="required">{t('street')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="deliveryAddress"
                                            name="deliveryAddress"
                                            value={newOrder.deliveryAddress || ''}
                                            onChange={(val) => { handleChange(val); resetDeliveryCoordinates(); }}
                                            maxLength={100}
                                            isInvalid={!!errors.deliveryAddress}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryAddress}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryZipCode" className="required">{t('zipcode')}:</Form.Label>
                                        <Form.Control
                                            id="deliveryZipCode"
                                            type="text"
                                            name="deliveryZipCode"
                                            value={newOrder.deliveryZipCode || ''}
                                            onChange={handleChange}
                                            maxLength={10}
                                            isInvalid={!!errors.deliveryZipCode}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryZipCode}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryCity" className="required">{t('city')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="deliveryCity"
                                            name="deliveryCity"
                                            value={newOrder.deliveryCity || ''}
                                            onChange={(val) => { handleChange(val); resetDeliveryCoordinates(); }}
                                            maxLength={35}
                                            isInvalid={!!errors.deliveryCity}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryCity}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryCountryCodeId" className="required">{t('country')}:</Form.Label>
                                        <Form.Select
                                            id="deliveryCountryCodeId"
                                            aria-label="Supplier cuntry"
                                            name="deliveryCountryCodeId"
                                            value={newOrder.deliveryCountryCodeId || ''}
                                            onChange={(val) => { handleChange(val); resetDeliveryCoordinates(); }}
                                            isInvalid={!!errors.deliveryCountryCodeId}
                                        >
                                            <option value="">{t('choosecountry')}</option>
                                            {countries && countries.map((country, index) => (
                                                <option key={index} value={country.supplierCountryCode}>
                                                    {getSupplierCountryName(country, lang)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryCountryCodeId}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryContactName">{t('contactFollowUpName')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="deliveryContactName"
                                            name="deliveryContactName"
                                            value={newOrder.deliveryContactName || ''}
                                            onChange={handleChange}
                                            maxLength={50}
                                            isInvalid={!!errors.deliveryContactName}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryContactName}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryContactPhoneNo">{t('contactPhone')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="deliveryContactPhoneNo"
                                            name="deliveryContactPhoneNo"
                                            value={newOrder.deliveryContactPhoneNo || ''}
                                            onChange={handleChange}
                                            maxLength={15}
                                            isInvalid={!!errors.deliveryContactPhoneNo}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryContactPhoneNo}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="deliveryContactEmail">{t('contactemail')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="deliveryContactEmail"
                                            name="deliveryContactEmail"
                                            value={newOrder.deliveryContactEmail || ''}
                                            onChange={handleChange}
                                            maxLength={50}
                                            isInvalid={!!errors.deliveryContactEmail}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.deliveryContactEmail}</Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="totalDistance" className={newOrder.contractType === "D" ? "required" : undefined}>{t("totalDistance")}:</Form.Label>
                                        {isAutoGeneratedDistance === true ?
                                            <Form.Label className="ms-2 text-success fw-bold">
                                                <InfoCircleFill className="pb-1 fw-bold" height={20} width={20} />
                                                {t('calculatedBySystem')}
                                            </Form.Label> : null}
                                        {isAutoGeneratedDistance === "error" ?
                                            <Form.Label className="ms-2 text-danger fw-bold">
                                                <ExclamationCircleFill className="pb-1 fw-bold" height={20} width={20} />
                                                {t('calculationFailed')}
                                            </Form.Label> : null}
                                        <InputGroup>
                                            <IntegerInput
                                                id="distance"
                                                name="distance"
                                                value={newOrder.distance}
                                                change={(e) => handleTotalDistanceChanged(e, setFieldValue, setFieldTouched)}
                                                isInvalid={!!errors.distance}
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                id="recalculateTotalDistance"
                                                onClick={() => handleCalculateDistanceButtonClick(setFieldValue, setFieldTouched, newOrder.pickupAddress, newOrder.pickupCity, newOrder.pickupCountryCodeId, newOrder.deliveryAddress, newOrder.deliveryCity, newOrder.deliveryCountryCodeId)}>
                                                {t("recalculate")}
                                            </Button>

                                        </InputGroup>
                                        <div className="text-danger fs-14px mt-4px" >{errors.distance}</div>
                                        <Button id="recalculateTrigger" type="button" style={{ display: "none" }} onClick={() => {
                                            handleRecalculateTriggerClick(setFieldValue, setFieldTouched);
                                        }}>
                                        </Button>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="price" className="required">{t("totalPrice")}:</Form.Label>
                                        {autoSumTotalPrice &&
                                            <Form.Label className="ms-2 text-success fw-bold">
                                                <InfoCircleFill className="pb-1 fw-bold" height={20} width={20} />
                                                {t('calculatedBySystem')}
                                            </Form.Label>}
                                        <InputGroup>
                                            <PriceInput
                                                id="totalPrice"
                                                name="totalPrice"
                                                value={newOrder.totalPrice}
                                                change={(val) => { handleChange(val); setAutoSumTotalPrice(false); }}
                                                isInvalid={!!errors.totalPrice}
                                                currency={newOrder.currencyId}
                                                step={0.01}
                                                zeroAlwaysVisible={true}
                                                additionalButtonName={t("recalculate")}
                                                additionalButtonClick={() => { recalculateTotalPrice(setFieldValue, selectedContract, selectedAdditionalCosts, distance, weight); setAutoSumTotalPrice(true); }}
                                            />

                                        </InputGroup>
                                        <div className="text-danger fs-14px mt-4px" >{errors.totalPrice}</div>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Label htmlFor="remarks">{t('remarks')}:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows={3}
                                            maxLength={250}
                                            id="remarks"
                                            name="remarks"
                                            value={newOrder.remarks}
                                            onChange={handleChange}
                                        />
                                        <div className="text-danger fs-14px mt-4px" >{errors.remarks}</div>
                                    </Col>
                                    <Col xs={12} >
                                        <UploadFiles sessionId={sessionId} setFieldValue={setFieldValue} uploadedfileNames={newOrder.uploadedFiles} />
                                    </Col>
                                    <Col xs={12} className="text-center">
                                        <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={() => setValidateOnChange(true)}>
                                            {isSubmitting ? <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-1"
                                            /> : null}
                                            {isSubmitting ? (t("saving")) : t("save")}
                                        </Button>
                                        <Button variant="secondary" className="mx-1" type="reset" onClick={() => {
                                            resetForm({ values: initialnewOrderData });
                                        }}>
                                            {t("clear")}
                                        </Button>
                                    </Col>
                                    <Col>
                                        {apiErrors &&
                                            <ValidationErrorList errorList={apiErrors} />}
                                    </Col>
                                </Row>
                                <SelectCarrierModal
                                    show={showSelectCarrierModal}
                                    setShow={(val) => setShowSelectCarrierModal(val)}
                                    setValue={setFieldValue}
                                    selectCarrier={(carrier) => handleCarrierSelect(carrier, setFieldValue, setFieldTouched)}
                                    selectedCarrierId={newOrder.carrierId}
                                    limitedToContainsContracts={true}
                                    blockWithoutContactUsers={true}
                                />
                                <SelectSupplierModal
                                    show={showSelectSupplierModal}
                                    setShow={(val) => setShowSelectSupplierModal(val)}
                                    setValue={setFieldValue}
                                    customModalTitlePhrase="selectPointFromList"
                                    supplierSelectFunction={(duns) => handleSupplierSelectFromList(duns, setFieldValue, setFieldTouched)}
                                />
                                <Row className='fixed-row-bottom summaryPanel shadow p-1 p-sm-2 p-md-3 mx-0 rounded'>
                                    <PriceSummaryPanel newOrder={newOrder} contract={selectedContract} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                    <StaticBackDropInfoModal
                        infoText={insertedOrderNo && `${t("newOrderNo")}: ${insertedOrderNo}`}
                        show={insertedOrderNo !== undefined}
                        onClose={() => setInsertedOrderNo(undefined)}
                        copyText={insertedOrderNo}
                    />
                </Col>
            </Row>
        </Container>
    )
}



