import React, { useState } from 'react';
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import agent from '../../app/api/agent';
import { OrderActiveDetails } from '../../app/models/orderActiveDetails';
import storage from '../../app/store/storage';
import AdditionalCostsList from '../common/AdditionalCostsList';
import ApprovalsList from '../common/ApprovalsList';
import { AttachmentsList } from '../common/AttachmentsList';
import { getContractName, getModeName, getTransportTypeName } from '../common/functions/langObjectTranslation';
import LoadingDataComponent from '../common/LoadingDataComponent';
import PackageList from '../common/PackageList';
import PartsList from '../common/PartsList';

interface Props {
    show: boolean,
    orderId: number,
    setShow: (show: boolean) => void;
}

export default function ActiveOrderDetailsModal({ show, orderId, setShow }: Props) {
    const { t } = useTranslation();
    const [orderDetails, setOrderDetails] = useState<OrderActiveDetails | null>(null);
    const [isDownloading, setIsDownloading] = useState(true);
    const lang = storage.Lang.get();

    const handleClose = () => setShow(false);

    function getTablesData() {
        setIsDownloading(true);
        agent.Orders.activeDetails(orderId)
            .then(reponse => {
                setOrderDetails(reponse);
            })
            .then(() => setIsDownloading(false));

    }

    return (
        <Modal show={show} onHide={handleClose} onShow={getTablesData} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <InfoCircle className='me-1 pb-1' height={30} width={30} />
                    {t("orderDetails")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-font-responsive">
                {isDownloading ?
                    <Row>
                        <Col>
                            <LoadingDataComponent height="240px" />
                        </Col>
                    </Row>
                    :
                    <>
                        {orderDetails === null ?
                            <>
                                
                            </> :
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <strong>{t("price")}: </strong><span>{orderDetails.totalPrice} {orderDetails.currencySymbol}</span>
                                    </Col>
                                    <Col xs={12}>
                                        <strong>{t("contract")}: </strong><span>{getContractName(orderDetails.contractTypeId, orderDetails.contractName, orderDetails.contractPrice, orderDetails.currencySymbol)}</span>
                                    </Col>
                                    <Col xs={12}>
                                        <strong>{t("transportType")}: </strong><span>{getTransportTypeName(orderDetails.transportType, lang)}</span>
                                    </Col>
                                    <Col xs={12}>
                                        <strong>{t("mode")}: </strong><span>{getModeName(orderDetails.mode, lang)} kg</span>
                                    </Col>
                                    <Col xs={12}>
                                        <strong>{t("remarks")}: </strong>{orderDetails.remarks ? orderDetails.remarks : '---'}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-0 px-sm-2 px-md-3 modal-font-responsive mt-3">
                                        {orderDetails.approvals.length > 0 ?
                                            <>
                                                <p className="list-title">{t("approvals")}:</p>
                                                <ApprovalsList approvals={orderDetails.approvals} />
                                            </> : null
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-0 px-sm-2 px-md-3 modal-font-responsive mt-3">
                                        {orderDetails.additionalCosts.length === 0 ?
                                            <Alert variant="secondary" className="text-center">
                                                <InfoCircle height={24} width={24} />
                                                <br />
                                                {t("noAdditionalCostsInOrder")}
                                            </Alert>
                                            :
                                            <>
                                                <p className="list-title">{t("additionalCostsList")}:</p>
                                                <AdditionalCostsList additionalCosts={orderDetails.additionalCosts} currencySymbol={orderDetails.currencySymbol} />
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-0 px-sm-2 px-md-3 modal-font-responsive mt-2">
                                        {orderDetails?.parts.length === 0 ?
                                            <Alert variant="secondary" className="text-center">
                                                <InfoCircle height={24} width={24} />
                                                <br />
                                                {t("noPartsInOrder")}
                                            </Alert>
                                            :
                                            <>
                                                <p className="list-title">{t("partsList")}:</p>
                                                <PartsList parts={orderDetails.parts} />
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1 px-sm-2 px-md-3 modal-font-responsive mt-2">
                                        {orderDetails.packages.length === 0 ?
                                            <Alert variant="secondary" className="text-center">
                                                <InfoCircle height={24} width={24} />
                                                <br />
                                                {t("noPackagesInOrder")}
                                            </Alert>
                                            :
                                            <>
                                                <p className="list-title">{t("packagesList")}:</p>
                                                <PackageList packages={orderDetails.packages} />
                                            </>
                                        }
                                    </Col>
                                </Row>
                                {orderDetails.uploads.length > 0 ?
                                    <Row className="mb-3">
                                        <Col className="px-0 px-sm-2 px-md-3">
                                            <p className="list-title">{t("attachments")}:</p>
                                            <AttachmentsList attachments={orderDetails.uploads} />
                                        </Col>
                                    </Row>
                                    : null}
                            </Container>
                        }
                    </>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}