import { makeAutoObservable } from "mobx";
import moment from "moment";
import agent from "../api/agent";
import { downloadFile } from "../api/downloadFile";
import { AuctionDetailsForHistory } from "../models/auctionDetailsForHistory";
import { AuctionHistoryListItemDto } from "../models/auctionHistoryListItemDto";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsHistoryTable } from "../models/queryParamsHistoryTable";

export default class HistoryAuctionStore {
    constructor() {
        makeAutoObservable(this);
    }
    loadingData = true;
    historyAuctions: AuctionHistoryListItemDto[] = [];
    pagination: PaginationModel | null = null
    attachmentsModalAuctionID: number | null = null;

    selectedAuctionId: number | null = null;
    auctionDetails: AuctionDetailsForHistory | null = null;
    generatingFile: Boolean = false;
    loadingDetails: Boolean = false;

    pagingParams: QueryParamsHistoryTable = new QueryParamsHistoryTable(1, 10, "CanceledFinishedDateTime", "DESC");

    loadHistoryAuctions = () => {
        try {
            return agent.Auctions.history(this.getAxiosParams())
                .then(response => {
                    this.setHistoryAuctions(response.data);
                    this.setPagination(response.pagination);
                })
                .then(() => {
                    this.setLoadingData(false);
                })
        } catch (e) {
            console.log(e);
        }
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }

        if (this.pagingParams.searchPhrase !== undefined) {
            params.append('searchPhrase', this.pagingParams?.searchPhrase.toString());
        }

        if (this.pagingParams.searchIn !== undefined) {
            params.append('searchIn', this.pagingParams?.searchIn.toString());
        }

        if (this.pagingParams.status !== undefined) {
            params.append('status', this.pagingParams?.status.toString());
        }

        if (this.pagingParams.dateField !== undefined) {
            params.append('dateField', this.pagingParams?.dateField.toString());
        }

        if (this.pagingParams.dateFrom !== undefined) {
            if (moment(this.pagingParams?.dateFrom, moment.ISO_8601, true).isValid() === false) {
                params.append('dateFrom', this.pagingParams?.dateFrom.toString());
            }
            else {
                params.append('dateFrom', new Date(this.pagingParams?.dateFrom).toISOString());
            }
        }

        if (this.pagingParams.dateTo !== undefined) {
            if (moment(this.pagingParams?.dateTo, moment.ISO_8601, true).isValid() === false) {
                params.append('dateTo', this.pagingParams?.dateTo.toString());
            }
            else {
                params.append('dateTo', new Date(this.pagingParams?.dateTo).toISOString());
            }
        }
        return params;
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsHistoryTable(this.pagingParams.pageNumber, this.pagingParams.pageSize, fieldName, sortDirection, this.pagingParams.searchPhrase, this.pagingParams.searchIn, this.pagingParams.dateFrom, this.pagingParams.dateTo, this.pagingParams.dateField, this.pagingParams.status)
        );
        this.loadHistoryAuctions();
    }

    loadAuctionDetails = (id: number | null) => {
        if (id !== null) {
            this.setLoadingDetails(true);

            agent.Auctions.historyDetails(id)
                .then((response) => {
                    this.setAuctionDetails(response);
                })
                .catch(() => this.setSelectedAuctionId(null))
                .finally(() => this.setLoadingDetails(false))
        }
    }

    getXLSReportParams = () => {
        const params = new URLSearchParams();

        if (this.pagingParams.searchPhrase !== undefined) {
            params.append('searchPhrase', this.pagingParams?.searchPhrase.toString());
        }

        if (this.pagingParams.searchIn !== undefined) {
            params.append('searchIn', this.pagingParams?.searchIn.toString());
        }

        if (this.pagingParams.status !== undefined) {
            params.append('status', this.pagingParams?.status.toString());
        }

        if (this.pagingParams.dateField !== undefined) {
            params.append('dateField', this.pagingParams?.dateField.toString());
        }

        if (this.pagingParams.dateFrom !== undefined) {
            if (moment(this.pagingParams?.dateFrom, moment.ISO_8601, true).isValid() === false) {
                params.append('dateFrom', this.pagingParams?.dateFrom.toString());
            }
            else {
                params.append('dateFrom', new Date(this.pagingParams?.dateFrom).toISOString());
            }
        }

        if (this.pagingParams.dateTo !== undefined) {
            if (moment(this.pagingParams?.dateTo, moment.ISO_8601, true).isValid() === false) {
                params.append('dateTo', this.pagingParams?.dateTo.toString());
            }
            else {
                params.append('dateTo', new Date(this.pagingParams?.dateTo).toISOString());
            }
        }

        return params;
    }


    getXLSHistoryReport = (lang: string) => {
        this.setGeneratingFile(true);
        agent.Documents.auctionHistoryXLS(this.getXLSReportParams(), lang)
            .then((response) => downloadFile(response, 'report.xls'))
            .then(() => this.setGeneratingFile(false))
    }

    getXLSMailingReport = (lang: string) => {
        this.setGeneratingFile(true);
        agent.Documents.auctionMailingReportXLS(this.auctionDetails?.auctionId!, lang)
            .then((response) => downloadFile(response, 'mailingHistory.xls'))
            .then(() => this.setGeneratingFile(false))
    }

    setAttachmentsModalAuctionId = (auctionId: number | null) => {
        this.attachmentsModalAuctionID = auctionId;
    }

    setAuctionDetails = (auction: AuctionDetailsForHistory | null) => {
        this.auctionDetails = auction;
    }

    setSelectedAuctionId = (auction: number | null) => {
        this.selectedAuctionId = auction;
    }

    setHistoryAuctions = (historyAuctions: AuctionHistoryListItemDto[]) => {
        this.historyAuctions = historyAuctions;
    }

    setPagination = (pagination: PaginationModel) => {
        this.pagination = pagination;
    }

    setLoadingData = (loadinData: boolean) => {
        this.loadingData = loadinData;
    }

    setPagingParams = (params: QueryParamsHistoryTable) => {
        this.pagingParams = params;
    }

    changePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsHistoryTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection, this.pagingParams.searchPhrase, this.pagingParams.searchPhrase, this.pagingParams.dateFrom, this.pagingParams.dateTo, this.pagingParams.dateField, this.pagingParams.status))
        this.loadHistoryAuctions();
    }

    setGeneratingFile = (val: Boolean) => {
        this.generatingFile = val;
    }

    setLoadingDetails = (val: Boolean) => {
        this.loadingDetails = val;
    }
}
