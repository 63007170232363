export class QueryParamsTable {
    pageNumber;
    pageSize;
    sortBy?: string | undefined;
    sortDirection?: string | undefined;

    constructor(pageNumber = 1, pageSize = 10, sortBy: string | undefined = undefined, sortDirection: string | undefined = undefined) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.sortBy = sortBy;
        this.sortDirection = sortDirection;
    }
}