import moment from "moment";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { WinnerPrice } from "../../app/models/winnerPriceDto";
import { getCurrencySymbol } from "../common/functions/langObjectTranslation";

interface Props {
    price: WinnerPrice,
    currency: string
}

export default function PriceList({ price, currency }: Props) {
    const { t } = useTranslation();

    let columns: string[] = [
        "price",
        "companyName",
        "realPickupDateTime",
        "maxLoadingDateTime",
        "remarks"
    ]

    return (
        <Table responsive hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="table-light" >
                <tr>
                    {columns.map((headerPhrase, index) => (
                        <th key={index}>{t(headerPhrase)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                    <tr>
                    <td>{price.price} {getCurrencySymbol(currency)}</td>
                        <td>{price.userCompany}</td>
                        <td>{moment(new Date(price.realPickUpDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(new Date(price.maxLoadingDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{price.remarks}</td>
                    </tr>
            </tbody>
        </Table>
    )
}