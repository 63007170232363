import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import agent from '../../../app/api/agent';
import { AuctionDetailsForAddPrice } from '../../../app/models/auctionDetailsForAddPrice';
import i18n from '../../../i18Next';
import { getModeName, getSupplierCountryName } from '../../common/functions/langObjectTranslation';
import LoadingDataComponent from '../../common/LoadingDataComponent';
import PackageList from '../../common/PackageList';
import PartsList from '../../common/PartsList';
import AddPriceForm from './AddPriceForm';

export default function APage() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const { t } = useTranslation();
    const [model, setModel] = useState<AuctionDetailsForAddPrice | null>(null);
    const [loadingData, setLoading] = useState(true);
    const [lang, setLang] = useState("en");
    const qs = query.get('qs')?.toString()

    const loadData = () => {
            agent.ManageDirectlyFromEmail.auctionDetailsForAddPrice(qs!)
                .then((response) => {
                    setModel(response);
                    i18n.changeLanguage(response.langId.toLowerCase());
                    setLang(response.langId.toLowerCase())
                })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [])

    const labelColumnClass = "text-end fw-bolder px-1 px-md-2";
    const valueColumnClass = "text-start align-self-center px-1 px-md-2";
    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3 mt-2'>
                {loadingData ?
                    <Col xs={12}>
                        <LoadingDataComponent height="300px" />
                    </Col>
                    :
                    <Col xs={12} xl={11} xxl={10}>
                        <div className="shadow rounded h-100 bg-white">
                            {model ?
                                <Card className="text-center">
                                    <Card.Header className="fw-bold">
                                        <span
                                            className={model.left2End < 0 || model.priceFromUserCompanyAlreadyExists ? "text-success" : undefined }>
                                            {model.left2End < 0 ?
                                                `${t("auctionFinished")}`
                                                : model.priceFromUserCompanyAlreadyExists ?
                                                    t("priceAlreadyGiven")
                                                    : t("addPrice")}
                                            {}
                                        </span>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} lg={6} >
                                                <Row>
                                                    <Col xs={6} className={labelColumnClass} >
                                                        {t("auctionId")}:
                                                    </Col>
                                                    <Col xs={6} className={valueColumnClass} >
                                                        {model.auctionId}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {model.mode &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("mode")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {getModeName(model.mode, lang)}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {model.followUpName &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("followupname")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.followUpName}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            {model.weight &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("weight")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.weight} kg
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {model.supplierDuns &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("supplierduns")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.supplierDuns}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            {model.supplierName &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("suppliername")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.supplierName}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {model.supplierAddress &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("street")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.supplierAddress}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            {model.supplierZipCode &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("zipcode")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.supplierZipCode}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {model.supplierCity &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("city")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.supplierCity}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            {model.supplierCountry &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("country")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {getSupplierCountryName(model.supplierCountry, lang)}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <Row>
                                                    <Col xs={6} className={labelColumnClass} >
                                                        {t("pickupdatetime")}:
                                                    </Col>
                                                    <Col xs={6} className={valueColumnClass} >
                                                        {moment(new Date(model.pickUpDateTime!)).format("DD.MM.YYYY HH:mm")}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Row>
                                                    <Col xs={6} className={labelColumnClass} >
                                                        {t("deliverydatetime")}:
                                                    </Col>
                                                    <Col xs={6} className={valueColumnClass} >
                                                        {moment(new Date(model.deliveryDateTime!)).format("DD.MM.YYYY HH:mm")}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {model.unloadingDock &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("unloadingdock")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.unloadingDock}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {model.remarks &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className={labelColumnClass} >
                                                            {t("remarks")}:
                                                        </Col>
                                                        <Col xs={6} className={valueColumnClass} >
                                                            {model.remarks}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        {model.parts.length > 0 ?
                                            <Row className="mt-2">
                                                <Col xs={12}>
                                                    <Row>
                                                        <Col xs={12} md={3} className="text-center text-md-end fw-bolder">
                                                            {t("partsList")}:
                                                        </Col>
                                                        <Col xs={12} md={9} className="text-start px-0">
                                                            <PartsList parts={model.parts} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            : null}
                                        {model.packages.length > 0 ?
                                            <Row className="mt-2">
                                                <Col xs={12}>
                                                    <Row>
                                                        <Col xs={12} md={3} className="text-center text-md-end fw-bolder">
                                                            {t("packagesList")}:
                                                        </Col>
                                                        <Col xs={12} md={9} className="text-start px-0">
                                                            <PackageList packages={model.packages} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            : null}
                                        <Row className="mt-2 mt-xl-4 justify-content-md-center">
                                            <Col md={11} lg={10} xl={9} xxl={6} className="text-center">
                                                <AddPriceForm currencySymbol={model.currencySymbol!} loadData={loadData} isAuctionBlocked={model.left2End < 0 || model.priceFromUserCompanyAlreadyExists} modeId={model.mode.modeId} />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 text-center">
                                            <Col xs={12} className="mt-2 fw-bold text-success">
                                                {model.priceFromUserCompanyAlreadyExists ?
                                                    t("priceAlreadyGiven")
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                : null}
                        </div>
                    </Col>
                }
            </Row>
        </Container>
    )

}