import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Price } from '../../app/models/price';
import { getCurrencySymbol } from './functions/langObjectTranslation';


interface Props {
    prices: Price[],
    currency: string,
    datetimeColumnVisible? : boolean 
}

export default function PriceList({ prices, currency, datetimeColumnVisible = true }: Props) {
    const { t } = useTranslation();

    let columns: string[] = [
        "price",
        "companyName",
        "username",
        "realPickupDateTime",
        "maxLoadingDateTime",
        "remarks",
        "dateTime"
    ]

    if (!datetimeColumnVisible) {
        columns = columns.filter(col => col !== "dateTime");
    }

    return (
        <Table responsive hover size="sm" className="table-content-centered unbreakable-cell-text">
                <thead className="table-light" >
                    <tr>
                        {columns.map((headerPhrase, index) => (
                            <th key={index}>{t(headerPhrase)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {prices.map((price, index) => (
                        <tr key={index} className={price.winner ? "text-success fw-bold" : undefined}>
                            <td>{price.price} {getCurrencySymbol(currency)}</td>
                            <td>{price.userCompany}</td>
                            <td>{price.userName}</td>
                            <td>{moment(new Date(price.realPickUpDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                            <td>{moment(new Date(price.maxLoadingDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                            <td>{price.remarks}</td>
                            {datetimeColumnVisible ? <td>{moment(new Date(price.priceDateTime)).format("DD.MM.YYYY HH:mm")}</td> : undefined}
                        </tr>
                    ))}
                </tbody>
            </Table>
    )
}