import React, { ChangeEvent } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { getCurrencySymbol } from './functions/langObjectTranslation';

interface Props {
    currency?: string | undefined,
    size?: "sm" | "lg" | undefined,
    step?: number,
    value?: any,
    change: (e: ChangeEvent<HTMLInputElement>) => void,
    id: string,
    name: string,
    zeroAlwaysVisible?: boolean,
    min?: number,
    isInvalid?: boolean,
    className?: string | undefined
    disabled?: boolean,
    decimalScale?: number,
    additionalButtonName?: string | undefined
    additionalButtonClick?: () => void;
    customCurrencyText?: string | undefined
}

export default function PriceInput({ currency = undefined, size = undefined, step = 1, value, change, id, name, min = 0, isInvalid = false, zeroAlwaysVisible = false, className = undefined, disabled = false, additionalButtonName = undefined, additionalButtonClick, customCurrencyText = undefined }: Props) {

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(",", ".");

        if (parseInt(e.target.value) <= 2147483647 ||
            e.target.value === '' ||
            e.target.value === '0') {

            if (e.target.value === '') {

                e.target.value = "0"
            }
            change(e);
        }
    };


    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber

        if (value) {
            e.target.value = value.toFixed(2).toString()
            change(e);
        }
    }

    return (
        <InputGroup size={size}>
            <Form.Control
                id={id}
                name={name}
                type="number"
                min={min}
                step={step}
                value={zeroAlwaysVisible ? value || '' || 0 : value || ''}
                isInvalid={isInvalid}
                onMouseUp={(e: any) => { (e.target.value < 0) && e.preventDefault(); }}
                onChange={handleChange}
                className={className}
                disabled={disabled}
                onBlur={handleOnBlur}
            />
            {customCurrencyText ?
                <InputGroup.Text>{customCurrencyText}</InputGroup.Text>
                :
                <InputGroup.Text>{currency && getCurrencySymbol(currency)}</InputGroup.Text>
            }
            {additionalButtonName !== undefined && additionalButtonClick !== undefined ? <Button variant="outline-secondary" id="recalculateTotalPrice" onClick={() => additionalButtonClick()}>
                {additionalButtonName}
            </Button> : null}
        </InputGroup>

    )
}


