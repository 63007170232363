import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { CheckLg, ExclamationTriangleFill, Trash3Fill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { UploadFileResult } from "../models/uploadFileResult";
import agent from "./agent";

interface Props {
    sessionId: string,
    setFieldValue: (fieldName: string, val: any) => void;
    uploadedfileNames: string[];
}

const UploadFiles = ({ sessionId, setFieldValue, uploadedfileNames }: Props) => {
    const { t } = useTranslation();

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [uploadingResults, setUploadingResults] = useState<UploadFileResult[]>([]);

    const selectFile = (event: any) => {
        setSelectedFiles(event.target.files);
    };

    useEffect(() => {
        let uploadedFileNames: string[] = [];

        uploadingResults.forEach(item => {
            if (item.correctlyUploaded) {
                uploadedFileNames.push(item.fileID);
            }
        })

        setFieldValue("uploadedFiles", uploadedFileNames);
    }, [uploadingResults, setFieldValue])

    useEffect(() => {
        if (uploadedfileNames.length === 0) {
            setUploadingResults([]);
        }
    }, [sessionId, uploadedfileNames.length])

    const upload = () => {
        if (selectedFiles.length > 0) {

            setProgress(0);

            agent.File.upload(selectedFiles, sessionId, (event: any) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            })
                .then((response) => {
                    setUploadingResults(response.data); 
                    clearFileInput();
                })
                .catch(() => {
                    setProgress(0);
                    toast.error(t("uploadingFileError"))
                    clearFileInput();
                });

            setSelectedFiles([]);
        }

    };



    const handleDeleteAttachment = (id: string) => {

        agent.File.delete(id)
            .then(() => toast.success(t("attachmentRemoved")));

        var filteredUploadedFiles = uploadingResults.filter(f => f.fileID !== id);
        setUploadingResults(filteredUploadedFiles);
    }

    const handleClearUploads = () => {
        agent.File.deleteBySessionId(sessionId)
            .then(() => {
                setUploadingResults([]);
            });
        clearFileInput();
    }

    const clearFileInput = () => {
        const fileUploadControl: any =
            document.getElementById("fileUpload");
        fileUploadControl!.value! = '';
    }

    return (
        <div>
            <Row>
                <Form.Label>{t("addAttachments")}:</Form.Label>
                <Col xs={12} md="auto">
                    <Form.Group controlId="fileUpload" className="mb-3">
                        <Form.Control type="file" multiple onChange={selectFile} />
                    </Form.Group>
                </Col>
                <Col xs={12} md="auto" className="text-center text-md-start">
                    <Button
                        className="btn btn-success"
                        disabled={!selectedFiles}
                        onClick={upload}
                    >
                        {t("upload")}
                    </Button>
                    <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => handleClearUploads()}
                    >
                        {t("clear")}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {uploadingResults.length > 0 && progress !== 100 ? (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                aria-valuenow={progress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                style={{ width: progress + "%" }}
                            >
                                {progress}%
                            </div>
                        </div>
                    ) : undefined}
                </Col>
            </Row>
            {uploadingResults.length > 0 &&
                <Row className="mt-3">
                    <Col xs={12}>
                        <div className="card">
                            <div className="card-header">List of Files</div>
                            <ul className="list-group list-group-flush">
                                {uploadingResults.map((uploadedFile: UploadFileResult, index: number) => (
                                    <li className="list-group-item" key={index}>
                                        {uploadedFile.correctlyUploaded ?
                                            <CheckLg className="text-success me-1" height={22} width={22} />
                                            :
                                            <ExclamationTriangleFill className="text-danger me-1" height={24} width={24} />}
                                        <span className={uploadedFile.correctlyUploaded ? "text-decoration-none" : "text-decoration-line-through text-danger"}>{uploadedFile.fileName} ({uploadedFile.fileSize})</span>
                                        {uploadedFile.correctlyUploaded &&
                                            <span
                                                className="ms-1 fw-bold text-danger cursor-pointer"
                                                onClick={() => handleDeleteAttachment(uploadedFile.fileID)}
                                            >
                                                <Trash3Fill height={20} width={20} />
                                            </span>}
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    );
};

export default UploadFiles;