import { Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import IntegerInput from '../common/IntegerInput'
import DatePicker, { registerLocale } from 'react-datepicker';
import { pl, enGB, de } from "date-fns/locale";
import { useStore } from '../../app/store/store'
import { BeginAgainFinishedAuction } from '../../app/models/beginAgainFinishedAuction'
import { toast } from 'react-toastify'
import BeginAgainFinishedAuctionValidator from '../../app/models/validators/beginAgainFinishedAuctionValidator';
import { fixTimezoneForOnChangeCalendarEvent, fixTimezoneForSelectedCalendarDate } from '../common/functions/dateTimeManipulation'
import storage from '../../app/store/storage'


export default function BeginAgainForm() {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { finishedAuctionsStore } = useStore();
    const { t } = useTranslation();

    const initData: BeginAgainFinishedAuction = {
        duration: finishedAuctionsStore.selectedAuction?.duration!,
        remarks: finishedAuctionsStore.selectedAuction?.remarks!,
        pickUpDateTime: new Date(finishedAuctionsStore.selectedAuction?.pickUpDateTime!),
        deliveryDateTime: new Date(finishedAuctionsStore.selectedAuction?.deliveryDateTime!)
    }


    function handleSubmit(val: BeginAgainFinishedAuction, actions: FormikHelpers<BeginAgainFinishedAuction>) {
        finishedAuctionsStore.beginAgainAuction(val)
            .then(() => toast.success(t("auctionBeginAgainSuccessfully")))
            .catch(() => toast.error(t("auctionBeginAgainUnuccessfully")))
            .finally(() => actions.setSubmitting(false))
    }

    const clearForm = (actions: FormikHelpers<BeginAgainFinishedAuction>) => {
        setValidateOnChange(false);
    }

    registerLocale("pl", pl);
    registerLocale("en", enGB);
    registerLocale("de", de);
    const lang = storage.Lang.get();

    return (
        <>
            <Row>
                <Col xs={12} className="text-center">
                    <p className="my-4 fs-5 fw-bold">{t("askConfirmBeginAgain")}</p>
                </Col>
            </Row>
            <Formik
                enableReinitialize
                validateOnChange={validateOnChange}
                validateOnBlur={true}
                validate={formValues => new BeginAgainFinishedAuctionValidator().validate(formValues)}
                initialValues={initData}
                onSubmit={(val, actions) => handleSubmit(val, actions)}
                onReset={(val, actions) => clearForm(actions)}>
                {({ values: finishedAuction, handleChange, handleSubmit, setFieldValue, errors, isSubmitting, setSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={6} lg={12} xl={6} >
                                <Form.Label htmlFor="duration" className="required">{t('duration')} [min]:</Form.Label>
                                <IntegerInput
                                    id="duration"
                                    name="duration"
                                    value={finishedAuction.duration}
                                    change={handleChange}
                                    isInvalid={!!errors.duration}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.duration}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm={6} lg={12} xl={6} >
                                <Form.Label htmlFor="pickUpDateTime" className="required">{t('pickupdatetime')}:</Form.Label>
                                <DatePicker
                                    name="pickUpDateTime"
                                    className={!!errors.pickUpDateTime ? "form-control is-invalid" : "form-control"}
                                    selected={fixTimezoneForSelectedCalendarDate(finishedAuction.pickUpDateTime)}
                                    onChange={(date: any) => setFieldValue('pickUpDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                    todayButton={t('today')}
                                    locale={lang}
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    placeholderText="__.__.____ __:__"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    autoComplete="off"
                                />
                                <div className="text-danger fs-14px mt-4px" >{errors.pickUpDateTime}</div>
                            </Col>
                            <Col sm={6} lg={12} xl={6} >
                                <Form.Label htmlFor="deliveryDatetime" className="required">{t('deliverydatetime')}:</Form.Label>
                                <DatePicker
                                    name="deliveryDateTime"
                                    className={!!errors.deliveryDateTime ? "form-control is-invalid" : "form-control"}
                                    selected={fixTimezoneForSelectedCalendarDate(finishedAuction.deliveryDateTime)}
                                    onChange={(date: any) => setFieldValue('deliveryDateTime', fixTimezoneForOnChangeCalendarEvent(date))}
                                    todayButton={t('today')}
                                    locale={lang}
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    placeholderText="__.__.____ __:__"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    minDate={finishedAuction.pickUpDateTime}
                                    autoComplete="off"
                                />
                                <div className="text-danger fs-14px mt-4px" >{errors.deliveryDateTime}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Label htmlFor="remarks">{t('remarks')}:</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={2}
                                    maxLength={250}
                                    id="remarks"
                                    name="remarks"
                                    value={finishedAuction.remarks || ""}
                                    onChange={handleChange}
                                />
                                <div className="text-danger fs-14px mt-4px" >{errors.remarks}</div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12} className="text-center">
                                <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={() => { setSubmitting(true); setValidateOnChange(true) }}>
                                    {isSubmitting ? <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-1"
                                    /> : null}
                                    {isSubmitting ? (t("saving")) : t("beginAgain")}
                                </Button>
                                <Button variant="secondary" className="mx-1" onClick={() => finishedAuctionsStore.setSelectedAuction(null)} >
                                    {t("cancel")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    )

}