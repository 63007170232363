import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Approver } from '../../../app/models/approver';
import ApproverValidator from '../../../app/models/validators/approverValidator';
import { useStore } from '../../../app/store/store';
import IntegerInput from '../../common/IntegerInput';
import ValidationErrorList from '../../error/ValidationErrorList';

export default observer(function ApproverForm() {
    const { t } = useTranslation();
    const { approversStore, commonStore } = useStore();
    const { selectedApprover, setSelectedApprover,
        selectedTab, setSelectedTab,
        addApprover, updateApprover,
        currencies } = approversStore

    const [validateOnChange, setValidateOnChange] = useState(false);
    const [apiErrors, setApiErrors] = useState(null);

    const initValue: Approver = selectedTab === "addApprover" ?
        {
            amount: 0,
            currency: "EUR",
            email: "",
            name: "",
            langId: "EN",
            active: false,
        }
        :
        selectedApprover!;

    const handleSubmit = (val: Approver, actions: FormikHelpers<Approver>) => {
        if (selectedTab === "addApprover") {
            addApprover(val)
                .then(() => {
                    toast.success(t("approverAddedSuccessfully"));
                    actions.resetForm();
                })
                .catch((err) => {
                    toast.error(t("approverAddedUnuccessfully"));
                    setApiErrors(err);
                })
                .finally(() => actions.setSubmitting(false))
        }
        else {
            updateApprover(val)
                .then(() => {
                    toast.success(t("approverUpdatedSuccessfully"));
                    actions.resetForm();
                })
                .catch((err) => {
                    toast.error(t("approverUpdatedUnuccessfully"))
                    setApiErrors(err);
                })
                .finally(() => actions.setSubmitting(false));
        }
    }

    useEffect(() => {
        document.getElementById("resetApproverForm")?.click();
    }, [commonStore.selectedOwner])

    const clearForm = () => {
        setApiErrors(null);
        setValidateOnChange(false);
    }

    return (
        <Formik
            enableReinitialize
            validateOnChange={validateOnChange}
            validateOnBlur={true}
            validate={formValues => new ApproverValidator().validate(formValues)}
            initialValues={initValue}
            onSubmit={(val, actions) => handleSubmit(val, actions)}
            onReset={() => clearForm()}
        >
            {({ values: newApprover, handleChange, handleSubmit, setFieldValue, resetForm, errors, isSubmitting, setSubmitting }) => (
                <Form>
                    <Row className="mt-3">
                        <Col xs={12} md={6} lg={12} xxl={6}>
                            <Form.Label htmlFor="amount">{t('amount')}:</Form.Label>
                            <IntegerInput
                                id="amount"
                                name="amount"
                                value={newApprover.amount}
                                change={handleChange}
                                isInvalid={!!errors.amount}
                                zeroAlwaysVisible={true}
                            />
                            <Form.Control.Feedback type='invalid'>{errors.amount}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                            <Form.Label htmlFor="currency" className="required">{t('currency')}:</Form.Label>
                            <Form.Select
                                id="currency"
                                name="currency"
                                value={newApprover.currency}
                                onChange={handleChange}
                                isInvalid={!!errors.currency}
                            >
                                {currencies.map((c, index) => (
                                    <option key={index} value={c.currencyId}>{c.currencySymbol}</option>))}
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>{errors.currency}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={6} lg={12} xxl={6}>
                            <Form.Label htmlFor="email">{t('email')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                value={newApprover.email}
                                onChange={handleChange}
                                maxLength={100}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.email}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                            <Form.Label htmlFor="name">{t('name')}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                value={newApprover.name}
                                onChange={handleChange}
                                maxLength={50}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type='invalid' >{errors.name}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mt-3 align-items-end">
                        <Col xs={12} md={6} lg={12} xxl={6}>
                            <Form.Label htmlFor="langId">{t('language')}:</Form.Label>
                            <Form.Select
                                id="langId"
                                name="langId"
                                value={newApprover.langId}
                                onChange={handleChange}
                                isInvalid={!!errors.langId}
                            >
                                <option value="EN">{t("EN")}</option>
                                <option value="DE">{t("DE")}</option>
                                <option value="PL">{t("PL")}</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid' >{errors.langId}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                            <Form.Check
                                type="checkbox"
                                id="active"
                                name="active"
                                label={t("send")}
                                className="mb-2"
                                onChange={() => setFieldValue("active", !newApprover.active)}
                                checked={newApprover.active}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12} className="text-center">
                            <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={(e: any) => { e.preventDefault(); setSubmitting(true); setValidateOnChange(true); handleSubmit(); }}>
                                {isSubmitting ? <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                /> : null}
                                {isSubmitting ? (t("saving")) : t("save")}
                            </Button>
                            {selectedTab === "addApprover" ?
                                <Button variant="secondary" id="resetApproverForm" className="mx-1" type="reset" onClick={() => {
                                    resetForm({ values: initValue });
                                }} >
                                    {t("clear")}
                                </Button>
                                :
                                <Button variant="secondary" className="mx-1" onClick={() => {
                                    setSelectedTab("addApprover");
                                    setSelectedApprover(null);
                                }} >
                                    {t("cancel")}
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center">
                            {apiErrors !== null ?
                                <ValidationErrorList errorList={apiErrors} /> : null}
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
})