import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { ContractCreateUpdateDto } from "../contractCreateUpdateDto";

class ContractCreateUpdateValidator extends Validator<ContractCreateUpdateDto>
{
    constructor() {
        super();

        this.ruleFor('companyId')
            .notNull().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('name')
            .notEmpty().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('contractTypeId')
            .notNull().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('currencyId')
            .notEmpty().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('price')
            .notNull().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('price')
            .greaterThan(0).withMessage(i18next.t("valValueGreatherThan").replace("#value#", "0"))

    }
}

export default ContractCreateUpdateValidator;