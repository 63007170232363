import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Part } from '../../app/models/part';

interface Props {
    parts: Part[]
}

export default function PartsList({parts} : Props) {
    const { t } = useTranslation();

    return (
        <Table responsive hover size="sm" className="table-content-centered">
            <thead className="table-light" >
                <tr>
                    <th>{t("partno")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("name")}</th>
                    <th>{t("unloadingdock")}</th>
                </tr>
            </thead>
            <tbody>
                {parts.map((part) => (
                    <tr key={part.partId}>
                        <td>{part.partNo}</td>
                        <td>{part.partQty}</td>
                        <td>{part.partName}</td>
                        <td>{part.unloadingDock}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}