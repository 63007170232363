import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import agent from '../../../app/api/agent';
import { Setting } from '../../../app/models/setting';
import { useStore } from '../../../app/store/store';
import ValidationErrorList from '../../error/ValidationErrorList';

export default observer(function EmailFooterPage() {
    const { t } = useTranslation();
    const { commonStore } = useStore();

    const [submitting, setSubmitting] = useState(false);
    const [setting, setSetting] = useState<Setting | null>(null);
    const [apiErrors, setApiErrors] = useState(null)

    const loadFooter = () => {
        agent.Settings.getOwnerFooter()
            .then((response) => setSetting(response.data))
    .catch(() => toast.error(t("emailfooterLoadedUnuccessfully")));
    }

const updateFooter = () => {
    agent.Settings.updateOwnerFooter(setting!)
        .then(() => {
            toast.success(t("emailfooterUpdatedSuccessfully"));
            setApiErrors(null);
        })
        .catch((err) => {
            toast.error(t("emailfooterUpdatedUnuccessfully"));
            setApiErrors(err);
        })
        .finally(() => setSubmitting(false));
    }

    const handleClearFormClick = () => {
        loadFooter();
        setApiErrors(null);
    }

useEffect(() => {
    loadFooter();
    // eslint-disable-next-line
}, [commonStore.selectedOwner])

return (
    <Container fluid className='mt-3'>
        <Row className='justify-content-center g-3'>
            <Col xs={12} md={10} lg={8} xl={7} xxl={5}>
                <div className="shadow p-3 rounded bg-white">
                    <Form>
                        <Row>
                            <Col xs={12} sm className="text-center">
                                <p className="fs-5 fw-bold">{t("emailfooter")}:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Control
                                type="text"
                                as="textarea"
                                rows={12}
                                maxLength={4000}
                                value={setting?.emailFooter ? setting?.emailFooter : ""}
                                onChange={(e) => setSetting({ ...setting, "emailFooter": e.target.value })}
                            >
                            </Form.Control>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} sm className="text-center">
                                <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={(e) => { e.preventDefault(); setSubmitting(true); updateFooter(); }}>
                                    {submitting ? <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-1"
                                    /> : null}
                                    {submitting ? (t("saving")) : t("save")}
                                </Button>
                                <Button variant="secondary" className="mx-1" onClick={() => handleClearFormClick()} >
                                    {t("clear")}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm className="text-center">
                                {apiErrors !== null ?
                                    <ValidationErrorList errorList={apiErrors} /> : null}
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Row>
    </Container>
)
})