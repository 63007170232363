import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../../app/store/store';


export default observer(function LogoutPage() {

    const { userStore } = useStore();

    useEffect(() => {
        userStore.logout();
        // eslint-disable-next-line
    }, [])

    return (
        <>

        </>
    )
})