import React from 'react';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, PencilSquare, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { FollowUpSettingsListItem } from '../../../app/models/followUpSettingsListItem';
import PaginationModel from '../../../app/models/paginatedResult';
import { QueryParamsTable } from '../../../app/models/queryParamsTable';

interface Props {
    followUps: FollowUpSettingsListItem[],
    selectedFollowUp: FollowUpSettingsListItem | null,
    selectedTab: "addFollowUp" | "editFollowUp" | "deleteFollowUp"
    handleRowCommandButtonClick: (action: "addFollowUp" | "editFollowUp" | "deleteFollowUp", selectedFollowUp: FollowUpSettingsListItem | null) => void,
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsTable,
}

export default function ContractsTable({ followUps, selectedFollowUp, selectedTab, handleRowCommandButtonClick, pagination, sort, queryParams }: Props) {
    const { t } = useTranslation();

    const columns = [
        { labelPhrase: "contactName", accessor: "Name" },
        { labelPhrase: "email", accessor: "Email" },
        { labelPhrase: "phone", accessor: "MobilePhone" },
        { labelPhrase: "language", accessor: "LangId" },
        { labelPhrase: "", accessor: "" }
    ]

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            { Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>) }
        </tr>
    )

    return (
        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => 
                    (<th key={index}
                        onClick={column.accessor
                            ? () => sort(column.accessor)
                            : undefined}
                        className={queryParams.sortBy === column.accessor
                            ? "th-sorted-by"
                            : undefined}
                        style={column.accessor
                            ? { cursor: "pointer" }
                            : undefined}
                    >
                        {t(column.labelPhrase)}
                        {queryParams.sortBy === column.accessor
                            ? queryParams.sortDirection === "ASC"
                                ? <CaretUpFill className="ms-1" />
                                : <CaretDownFill className="ms-1" />
                            : null}
                    </th>))
                    }
                </tr>
            </thead>
            <tbody>
                {followUps.map(fu => (
                    <tr
                        key={fu.userId}
                        className={fu.userId === selectedFollowUp?.userId
                            ? selectedTab === "editFollowUp" ? "selected-table-row" : "selected-table-row-delete"
                            : undefined}
                    >
                        <td>{fu.name}</td>
                        <td>{fu.email}</td>
                        <td>{fu.mobilePhone}</td>
                        <td>{t(fu.langId)}</td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('editFollowUp', fu)}>
                                    <PencilSquare className='text-info' height={21} width={21} />
                                </span>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('deleteFollowUp', fu)}>
                                    <XCircleFill className='text-danger' height={21} width={21} />
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
                {emptyRows}
            </tbody>
        </Table>
    )
}

