import { QueryParamsTable } from "./queryParamsTable";

export class QueryParamsCarriersUsersTable extends QueryParamsTable {
    carrier?: number | undefined;

    constructor(
        pageNumber: number,
        pageSize: number,
        sortBy: string | undefined,
        sortDirection: string | undefined,
        carrier: number | undefined = undefined,
    ) {
        super(pageNumber, pageSize, sortBy, sortDirection);
        this.carrier = carrier;
    }
}