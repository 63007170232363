import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { NewPriceDirectlyFromEmail } from "../newPriceDirectlyFromEmail";
import { isIntegerGreatherThan, isNumericFieldEmpty } from "./validateFunctions";

class NewPriceDirectlyFromEmailValidator extends Validator<NewPriceDirectlyFromEmail> {
    constructor() {
        super();

        this.ruleFor('price')
            .must(x => isNumericFieldEmpty(x)!)
            .withMessage(i18next.t("valRequiredField"))
            .when(x => x !== undefined)
            .must(x => isIntegerGreatherThan(x, 0)!)
            .withMessage(i18next.t("valRequiredField"))

        this.ruleFor('realPickUpDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('maxLoadingDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"))
            .when(x => x.modeID === "3" || x.modeID === "D" || x.modeID === "M" );

        this.ruleFor('remarks')
            .maxLength(250).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "250"));
    }

}

export default NewPriceDirectlyFromEmailValidator;