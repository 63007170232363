import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import agent from '../../app/api/agent';
import { downloadFile } from '../../app/api/downloadFile';
import { DatePeriod } from '../../app/models/datePeriod';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { pl, enGB, de } from "date-fns/locale";
import moment from 'moment';
import DatePeriodValidator from '../../app/models/validators/datePeriodValidator';
import { fixTimezoneForOnChangeCalendarEvent, fixTimezoneForSelectedCalendarDate } from '../common/functions/dateTimeManipulation';
import storage from '../../app/store/storage';

export default function StatisticsPage() {
    const [reportType, setReportType] = useState<"auction" | "order">("auction");
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { t } = useTranslation();
    const lang = storage.Lang.get();


    const handleSubmit = (val: DatePeriod, actions: FormikHelpers<DatePeriod>) => {
        const fileName = `${t("stats")}_${moment(new Date(val.startDate!)).format("DD.MM.YYYY")}-${moment(new Date(val.endDate!)).format("DD.MM.YYYY") }.xls`;

        if (reportType === "auction") {
            agent.Documents.auctionStatisticsXLS(val, lang)
                .then((response) => downloadFile(response, fileName))
                .finally(() => actions.setSubmitting(false));
        }
        else if (reportType === "order") {
            agent.Documents.orderStatisticsXLS(val, lang)
                .then((response) => downloadFile(response, fileName))
                .finally(() => actions.setSubmitting(false));
        }
    }

    const todayDate = moment(new Date()).utcOffset(0, true).toDate();
    
    var initPeriod: DatePeriod = {
        startDate: moment(todayDate).add(-1, 'month').toDate(),
        endDate: todayDate
    }

    registerLocale("pl", pl);
    registerLocale("en", enGB);
    registerLocale("de", de);

    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3'>
                <Col xs={12} md={10} lg={8} xl={7} xxl={5}>
                    <div className="shadow p-3 rounded bg-white">
                        <Formik
                            enableReinitialize
                            validateOnChange={validateOnChange}
                            validateOnBlur={true}
                            validate={formValues => new DatePeriodValidator().validate(formValues)}
                            initialValues={initPeriod}
                            onSubmit={(val, actions) => { actions.setSubmitting(true); handleSubmit(val, actions) }}
                        >
                            {({ values: period, handleSubmit, setFieldValue, errors, setErrors, isSubmitting, setSubmitting, resetForm  }) => (
                                <Form>
                                    <Row>
                                        <Col xs={12} sm>
                                            <Form.Label htmlFor="startDate" className="required">{t('startDate')}:</Form.Label>
                                            <DatePicker
                                                name="startDate"
                                                className={!!errors.startDate ? "form-control is-invalid" : "form-control"}
                                                selected={fixTimezoneForSelectedCalendarDate(period.startDate)}
                                                onChange={(date: any) => setFieldValue('startDate', fixTimezoneForOnChangeCalendarEvent(date))}
                                                todayButton={t('today')}
                                                locale={lang}
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText="__.__.____"
                                                showTimeSelect={false}
                                                autoComplete="off"
                                            />
                                            <div className="text-danger fs-14px mt-4px" >{errors.startDate}</div>
                                        </Col>
                                        <Col xs={12} sm className="mt-3 mt-sm-0">
                                            <Form.Label htmlFor="deliveryDatetime" className="required">{t('endDate')}:</Form.Label>
                                            <DatePicker
                                                name="endDate"
                                                className={!!errors.endDate ? "form-control is-invalid" : "form-control"}
                                                selected={fixTimezoneForSelectedCalendarDate(period.endDate)}
                                                onChange={(date: any) => setFieldValue('endDate', fixTimezoneForOnChangeCalendarEvent(date))}
                                                todayButton={t('today')}
                                                locale={lang}
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText="__.__.____"
                                                showTimeSelect={false}
                                                minDate={period.startDate}
                                                autoComplete="off"
                                            />
                                            <div className="text-danger fs-14px mt-4px" >{errors.endDate}</div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} className="text-center">
                                            <Button
                                                id="generateStats"
                                                type="submit"
                                                className="m-1"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setReportType("auction");
                                                    setValidateOnChange(true);
                                                    handleSubmit()
                                                }}>
                                                {isSubmitting && reportType === "auction" ?  <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-1"
                                                /> : null}
                                                {isSubmitting && reportType === "auction" ? (t("generating")) : t("generateAuctionStatistics")}
                                            </Button>
                                            <Button
                                                id="generateOrderStats"
                                                type="submit"
                                                className="m-1"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setReportType("order");
                                                    setValidateOnChange(true);
                                                    handleSubmit()
                                                }}>
                                                {isSubmitting && reportType === "order" ? <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-1"
                                                /> : null}
                                                {isSubmitting && reportType === "order" ? (t("generating")) : t("generateOrderStatistics")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}