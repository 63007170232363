import React, { useEffect } from 'react';
import { Alert, Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { CarrierUserListItemDto } from '../../../app/models/carrierUserListItemDto';
import { useStore } from '../../../app/store/store';
import LoadingDataComponent from '../../common/LoadingDataComponent';
import TablePagination from '../../common/TablePagination';
import UsersTable from './UsersTable';
import { observer } from 'mobx-react-lite';
import DeleteUserPanel from './DeleteUserPanel';
import CarrierUserForm from './CarrierUserForm';
import SelectCarrierModal from '../../common/SelectCarrierModal';
import { CarrierListItem } from '../../../app/models/carrierListItem';
import { QueryParamsCarriersUsersTable } from '../../../app/models/queryParamsCarrierUsersTable';


export default observer(function CarriersPage() {
    const { t } = useTranslation();
    const { carrierUsersStore, commonStore } = useStore();
    const {
        users, loadUsers,
        selectedCarrier, setSelectedCarrier,
        carriersHavingUsers, loadCarriersHavingUsers,
        selectedUser, setSelectedUser,
        selectedTab, setSelectedTab,
        selectedFormCarrier, setSelectedFormCarrier,
        showSelectCarrierModal, setShowSelectCarrierModal,
        pagingParams, setPagingParams,
        handleChangePage, handleSort,
        loadingData, pagination, resetParams,
        setCarrierChangedFrom, setBlockAutoChangeFormCarrier
    } = carrierUsersStore;


    useEffect(() => {
        loadUsers();
        setSelectedTab(selectedTab);
        // eslint-disable-next-line
    }, [carrierUsersStore, commonStore.selectedOwner])

    useEffect(() => {
        resetParams();
        setSelectedCarrier(undefined);
        setSelectedUser(null);
        setSelectedTab("addUser");
        // eslint-disable-next-line
    }, [commonStore.selectedOwner])

    useEffect(() => {
        loadCarriersHavingUsers();
        // eslint-disable-next-line
    }, [])

    const handleTabSelect = (val: "addUser" | "editUser" | "deleteUser") => {
        setSelectedTab(val);
    }

    function handleRowCommandButton(tabName: "addUser" | "editUser" | "deleteUser", selectedUser: CarrierUserListItemDto | null) {
        setSelectedTab(tabName);
        setSelectedUser(selectedUser);
    }

    const isCarrierColumnsHidden = !(selectedCarrier?.id === undefined);

    const handleCarrierSelectChange = (val: string) => {
        const id: number | undefined = Number(val) ? Number(val) : undefined;
        const carrier = carriersHavingUsers.find(c => c.id === id);

        setSelectedCarrier(carrier);
        setSelectedFormCarrier(carrier);
        setCarrierChangedFrom("select");
        setPagingParams(new QueryParamsCarriersUsersTable(1, 10, pagingParams.sortBy, pagingParams.sortDirection, id))
        loadUsers();
    }

    const handleCarrierSelectFromModal = (carrier: CarrierListItem | undefined) => {
        setSelectedFormCarrier(carrier);
        setCarrierChangedFrom("modal")
        setBlockAutoChangeFormCarrier(true);
    }



    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3'>
                {loadingData ?
                    <LoadingDataComponent height="300px" />
                    :
                    <>
                        <Col xs={12} lg={8} >
                            <div className="shadow p-3 rounded h-100 bg-white">
                                <Row>
                                    <div className="col-auto">
                                        <label htmlFor="selectCarrier" className="col-form-label">{t("carrier")}:</label>
                                    </div>
                                    <div className="col-auto">
                                        <Form.Select
                                            id="selectCarrier"
                                            name="selectCarrier"
                                            value={selectedCarrier?.id || undefined}
                                            defaultValue={undefined}
                                            onChange={(e) => handleCarrierSelectChange(e.target.value)}
                                        >
                                            <option value={undefined}>{t("all")}</option>
                                            {
                                                carriersHavingUsers.map(carrier => (
                                                    <option key={carrier.id} value={carrier.id}>{carrier.name}{carrier.cofor ? ` (${carrier.cofor})` : undefined}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={12}>
                                        {
                                            users.length === 0 ?
                                                <Col xs={12} className='mt-3 text-center'>
                                                    <Alert variant='info' className='text-center shadow'>
                                                        <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                                        <br />
                                                        {t('usersNotFound')}
                                                    </Alert>
                                                </Col>
                                                :
                                                <>
                                                    <UsersTable
                                                        users={users}
                                                        handleRowCommandButtonClick={handleRowCommandButton}
                                                        pagination={pagination}
                                                        sort={(val) => handleSort(val)}
                                                        queryParams={pagingParams}
                                                        isCarrierColumnsHidden={isCarrierColumnsHidden}
                                                        selectedUser={selectedUser}
                                                        selectUser={(val) => setSelectedUser(val)}
                                                        selectedTab={selectedTab}
                                                    />
                                                    {pagination &&
                                                        <TablePagination
                                                            pagination={pagination}
                                                            changePage={handleChangePage}
                                                        />
                                                    }
                                                </>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                            <Col lg={4}>
                                <div className="shadow p-3 rounded h-100 bg-white">
                                    {selectedUser ?
                                        <>
                                            <Tab.Container defaultActiveKey="editUser">
                                                <Row>
                                                    <Col xs={12}>
                                                        <Nav
                                                            variant="tabs"
                                                            justify={true}
                                                            onSelect={(val: any) => handleTabSelect(val)}
                                                        >
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    active={selectedTab === "editUser"}
                                                                    eventKey="editUser">
                                                                    {t("editcontact")}

                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    active={selectedTab === "deleteUser"}
                                                                    eventKey="deleteUser"
                                                                >
                                                                    {t("deleteContact")}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Tab.Content>
                                                            <Tab.Pane
                                                                active={selectedTab === "editUser"}
                                                                eventKey="editUser"
                                                            >
                                                                <CarrierUserForm />
                                                            </Tab.Pane>
                                                            <Tab.Pane
                                                                active={selectedTab === "deleteUser"}
                                                                eventKey="deleteUser"
                                                            >
                                                                <DeleteUserPanel />
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>
                                        </>
                                        :
                                        <>
                                            <Row>
                                                <Col xs={12} className="text-center">
                                                    <p className="my-4 fs-5 fw-bold text-primary">{t("addnewcontact")}</p>
                                                </Col>
                                                <Col xs={12} >
                                                    <CarrierUserForm />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </div>
                            </Col>
                            <SelectCarrierModal
                                show={showSelectCarrierModal}
                                setShow={setShowSelectCarrierModal}
                                setValue={(fieldName, value) => { }}
                                selectCarrier={(val) => handleCarrierSelectFromModal(val)}
                                selectedCarrierId={selectedFormCarrier?.id}
                            limitedToContainsContracts={false}
                            blockWithoutContactUsers={false}
                            />
                    </>
                }
            </Row>
        </Container>
    )
})