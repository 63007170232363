import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './NavBar';
import ActiveAuctionsPage from '../../features/activeAuctions/ActiveAuctionsPage';
import { Route, Routes, Navigate } from 'react-router-dom';
import NewAuctionPage from '../../features/newAuction/NewAuctionPage';
import FinishedAuctionPage from '../../features/finishedAuctions/FinishedAuctionPage';
import HistoryPage from '../../features/history/HistoryPage';
import StatisticsPage from '../../features/statistics/StatisticsPage';
import CarriersPage from '../../features/settings/carriers/CarriersPage';
import { ToastContainer } from 'react-toastify'
import './../../i18Next'
import LoginForm from '../../features/users/LoginForm';
import Logout from '../../features/users/Logout';
import { useStore } from '../store/store';
import { observer } from 'mobx-react-lite';
import LoadingAppComponent from './LoadingAppComponent';
import PageNotFoundComponent from './PageNotFoundComponent';
import ApproversPage from '../../features/settings/approvers/ApproversPage';
import EmailFooterPage from '../../features/settings/emailFooter/EmailFooterPage';
import APage from '../../features/notLogged/addPriceDirectlyFromEmail/APage';
import PPage from '../../features/notLogged/approveWinnerDirectlyFromEmail/PPage';
import NewOrderPage from '../../features/newOrder/newOrderPage';
import ContractPage from '../../features/settings/contracts/ContractsPage';
import DocksPage from '../../features/settings/docks/DocksPage';
import FollowUpPage from '../../features/settings/followUp/FollowUpPage';
import customHistory from "../..";
import OPage from '../../features/notLogged/approveOrderDirectlyFromEmail/OPage';
import ActiveOrdersPage from '../../features/activeOrders/ActiveOrdersPage';


function App() {
    const { commonStore, userStore } = useStore();

    useEffect(() => {
            userStore.getUser()
                .finally(() => {
                    commonStore.setAppLoaded();
                    if (window.location.pathname === '/') {
                        customHistory.push("/auctions/active")
                    } 
                });
    }, [userStore, commonStore])

    if (!commonStore.appLoaded) return <LoadingAppComponent />

    return (
        <div className="App">
            <ToastContainer pauseOnHover position="bottom-right" />
            {!userStore.isLoggedIn ?
                (
                    <Routes>
                        <Route path='/a/' element={<APage />} />
                        <Route path='/p/' element={<PPage />} />
                        <Route path='/o/' element={<OPage />} />
                        <Route path='/login' element={<LoginForm />} />
                        <Route path="*" element={<Navigate to="/login" replace />} />
                    </Routes>
                )
                :
                (
                    <>
                        <NavBar />
                        <Routes>
                            <Route path='/orders/new' element={<NewOrderPage />} />
                            <Route path='/orders/active' element={<ActiveOrdersPage />} />
                            <Route path='/auctions/new' element={<NewAuctionPage />} />
                            <Route path='/auctions/active' element={<ActiveAuctionsPage />} />
                            <Route path='/auctions/finished' element={<FinishedAuctionPage />} />
                            <Route path='/history' element={<HistoryPage />} />
                            <Route path='/statistics' element={<StatisticsPage />} />
                            <Route path='/settings/approvers' element={<ApproversPage />} />
                            <Route path='/settings/contact' element={<CarriersPage />} />
                            <Route path='/settings/contracts' element={<ContractPage />} />
                            <Route path='/settings/docks' element={<DocksPage />} />
                            <Route path='/settings/footer' element={<EmailFooterPage />} />
                            <Route path='/settings/followUp' element={<FollowUpPage />} />
                            <Route path='/notFound' element={<PageNotFoundComponent />} />
                            <Route path='/a/' element={<APage />} /> 
                            <Route path='/p/' element={<PPage />} /> 
                            <Route path='/o/' element={<OPage />} />
                            <Route path='/logout' element={<Logout />} />
                            <Route path="*" element={<Navigate to="/notFound" replace />} />
                        </Routes>
                    </>
                )}
        </div>
    );
}

export default observer(App);
