import { observer } from 'mobx-react-lite';
import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Alt, Person, Translate, Building } from "react-bootstrap-icons";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import { store, useStore } from '../store/store';
import i18n from './../../i18Next';

export default observer(function NavBar() {
    const { t } = useTranslation();
    const { userStore: { user, logout, changeLanguage, getUser } } = useStore();


    const changeLang = (language: string) => () => {        
        if (user?.role === 'GMDB') {
            changeLanguage({ langId: language })
                .then(() => getUser())
                .then(() => toast.success(t("languageChangedSuccessfully")))
                .catch(() => toast.error(t("languageChangedUnsuccessfully")));
        } else {
            i18n.changeLanguage(language);
            toast.success(t("languageChangedSuccessfully"));
        }
        localStorage.setItem('langID', language);
        i18n.changeLanguage(language);
    };

    const changeOwner = (ownerId: number, plantName: string) => {
        store.commonStore.setSelectedOwner(ownerId.toString());
        store.commonStore.setSelectedPlantName(plantName);
        toast.info(t("actingOnBehalfOfInfo").replace("###companyName###", plantName));
    }

    const navLinkClassName = "text-start text-lg-center my-auto"

    return (
        <Navbar collapseOnSelect sticky="top" className="main-nav-bar" expand="lg">
            <Container fluid>
                <Navbar.Brand as={NavLink} to="/auctions/active">
                    <Alt color='royalblue' height={33} width={33} className='me-2' />
                    PREMIUM</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} className={navLinkClassName} to="/orders/new" href="/orders/new">{t('newOrder')}</Nav.Link>
                        <Nav.Link as={NavLink} className={navLinkClassName} to="/orders/active" href="/orders/active">{t('pendingOrders')}</Nav.Link>
                        <Nav.Link as={NavLink} className={navLinkClassName} to="/auctions/new" href="/auctions/new">{t('newauction')}</Nav.Link>
                        <Nav.Link as={NavLink} className={navLinkClassName} to="/auctions/active" href="/auctions/active">{t('activeAuctions')}</Nav.Link>
                        <Nav.Link as={NavLink} className={navLinkClassName} to="/auctions/finished" href="/auctions/finished">{t('finishedauctions')}</Nav.Link>
                        <Nav.Link as={NavLink} className={navLinkClassName} to="/history" href="/history">{t('historyreports')}</Nav.Link>
                        <Nav.Link as={NavLink} className={navLinkClassName} to="/statistics" href="/statistics">{t('stats')}</Nav.Link>
                        {user?.accessToSettings === true ?
                            <NavDropdown title={t('settings')} id="navbarSettingsDropdown" className={navLinkClassName}>
                                <NavDropdown.Item as={NavLink} to="/settings/approvers" href="/settings/approvers">{t('approvers')}</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/settings/contact" href="/settings/contact">{t('carriersusers')}</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/settings/contracts" href="/settings/contracts">{t('contracts')}</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/settings/docks" href="/settings/docks">{t('docks')}</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/settings/footer" href="/settings/footer">{t('emailfooter')}</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/settings/followUp" href="/settings/followUp">{t('followup')}</NavDropdown.Item>
                            </NavDropdown> : null}
                    </Nav>
                    <Navbar.Collapse className="justify-content-end mt-2 ">
                        {user?.source === "CCCIL" &&
                            <NavDropdown
                                id="supported-plants-nav-dropdown"
                                className="ms-0 my-3 ms-lg-3 my-lg-0 me-4"
                                title={<span><Building color='royalblue'
                                    height={26}
                                    width={26} /><label className="ms-2">{store.commonStore.selectedPlant}</label></span>}
                            >
                                {user?.supportedPlants.map(plant => (
                                    <NavDropdown.Item key={plant.companyId} onClick={() => changeOwner(plant.ownerId, plant.companyName)} >
                                        {plant.companyName} {plant.companyCofor && `(${plant.companyCofor})`}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        }
                        <NavDropdown
                            id="lang-nav-dropdown"
                            title={
                                <span><Translate color='royalblue'
                                    height={26}
                                    width={26} />
                                    <label className="ms-2">{localStorage.getItem("langID")?.toUpperCase()}</label>
                                </span>
                            }
                        >
                            <NavDropdown.Item onClick={changeLang('en')}>
                                EN
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={changeLang('pl')}>
                                PL
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={changeLang('de')}>
                                DE
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            id="logged-user-nav-dropdown"
                            className="ms-0 my-3 ms-lg-3 my-lg-0"
                            title={<span><Person color='royalblue'
                                height={26}
                                width={26} /><label>{user?.name}</label></span>}

                        >
                            <NavDropdown.Item onClick={logout}>
                                Log out          {/*DODAC TLUMACZENIE*/}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
})