import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, ListGroup, Row, Spinner } from 'react-bootstrap';
import { InfoCircle, Trash3Fill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import agent from '../../../app/api/agent';
import { CreateDockDto } from '../../../app/models/createDockDto';
import { Dock } from '../../../app/models/dock';
import { useStore } from '../../../app/store/store';
import LoadinDataComponent from '../../common/LoadingDataComponent';
import ValidationErrorList from '../../error/ValidationErrorList';

export default observer(function DocksPage() {
    const { t } = useTranslation();
    const { commonStore } = useStore()

    const [docks, setDocks] = useState<Dock[]>([]);
    const [newDockName, setNewDockName] = useState<string>('')
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [apiErrors, setApiErrors] = useState(null);

    const loadDocks = () => {
        agent.Docks.ownerList()
            .then((response) => {
                setDocks(response);
            })
            .finally(() => setLoading(false));
    }

    const handleSubmit = () => {
        const newDock: CreateDockDto = {
            name: newDockName!
        };

        agent.Docks.add(newDock)
            .then(() => {
                toast.success(t("dockAddedSuccessfully"));
                setNewDockName('');
                setApiErrors(null);
                loadDocks();
            })
            .catch(err => {
                toast.error(t("dockAddedUnuccessfully"));
                setApiErrors(err);
            })
            .finally(() => setSubmitting(false))
    }

    const handleDeleteDock = (id: number) => {
        agent.Docks.delete(id)
            .then(() => {
                toast.success(t("dockDeletedSuccessfully"));
                loadDocks();
                setApiErrors(null);
            })
            .catch(() => toast.error(t("dockDeletedUnsuccessfully")));
    }

    const handleClearClick = () => {
        setNewDockName('');
        setApiErrors(null);
    }

    const isNewDockCorrect = () => {
        return (!docks.some(d => d.name === newDockName)) && (newDockName.length > 0);
    }

    useEffect(() => {
        loadDocks();
    }, [commonStore.selectedOwner])

    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3'>
                <Col xs={12} md={10} lg={8} xl={7} xxl={5}>
                    <div className="shadow p-3 rounded bg-white">
                        <Form>
                            {/*<Row>*/}
                            {/*    <Col xs={12} className="text-center my-2">*/}
                            {/*        <p className="h4 fw-bold">{t("Docks")}:</p>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row>
                                <Col xs={12} md={6} className="mt-md-4">
                                    <Col xs={12} className="text-center">
                                        <p className="h5 fw-bold">{t("list")}:</p>
                                    </Col>
                                    {
                                        loading ?
                                            <LoadinDataComponent height="140px" />
                                            :
                                            docks.length > 0 ?
                                                <ListGroup >
                                                    {docks.map((dock, index) => (
                                                        <ListGroup.Item key={index}>
                                                            <Row>
                                                                <Col xs={6}><span className="fw-bold">{index + 1}.</span> {dock.name}</Col>
                                                                <Col xs={6} className="text-end">
                                                                    <span className="text-danger cursor-pointer" onClick={() => handleDeleteDock(dock.dockId)}>
                                                                        <Trash3Fill className="me-2 mb-1" width={20} height={20} />{t("delete")}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                                :
                                                <Alert variant='info' className='text-center shadow mb-0'>
                                                    <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                                    <br />
                                                    {t('docksNotFound')}
                                                </Alert>
                                    }
                                </Col>
                                <Col xs={12} md={6} className="mt-4">
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <p className="h5 fw-bold">{t("addDock")}:</p>
                                        </Col>
                                        <Col xs={12} className="text-center mt-2">
                                            <Form.Control
                                                type="text"
                                                maxLength={5}
                                                value={newDockName}
                                                onChange={(e) => setNewDockName(e.target.value)}
                                                style={{ maxWidth: 120 }}
                                                className="mx-auto"
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col xs={12} sm className="text-center mt-2">
                                            <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" disabled={!isNewDockCorrect()} onClick={(e) => { e.preventDefault(); setSubmitting(true); handleSubmit(); }}>
                                                {submitting ? <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-1"
                                                /> : null}
                                                {submitting ? (t("saving")) : t("addDock")}
                                            </Button>
                                            <Button variant="secondary" className="mx-1" onClick={() => handleClearClick()} >
                                                {t("clear")}
                                            </Button>
                                        </Col>
                                    </Row>
                                   
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm className="text-center mt-2">
                                    {apiErrors !== null ?
                                        <ValidationErrorList errorList={apiErrors} /> : null}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
})