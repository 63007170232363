import React from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MailingHistory } from '../../app/models/mailingHistory';
import { getMessageTypeName } from '../common/functions/langObjectTranslation';
import storage from '../../app/store/storage';


interface Props {
    mails: MailingHistory[],
//    premInbNo: string
}

export default function MailingHistoryList({ mails }: Props) {
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    const columns: string[] = [
        "username",
        "email",
        "phone",
        "messageType",
        "sent"
    ]

    return (
        <Table responsive hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="table-light" >
                <tr>
                    {columns.map((headerPhrase, index) => (
                        <th key={index}>{t(headerPhrase)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {mails.map((mail, index) => (
                    <tr key={index}>
                        {/*<td>{premInbNo}</td>*/}
                        <td>{mail.userName}</td>
                        <td>{mail.email}</td>
                        <td>{mail.phone}</td>
                        <td>{getMessageTypeName(mail.msgType, lang)}</td>
                        <td>{moment(new Date(mail.sent)).format("DD.MM.YYYY HH:mm")}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}