import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Alert, Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { CarrierListItem } from '../../../app/models/carrierListItem';
import { ContractListItem } from '../../../app/models/contractListItem';
import { QueryParamsContractsTable } from '../../../app/models/queryParamsContractsTable';
import { useStore } from '../../../app/store/store';
import LoadingDataComponent from '../../common/LoadingDataComponent';
import SelectCarrierModal from '../../common/SelectCarrierModal';
import TablePagination from '../../common/TablePagination';
import ContractForm from './ContractForm';
import ContractsTable from './ContractsTable';
import DeleteContractPanel from './DeleteContractPanel';

export default observer(function ContractPage() {
    const { t } = useTranslation();
    const { contractsStore, commonStore } = useStore();
    const { contracts, loadContracts,
        selectedContract, setSelectedContract,
        carriersHavingContract, loadCarriersHavingContract,
        selectedCarrier, setSelectedCarrier,
        showSelectCarrierModal, setShowSelectCarrierModal,
        selectedFormCarrier, setSelectedFormCarrier,
        pagingParams, setPagingParams, resetParams,
        selectedTab, setSelectedTab,
        contractTypes, loadContractTypes,
        loadCurrencies, loadingData, pagination,
        handleSort, handleChangePage,
        setCarrierChangedFrom, setBlockAutoChangeFormCarrier
    } = contractsStore;

    useEffect(() => {
        loadContracts();
        setSelectedTab(selectedTab);
        // eslint-disable-next-line
    }, [contractsStore, commonStore.selectedOwner])

    useEffect(() => {
        resetParams();
        setSelectedCarrier(undefined);
        setSelectedContract(null);
        setSelectedTab("addContract");
        // eslint-disable-next-line
    }, [commonStore.selectedOwner])

    useEffect(() => {
        loadCarriersHavingContract();
        loadContractTypes();
        loadCurrencies();
        // eslint-disable-next-line
    }, [])

    const handleTabSelect = (val: "addContract" | "editContract" | "deleteContract") => {
        setSelectedTab(val);
    }

    function handleRowCommandButton(tabName: "addContract" | "editContract" | "deleteContract", selectedContract: ContractListItem | null) {
        setSelectedTab(tabName);
        setSelectedContract(selectedContract);
    }

    const isCarrierColumnsHidden = !(selectedCarrier?.id === undefined);

    const handleCarrierSelectChange = (val: string) => {
        const id: number | undefined = Number(val) ? Number(val) : undefined;
        const carrier = carriersHavingContract.find(c => c.id === id);

        setSelectedCarrier(carrier);
        setSelectedFormCarrier(carrier);
        setCarrierChangedFrom("select");
        setPagingParams(new QueryParamsContractsTable(1, 10, pagingParams.sortBy, pagingParams.sortDirection, id))
        loadContracts();
    }

    const handleCarrierSelectFromModal = (carrier: CarrierListItem | undefined) => {
        setSelectedFormCarrier(carrier);
        setCarrierChangedFrom("modal")
        setBlockAutoChangeFormCarrier(true);
    }

    return (
        <>
            <Container fluid className='mt-3'>
                <Row className='justify-content-center g-3'>
                    {loadingData ?
                        < LoadingDataComponent height="300px" />
                        :
                       <>
                            <Col xs={12} lg={8} >
                                <div className="shadow p-3 rounded h-100 bg-white">
                                    <Row>
                                        <div className="col-auto">
                                            <label htmlFor="selectCarrier" className="col-form-label">{t("carrier")}:</label>
                                        </div>
                                        <div className="col-auto">
                                            <Form.Select
                                                id="selectCarrier"
                                                name="selectCarrier"
                                                value={selectedCarrier?.id || undefined}
                                                defaultValue={undefined}
                                                onChange={(e) => handleCarrierSelectChange(e.target.value)}
                                            >
                                                <option value={undefined}>{t("all")}</option>
                                                {
                                                    carriersHavingContract.map(carrier => (
                                                        <option key={carrier.id} value={carrier.id}>{carrier.name}{carrier.cofor ? ` (${carrier.cofor})` : undefined}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </div>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={12}>
                                            {
                                                contracts.length === 0 ?
                                                    <Col xs={12} className='mt-3 text-center'>
                                                        <Alert variant='info' className='text-center shadow'>
                                                            <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                                            <br />
                                                            {t('contractsNotFound')}
                                                        </Alert>
                                                    </Col>
                                                    :
                                                    <>
                                                        <ContractsTable
                                                            contracts={contracts}
                                                            selectedContract={selectedContract}
                                                            selectedTab={selectedTab}
                                                            handleRowCommandButtonClick={handleRowCommandButton}
                                                            isCarrierColumnsHidden={isCarrierColumnsHidden}
                                                            pagination={pagination}
                                                            sort={(val) => handleSort(val)}
                                                            queryParams={pagingParams}
                                                        />
                                                        {pagination &&
                                                            <TablePagination
                                                                pagination={pagination}
                                                                changePage={handleChangePage}
                                                            />
                                                        }
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="shadow p-3 rounded h-100 bg-white">
                                    {selectedContract ?
                                        <>
                                            <Tab.Container defaultActiveKey="editUser">
                                                <Row>
                                                    <Col xs={12}>
                                                        <Nav
                                                            variant="tabs"
                                                            justify={true}
                                                            onSelect={(val: any) => handleTabSelect(val)}
                                                        >
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    active={selectedTab === "editContract"}
                                                                    eventKey="editContract">
                                                                    {t("editcontract")}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    active={selectedTab === "deleteContract"}
                                                                    eventKey="deleteContract"
                                                                >
                                                                    {t("deleteContract")}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Tab.Content>
                                                            <Tab.Pane
                                                                active={selectedTab === "editContract"}
                                                                eventKey="editContract"
                                                            >
                                                                <ContractForm contractTypes={contractTypes} />
                                                            </Tab.Pane>
                                                            <Tab.Pane
                                                                active={selectedTab === "deleteContract"}
                                                                eventKey="deleteContract"
                                                            >
                                                                <DeleteContractPanel />
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>
                                        </>
                                        :
                                        <>
                                            <Row>
                                                <Col xs={12} className="text-center">
                                                    <p className="my-4 fs-5 fw-bold text-primary">{t("addNewContract")}</p>
                                                </Col>
                                                <Col xs={12} >
                                                    <ContractForm contractTypes={contractTypes} />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </div>
                            </Col>
                            <SelectCarrierModal
                                show={showSelectCarrierModal}
                                setShow={setShowSelectCarrierModal}
                                setValue={(fieldName, value) => { }}
                                selectCarrier={(val) => handleCarrierSelectFromModal(val)}
                                selectedCarrierId={selectedFormCarrier?.id}
                                limitedToContainsContracts={false}
                                blockWithoutContactUsers={false}
                            />
                        </>
                    }
                </Row>
            </Container>
        </>
    )
})