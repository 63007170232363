import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { DatePeriod } from "../datePeriod";
import { endDateNotGreatherThanNoOfDays } from "./validateFunctions";

class DatePeriodValidator extends Validator<DatePeriod> {
    constructor() {
        super();

        const errors = {};

        this.ruleFor('startDate')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('endDate')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('endDate')
            .must((x, field) => endDateNotGreatherThanNoOfDays(field.startDate, field.endDate, 100)!)
            .withMessage(i18next.t("valEndDateNotGreatherThanNoOfDays").replace("#days#", "100"))
            .when(x => x.startDate !== null && x.endDate !== null)

    }
}

export default DatePeriodValidator