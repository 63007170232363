import { Validator } from 'fluentvalidation-ts';
import i18next from 'i18next';
import { AuctionCreateDto } from '../auctionCreateDto';
import { isIntegerGreatherThan, isNumericFieldEmpty, endDateAfterStartDate } from './validateFunctions';

class AuctionCreateValidator extends Validator<AuctionCreateDto> {
    constructor() {
        super();

        const errors = {};

        this.ruleFor('duration')
        .must(x => isNumericFieldEmpty(x)!)
            .withMessage(i18next.t("valRequiredField"))
            .when(x => x !== undefined)
        .must(x => isIntegerGreatherThan(x, 0)!)
        .withMessage(i18next.t("valValueGreatherThan").replace("#value#", "0"))

        this.ruleFor('modeId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('transportTypeId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('primaryReasonId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('followUpId')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('responsiblePartyId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('billToId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('billToValue')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('weight')
            .must(x => isNumericFieldEmpty(x)!)
            .withMessage(i18next.t("valRequiredField"))
            .must(x => isIntegerGreatherThan(x, 0)!)
            .withMessage(i18next.t("valValueGreatherThan").replace("#value#", "0"))

        this.ruleFor('supplierDuns')
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('supplierName')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(100).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "100"));

        this.ruleFor('supplierAddress')
            .maxLength(100).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "100"));

        this.ruleFor('supplierZipCode')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('supplierCity')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(35).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "35"));

        this.ruleFor('supplierCountryCode')
            .notEmpty().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('pickUpDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('deliveryDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('deliveryDateTime')
            .must((x, field) => endDateAfterStartDate(field.pickUpDateTime, field.deliveryDateTime)!)
            .withMessage(i18next.t("valDeliveryDateGreatherThanPickup"))
            .when(x => x.pickUpDateTime !== null && x.deliveryDateTime !== null)

        this.ruleFor('currencyId')
            .notEmpty().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('remarks')
            .maxLength(250).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "250"));
    }
}

export default AuctionCreateValidator