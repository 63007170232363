import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { XCircleFill, InfoCircleFill, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { AuctionActive } from '../../app/models/auctionActive';
import PaginationModel from '../../app/models/paginatedResult';
import { QueryParamsTable } from '../../app/models/queryParamsTable';
import storage from '../../app/store/storage';
import { getSupplierCountryName } from '../common/functions/langObjectTranslation';

interface Props {
    auctions: AuctionActive[],
    handleRowCommandButtonClick: (action: string, selectedAuctionID: AuctionActive | null) => void,
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsTable
}

export default function ActiveAuctionsTable({ auctions, handleRowCommandButtonClick, pagination, sort, queryParams }: Props) {
    const { t } = useTranslation();
    const alertLeftTime: number = 10;
    const lang = storage.Lang.get();

    const columns = [
        { labelPhrase: "left", accessor: "Left2End" },
        { labelPhrase: "prices", accessor: "Prices" },
        { labelPhrase: "auctionId", accessor: "AuctionId" },
        { labelPhrase: "supplierduns", accessor: "SupplierDuns" },
        { labelPhrase: "country", accessor: "SupplierCountry" },
        { labelPhrase: "zipcode", accessor: "SupplierZipCode" },
        { labelPhrase: "city", accessor: "SupplierCity" },
        { labelPhrase: "pickupdatetime", accessor: "PickUpDateTime" },
        { labelPhrase: "deliverydatetime", accessor: "DeliveryDateTime" },
        { labelPhrase: "", accessor: "" },
    ];

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            {Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)}
        </tr>
    )

    return (

        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => (
                        <th
                            key={index}
                            onClick={column.accessor
                                ? () => sort(column.accessor)
                                : undefined}
                            className={queryParams.sortBy === column.accessor
                                ? "th-sorted-by"
                                : undefined}
                            style={column.accessor
                                ? { cursor: "pointer" }
                                : undefined}
                        >
                            {t(column.labelPhrase)}
                            {queryParams.sortBy === column.accessor
                                ? queryParams.sortDirection === "ASC"
                                    ? <CaretUpFill className="ms-1" />
                                    : <CaretDownFill className="ms-1" />
                                : null}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {auctions && auctions.map((auction) => (
                    <tr key={auction.auctionId}>
                        <td>
                            <span
                                className={auction.left2End < alertLeftTime
                                    ? 'text-danger fw-bold'
                                    : undefined}
                                style={{ whiteSpace: 'nowrap' }}>
                                {auction.left2End !== 0
                                    ? `${auction.left2End} ${t('minutes')}`
                                    : t('lessthanminute')}
                            </span>
                        </td>
                        <td>{auction.pricesCount}</td>
                        <td>{auction.auctionId}</td>
                        <td>{auction.supplierDuns}</td>
                        <td>{getSupplierCountryName(auction.supplierCountry, lang)}</td>
                        <td>{auction.supplierZipCode}</td>
                        <td>{auction.supplierCity}</td>
                        <td>{moment(new Date(auction.pickUpDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(new Date(auction.deliveryDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span className='cursor-pointer mx-1' onClick={() => { handleRowCommandButtonClick('showDetailsModal', auction) }}>
                                    <InfoCircleFill className='text-info' height={21} width={21} />
                                </span>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('showConfirmlDeleteModal', auction)}>
                                    <XCircleFill className='text-danger' height={21} width={21} />
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
                {emptyRows}
            </tbody>
        </Table>

    )
}