import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, HandIndexThumb, Search } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { AuctionHistoryListItemDto } from '../../app/models/auctionHistoryListItemDto';
import PaginationModel from '../../app/models/paginatedResult';
import { QueryParamsHistoryTable } from '../../app/models/queryParamsHistoryTable';
import storage from '../../app/store/storage';
import { getStatusName } from '../common/functions/langObjectTranslation';

interface Props {
    auctions: AuctionHistoryListItemDto[],
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsHistoryTable,
    selectedAuctionId: number | null,
    selectAuction: (id: number | null) => void,
    showAttachments: (auctionId: number) => void
}

export default function HistoryAuctionTable({ auctions, pagination, sort, queryParams, selectedAuctionId, selectAuction, showAttachments }: Props) {
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    const columns = [
        { labelPhrase: "prices", accessor: "PricesCount" },
        { labelPhrase: "auctionId", accessor: "AuctionId" },
        { labelPhrase: "orderNo", accessor: "OrderNo" },
        { labelPhrase: "pickupdatetime", accessor: "PickUpDateTime" },
        { labelPhrase: "deliverydatetime", accessor: "DeliveryDateTime" },
        { labelPhrase: "cancelledFinishedDate", accessor: "CanceledFinishedDateTime" },
        { labelPhrase: "attachments", accessor: "NoOfAttachments" },
        { labelPhrase: "status", accessor: "Status" },
        { labelPhrase: "", accessor: "" }
    ];

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            {Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)}
        </tr>
    )

    const handleShowAttachmentsButtonClick = (auctionId: number) => {
        showAttachments(auctionId);
    }

    return (
        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => (
                        <th
                            key={index}
                            onClick={column.accessor
                                ? () => sort(column.accessor)
                                : undefined}
                            className={queryParams.sortBy === column.accessor
                                ? "th-sorted-by"
                                : undefined}
                            style={column.accessor
                                ? { cursor: "pointer" }
                                : undefined}
                        >
                            {t(column.labelPhrase)}
                            {queryParams.sortBy === column.accessor
                                ? queryParams.sortDirection === "ASC"
                                    ? <CaretUpFill className="ms-1" />
                                    : <CaretDownFill className="ms-1" />
                                : null}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {auctions.map(auction => (
                    <tr key={auction.auctionId} className={auction.auctionId === selectedAuctionId ? "selected-table-row" : undefined}
                    >
                        <td>{auction.prices}</td>
                        <td>{auction.auctionId}</td>
                        <td>{auction.orderNo}</td>
                        <td>{moment(new Date(auction.pickUpDateTime!)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(new Date(auction.deliveryDateTime!)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(new Date(auction.canceledFinishedDateTime!)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{auction.noOfAttachments} {auction.noOfAttachments > 0 ?
                            <span className='cursor-pointer' onClick={() => { handleShowAttachmentsButtonClick(auction.auctionId) }}>
                                <Search className='text-primary' height={21} width={21} />
                            </span>
                            : null}
                        </td>
                        <td className={auction.status?.statusId !== "A" ? "text-danger" : undefined}>{getStatusName(auction.status, lang)}</td>
                        <td>
                            <span
                                className='cursor-pointer mx-1'
                                onClick={() => { selectAuction(auction.auctionId); }}
                            >
                                <span style={{ whiteSpace: 'nowrap', textDecoration: "none" }}>
                                    <HandIndexThumb className='text-primary me-1' height={21} width={21} />
                                    {t("select").toLowerCase()}
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
                {emptyRows}
            </tbody>
        </Table>
        )
}