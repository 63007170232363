import { QueryParamsTable } from "./queryParamsTable";

export class QueryParamsHistoryTable extends QueryParamsTable {
    searchPhrase?: string | undefined;
    searchIn?: string | undefined;
    dateFrom?: Date | undefined;
    dateTo?: Date | undefined;
    dateField?: string | undefined;
    status?: string | undefined;

    constructor(
        pageNumber: number,
        pageSize: number,
        sortBy: string | undefined,
        sortDirection: string | undefined,
        searchPhrase: string | undefined = undefined,
        searchIn: string | undefined = undefined,
        dateFrom: Date | undefined = undefined,
        dateTo: Date | undefined = undefined,
        dateField: string | undefined = undefined,
        status: string | undefined = undefined,

    ) {
        super(pageNumber, pageSize, sortBy, sortDirection);
        this.searchPhrase = searchPhrase;
        this.searchIn = searchIn;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.dateField = dateField;
        this.status = status;
    }
}