import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { OrderActiveListItem } from "../models/orderActiveListItem";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsTable } from "../models/queryParamsTable";
import { store } from "./store";

export default class ActiveOrdersStore {
    constructor() {
        makeAutoObservable(this);
    }

    orders: OrderActiveListItem[] = [];
    setOrders = (orders: OrderActiveListItem[]) => this.orders = orders;

    selectedOrder: OrderActiveListItem | null = null;
    setSelectedOrder = (order: OrderActiveListItem | null) => this.selectedOrder = order;

    loadingData = true;
    setLoadingData = (loadingData: boolean) => this.loadingData = loadingData;

    rowCommandAction: string | null = null;
    setRowCommandAction = (actionName: string | null) => this.rowCommandAction = actionName;

    pagination: PaginationModel | null = null
    setPagination = (pagination: PaginationModel) => this.pagination = pagination;

    pagingParams: QueryParamsTable = new QueryParamsTable(1, 10, "OrderId", "ASC");
    setPagingParams = (params: QueryParamsTable) => this.pagingParams = params;

    loadActiveOrders = () => {
        agent.Orders.activeList(this.getAxiosParams())
            .then(response => {
                this.setOrders(response.data);
                this.setPagination(response.pagination);
                this.setLoadingData(false);
            })
            .then(() => this.setLoadingData(false))
            .catch()
            .finally(() => store.commonStore.selectedOwner);
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsTable(this.pagingParams.pageNumber, this.pagingParams.pageSize, fieldName, sortDirection)
        );
        this.loadActiveOrders();
    }

    changePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection))
        this.loadActiveOrders();
    }

    cancelSelectedOrder = (selectedReason: string) => {
        return agent.Orders.cancel(this.selectedOrder?.orderId!, Number(selectedReason)).then(() => {
            this.setSelectedOrder(null);
            this.setRowCommandAction(null);
            this.loadActiveOrders();
        })
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }
        return params;
    }
}