import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { FollowUpFormModel } from "../models/FollowUpFormModel";
import { FollowUpSettingsListItem } from "../models/followUpSettingsListItem";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsTable } from "../models/queryParamsTable";

export default class FollowUpStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingData = true;
    setLoadingData = (val: boolean) => this.loadingData = val;

    followUps: FollowUpSettingsListItem[] = [];
    setFollowUps = (val: FollowUpSettingsListItem[]) => this.followUps = val;

    selectedFollowUp: FollowUpSettingsListItem | null = null
    setSelectedFollowUp = (val: FollowUpSettingsListItem | null) => this.selectedFollowUp = val;

    selectedTab: "addFollowUp" | "editFollowUp" | "deleteFollowUp" = "addFollowUp";
    setSelectedTab = (val: "addFollowUp" | "editFollowUp" | "deleteFollowUp") => this.selectedTab = val;

    pagingParams: QueryParamsTable = new QueryParamsTable(1, 10, "Name", "ASC");
    setPagingParams = (params: QueryParamsTable) => this.pagingParams = params;

    pagination: PaginationModel | null = null;
    setPagination = (pagination: PaginationModel) => this.pagination = pagination;

    resetPagingParams = () => {
        this.setPagingParams(new QueryParamsTable(1, 10, "Name", "ASC"));
    }

    loadFollowUps = () => {
        try {
            return agent.FollowUp.list(this.getAxiosParams())
                .then(response => {
                    this.setFollowUps(response.data);
                    this.setPagination(response.pagination);
                })
                .then(() => this.setLoadingData(false))
        } catch (e) {
            console.log(e);
        }
    }

    addFollowUp = (val: FollowUpFormModel) => {
        return agent.FollowUp.add(val)
            .then(() => this.loadFollowUps())
            .catch();
    }

    updateFollowUp = (val: FollowUpFormModel) => {
        return agent.FollowUp.update(this.selectedFollowUp?.userId!, val)
            .then(() => {
                this.setSelectedFollowUp(null);
                this.setSelectedTab("addFollowUp");
                this.loadFollowUps();
            });
    }

    deleteFollowUp = () => {
        return agent.FollowUp.delete(this.selectedFollowUp?.userId!)
            .then(() => {
                this.setSelectedFollowUp(null);
                this.setSelectedTab("addFollowUp");
                this.loadFollowUps();
            });
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsTable(this.pagingParams.pageNumber, this.pagingParams.pageSize, fieldName, sortDirection)
        );
        this.loadFollowUps();
    }

    changePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection))
        this.loadFollowUps();
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }
        return params;
    }
}