import moment from 'moment';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuctionDetailsForApproveWinnerDto } from '../../../app/models/auctionDetailsForApproveWinnerDto';
import { getCurrencySymbol } from '../../common/functions/langObjectTranslation';

interface Props {
    model: AuctionDetailsForApproveWinnerDto;
}

export default function WinnerPriceDetailsPanelApproveWinner({ model }: Props) {
    const { t } = useTranslation();

    const labelColumnClass = "text-end fw-bolder px-1 px-md-2";
    const valueColumnClass = "text-start align-self-center px-1 px-md-2";

    return (
        <Card className="text-center">
            <Card.Header className="fw-bold">
                <span>s
                    {t("winner")}
                </span>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12} lg={6} >
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("code")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {model.winnerPrice.userCompanyCode}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("carrierName")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {model.winnerPrice.userCompanyName}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("name")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {model.winnerPrice.userName}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("email")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {model.winnerPrice.userEmail}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("phone")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {model.winnerPrice.userMobilePhone}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("realPickupDateTime")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {moment(new Date(model.winnerPrice.realPickUpDateTime!)).format("DD.MM.YYYY HH:mm")}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("maxLoadingDateTime")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {moment(new Date(model.winnerPrice.maxLoadingDateTime!)).format("DD.MM.YYYY HH:mm")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("price")}:
                            </Col>
                            <Col xs={6} className="text-start align-self-center px-1 px-md-2 fw-bold text-success" >
                                {model.winnerPrice.price} {getCurrencySymbol(model.currencySymbol)}
                            </Col>
                        </Row>
                    </Col>
                    {model.winnerPrice.priceDateTime &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("priceDatetime")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {moment(new Date(model.winnerPrice.priceDateTime!)).format("DD.MM.YYYY HH:mm")}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
        )
}