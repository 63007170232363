import { makeAutoObservable, reaction } from "mobx"

export default class CommonStore {
    selectedOwner: string | null = window.localStorage.getItem('ownerId');
    selectedPlant: string | null = window.localStorage.getItem('selectedPlantName');
    appLoaded = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.selectedOwner,
            selectedOwner => {
                if (selectedOwner) {
                    window.localStorage.setItem('ownerId', selectedOwner)
                }
            }
        )

        reaction(
            () => this.selectedPlant,
            selectedPlant => {
                if (selectedPlant) {
                    window.localStorage.setItem('selectedPlantName', selectedPlant)
                }
            }
        )
    }

    setSelectedOwner = (ownerID: string | null) => {
        this.selectedOwner = ownerID;
    }

    setSelectedPlantName = (plantName: string | null) => {
        this.selectedPlant = plantName;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }
}

