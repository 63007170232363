import axios, { AxiosResponse } from 'axios';
import { AuctionActive } from '../models/auctionActive';
import { AuctionCreateDto } from '../models/auctionCreateDto';
import { toast } from 'react-toastify';
import { SupplierCountry } from '../models/supplierCountry';
import { Currency } from '../models/currency';
import { SupplierDto } from '../models/supplierDto';
import { User } from '../models/user';
import { UserLoginDto } from '../models/userLoginDto';
import i18next from 'i18next';
import customHistory from '../..';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { store } from '../store/store';
import { baseApiUrl } from '../../config';
import { PaginatedResult } from '../models/paginatedResult';
import { AuctionFinished } from '../models/auctionFinished';
import { BeginAgainFinishedAuction } from '../models/beginAgainFinishedAuction';
import { CancellationReason } from '../models/cancellationReason';
import { CarrierUserCreateUpdateDto } from '../models/carrierUserCreateUpdateDto';
import { CarrierUserListItemDto } from '../models/carrierUserListItemDto';
import { DatePeriod } from '../models/datePeriod';
import { SupplierNameDunsDto } from '../models/supplierNameDunsDto';
import { Setting } from '../models/setting';
import { Approver } from '../models/approver';
import { AuctionHistoryListItemDto } from '../models/auctionHistoryListItemDto';
import { AuctionDetailsForHistory } from '../models/auctionDetailsForHistory';
import { UpdateUserLanguage } from '../models/updateUserLanguage';
import { NewPriceDirectlyFromEmail } from '../models/newPriceDirectlyFromEmail';
import { PriceListItemDto } from '../models/priceListItemDto';
import { WinnerPrice } from '../models/winnerPriceDto';
import { Approval } from '../models/approval';
import { CreatePlayOff } from '../models/createPlayOff';
import { CarrierListItem } from '../models/carrierListItem';
import { ContractType } from '../models/contractType';
import { ContractCreateUpdateDto } from '../models/contractCreateUpdateDto';
import { ContractListItem } from '../models/contractListItem';
import { Dock } from '../models/dock';
import { CreateDockDto } from '../models/createDockDto';
import { ContractSelectListItem } from '../models/contractSelectListItem';
import { OrderReason } from '../models/orderReasonDto';
import { TransportType } from '../models/transportType';
import { Mode } from '../models/mode';
import { ServiceLevel } from '../models/serviceLevel';
import { ResponsibleParty } from '../models/responsibleParty';
import { BillTo } from '../models/billTo';
import { FollowUpSettingsListItem } from '../models/followUpSettingsListItem';
import { FollowUpFormModel } from '../models/FollowUpFormModel';
import { FollowUpListItem } from '../models/FollowUpListItem';
import { UploadFileResult } from '../models/uploadFileResult';
import { OrderCreateDto } from '../models/orderCreateDto';
import { AttachmentListItem } from '../models/attachmentListItem';
import { OrderHistoryListItem } from '../models/orderHistoryListItem';
import { AdditionalCostType } from '../models/additionalCostType';
import { OrderActiveListItem } from '../models/orderActiveListItem';
import { OrderActiveDetails } from '../models/orderActiveDetails';
import { Confirmation } from '../models/confitmation';
import { AuctionDetailsForApproveWinnerDto } from '../models/auctionDetailsForApproveWinnerDto';
import { OrderApproveDetails } from '../models/orderApproveDetails';
import { AuctionDetailsForAddPrice } from '../models/auctionDetailsForAddPrice';
import { OrderDetailsForHistory } from '../models/orderDetailsForHistory';
import { LogoutDto } from '../models/logoutDto';

axios.defaults.baseURL = baseApiUrl;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const refreshAuthLogic = (failedRequest: any) =>
    axios.get(`${baseApiUrl}/account/refresh`).then((tokenRefreshResponse) => {
        failedRequest.response.config.withCredentials = true;
        return Promise.resolve().then();
    }).catch((err: any) => {

        const originalRequestURL: string = failedRequest.request.responseURL;

        if (!(originalRequestURL.includes('/api/account'))) {
            if (Array.isArray(err)) {
                if (err[0] === 'Refresh token expired') {
                    const message401 = i18next.t("autoLogoutInfo").toString()
                    toast.error(message401);
                    customHistory.push('/logout');
                }
            }
        }
    });

axios.interceptors.request.use(config => {
    config.withCredentials = true;
    return config;
})

axios.interceptors.response.use(
    response => {
        const pagination = response.headers['pagination'];
        if (pagination) {
            response.data = new PaginatedResult(response.data, JSON.parse(pagination));
            return response as AxiosResponse<PaginatedResult<any>>;
        }
        return response;
    },
    (error: any) => {
        const { data, status } = error.response!;
        let errors = []

        switch (status) {
            case 400:
                if (data.errors) {
                    const modalStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(data.errors[key])
                        }
                    }
                    throw modalStateErrors.flat();
                }
                if (data) {
                    if (typeof data === 'string' || data instanceof String) {
                        const modalStateErrors = [];
                        modalStateErrors.push(data);
                        throw modalStateErrors.flat();
                    }
                }
                break;
            case 401:
            case 403:
                if (data.errors) {
                    const modalStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(data.errors[key])
                        }
                    }
                    throw modalStateErrors.flat();
                }
                if (data) {
                    if (typeof data === 'string' || data instanceof String) {
                        const modalStateErrors = [];
                        modalStateErrors.push(data);
                        throw modalStateErrors.flat();
                    }
                }
                break;
            case 404:
                const message404 = i18next.t("apiErrorNotFoundInfo").toString()
                toast.error(message404);
                errors.push(message404);
                throw errors.flat();
            case 409:
                const message409 = i18next.t("apiErrorConflictInfo").toString()
                toast.error(message409);
                errors.push(message409);
                throw errors.flat();
            case 415:
                const message415 = i18next.t("apiErrorUnsupportedMediaType").toString()
                toast.error(message415);
                errors.push(message415);
                throw errors.flat();
            case 500:
                const message500 = i18next.t("apiErrorServerErrorInfo").toString()
                toast.error(message500);
                errors.push(message500);
                throw errors.flat();
            default:
                const message = i18next.t("apiErrorUnexpected").toString()
                toast.error(message);
                errors.push(message);
                throw errors.flat();
        }
        return Promise.reject(error);
    })

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    putWithoutBody: <T>(url: string) => axios.put<T>(url).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axios.patch<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    getFile: (url: string, body: {}) => axios({ url: url, method: 'POST', responseType: 'blob', data: body }),
    getFileById: (url: string) => axios({ url: url, method: 'GET', responseType: 'blob' }),
    getFileWithParams: (url: string, params: {}) => axios({ url: url, method: 'GET', responseType: 'blob', params: params }),
}

createAuthRefreshInterceptor(axios, refreshAuthLogic);

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserLoginDto) => requests.post<User>('/account/login', user),
    logout: (dto: LogoutDto) => requests.put<LogoutDto>('/account/logout', dto),
    updateLanguage: (lang: UpdateUserLanguage) => requests.patch('/account/language', lang)
}

const Approvals = {
    getApprovalListItems: (id: number) => requests.get<Approval[]>(`/owner/${store.commonStore.selectedOwner}/auction/${id}/approvals`),
    }

const Approvers = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Approver[]>>(`/owner/${store.commonStore.selectedOwner}/approver`, { params })
        .then(responseBody),
    add: (approver: Approver) => requests.post(`/owner/${store.commonStore.selectedOwner}/approver`, approver),
    update: (approver: Approver, amount: number, currency: string, email: string) => requests.put(`/owner/${store.commonStore.selectedOwner}/approver?amount=${amount}&currency=${currency}&email=${email}`, approver),
    delete: (amount: number, currency: string, email: string) => requests.del(`/owner/${store.commonStore.selectedOwner}/approver?amount=${amount}&currency=${currency}&email=${email}`),
}

const Auctions = {
    create: (createAuctionDto: AuctionCreateDto) => requests.post(`/owner/${store.commonStore.selectedOwner}/auction`, createAuctionDto),
    active: (params: URLSearchParams) => axios.get<PaginatedResult<AuctionActive[]>>(`/owner/${store.commonStore.selectedOwner}/auction/active`, { params }).then(responseBody),
    cancel: (id: number, cancellationReason: number) => requests.putWithoutBody(`/owner/${store.commonStore.selectedOwner}/auction/${id}/cancel/${cancellationReason}`),
    finished: (params: URLSearchParams) => axios.get<PaginatedResult<AuctionFinished[]>>(`/owner/${store.commonStore.selectedOwner}/auction/finished`, { params }).then(responseBody),
    beginAgain: (id: number, dto: BeginAgainFinishedAuction) => axios.patch(`/owner/${store.commonStore.selectedOwner}/auction/${id}/beginAgain`, dto),
    history: (params: URLSearchParams) => axios.get<PaginatedResult<AuctionHistoryListItemDto[]>>(`/owner/${store.commonStore.selectedOwner}/auction/history`, { params }).then(responseBody),
    historyDetails: (id: number) => requests.get<AuctionDetailsForHistory>(`/owner/${store.commonStore.selectedOwner}/auction/${id}/history`),
    makePlayOff: (auctionId: number, dto: CreatePlayOff) => requests.patch(`/owner/${store.commonStore.selectedOwner}/auction/${auctionId}/makePlayOff`, dto),
}

const Carriers = {
    getOwnerUsers: (id: number | undefined, params: URLSearchParams) => axios.get<PaginatedResult<CarrierUserListItemDto[]>>(`/owner/${store.commonStore.selectedOwner}/carrier/${id ? id : "all"}/user`, { params }).then(responseBody),
    getOwnerCarrierList: (limitedToHavingContracts: boolean, limitedToHavingUsers: boolean) => requests.get<CarrierListItem[]>(`/owner/${store.commonStore.selectedOwner}/carrier${limitedToHavingContracts ? "?havingContracts=true" : ''}${limitedToHavingUsers ? "?havingUsers=true" : ''}`),
    addUser: (user: CarrierUserCreateUpdateDto) => requests.post(`/owner/${store.commonStore.selectedOwner}/carrier/${user.companyId}/user`, user),
    updateUser: (carrierId: number, userId: number, user: CarrierUserCreateUpdateDto) => requests.put(`/owner/${store.commonStore.selectedOwner}/carrier/${carrierId}/user/${userId}`, user),
    deleteUser: (id: number, carrierId: number) => requests.del(`/owner/${store.commonStore.selectedOwner}/carrier/${carrierId}/user/${id}`)
}

const Contracts = {
    list: (id: number | undefined, params: URLSearchParams) => axios.get<PaginatedResult<ContractListItem[]>>(`/owner/${store.commonStore.selectedOwner}/carrier/${id ? id : "all"}/contract`, { params }).then(responseBody),
    getCarrierSelectList: (carrierId: number) => requests.get<ContractSelectListItem[]>(`/owner/${store.commonStore.selectedOwner}/carrier/${carrierId}/contract/selectList`),
    add: (dto: ContractCreateUpdateDto) => requests.post(`/owner/${store.commonStore.selectedOwner}/carrier/${dto.companyId}/contract`, dto),
    update: (carrierId: number | undefined, contractId: number, dto: ContractCreateUpdateDto) => requests.patch(`/owner/${store.commonStore.selectedOwner}/carrier/${carrierId}/contract/${contractId}`, dto),
    delete: (carrierId: number, contractId: number) => requests.del(`/owner/${store.commonStore.selectedOwner}/carrier/${carrierId}/contract/${contractId}`)
}

const Docks = {
    ownerList: () => requests.get<Dock[]>(`/owner/${store.commonStore.selectedOwner}/docks`),
    add: (dto: CreateDockDto) => requests.post(`/owner/${store.commonStore.selectedOwner}/docks`, dto),
    delete: (id: number) => requests.del(`/owner/${store.commonStore.selectedOwner}/docks/${id}`)
}

const Documents = {
    auctionDetailsPDF: (auctionId: number) => requests.getFileById(`/owner/${store.commonStore.selectedOwner}/report/pdf/auction/${auctionId}/details`),
    auctionHistoryXLS: (params: URLSearchParams, lang: string) => axios({ url: `/owner/${store.commonStore.selectedOwner}/report/xls/auction/history/${lang}`, method: 'GET', responseType: 'blob', params: params }),
    auctionMailingReportXLS: (auctionId: number, lang: string) => axios({ url: `/owner/${store.commonStore.selectedOwner}/report/xls/auction/${auctionId}/mailing/${lang}`, method: 'GET', responseType: 'blob' }),
    auctionStatisticsXLS: (period: DatePeriod, lang: string) => requests.getFile(`/owner/${store.commonStore.selectedOwner}/report/xls/statistics/auction/${lang}`, period),
    orderDetailsPDF: (orderId: number, lang: string) => requests.getFileById(`/owner/${store.commonStore.selectedOwner}/report/pdf/order/${orderId}/details/${lang}`),
    orderHistoryXLS: (params: URLSearchParams, lang: string) => axios({ url: `/owner/${store.commonStore.selectedOwner}/report/xls/order/history/${lang}`, method: 'GET', responseType: 'blob', params: params }),
    orderMailingReportXLS: (orderId: number, lang: string) => axios({ url: `/owner/${store.commonStore.selectedOwner}/report/xls/order/${orderId}/mailing/${lang}`, method: 'GET', responseType: 'blob' }),
    orderStatisticsXLS: (period: DatePeriod, lang: string) => requests.getFile(`/owner/${store.commonStore.selectedOwner}/report/xls/statistics/order/${lang}`, period)
}

const File = {
    upload: (file: any[], sessionId: string, onUploadProgress: any) => {
        let formData = new FormData();

        for (var i = 0; i < file.length; i++) {
            formData.append("files", file[i]);
        }

        return axios.post<UploadFileResult[]>(`/file/${sessionId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    },
    download: (uploadId: number) => requests.getFileById(`/file/${uploadId}`),
    auctionAttachments: (auctionId: number) => requests.get<AttachmentListItem[]>(`/file?auctionId=${auctionId}`),
    orderAttachments: (orderId: number) => requests.get<AttachmentListItem[]>(`/file?orderId=${orderId}`),
    delete: (id: string) => {
        return axios.delete(`/file/${id}`)
    },
    deleteBySessionId: (sessionId: string) => {
        return axios.delete(`/file?session=${sessionId}`)
    }
}

const FollowUp = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<FollowUpSettingsListItem[]>>(`/owner/${store.commonStore.selectedOwner}/followUp`, { params }).then(responseBody),
    add: (dto: FollowUpFormModel) => requests.post(`/owner/${store.commonStore.selectedOwner}/followUp`, dto),
    update: (fillowUpId: number, dto: FollowUpFormModel) => requests.patch(`/owner/${store.commonStore.selectedOwner}/followUp/${fillowUpId}`, dto),
    delete: (fillowUpId: number) => requests.del(`/owner/${store.commonStore.selectedOwner}/followUp/${fillowUpId}`),
    getSelectionList: () => requests.get<FollowUpListItem[]>(`/owner/${store.commonStore.selectedOwner}/followUp/selectionList`)
}

const ManageDirectlyFromEmail = {
    auctionDetailsForAddPrice: (qs: string) => requests.get<AuctionDetailsForAddPrice>(`/auction/addPrice/?qs=${qs}`),
    auctionDetailsForApprove: (qs: string) => requests.get<AuctionDetailsForApproveWinnerDto>(`/auction/approve/?qs=${qs}`),
    orderDetailsForApprove: (qs: string) => requests.get<OrderApproveDetails>(`/order/approve/?qs=${qs}`),
    addPrice: (qs: string, newPrice: NewPriceDirectlyFromEmail) => requests.post(`/auction/addPrice/?qs=${qs}`, newPrice),
    approveOrder: (qs: string, dto: Confirmation) => requests.post(`/order/approve/?qs=${qs}`, dto),
    approveAuctionWinner: (qs: string, dto: Confirmation) => requests.post(`/auction/approve/?qs=${qs}`, dto)
}

const Orders = {
    add: (dto: OrderCreateDto) => requests.post<number>(`/owner/${store.commonStore.selectedOwner}/order`, dto),
    getOrderNo: (orderID: number) => requests.get<string>(`/owner/${store.commonStore.selectedOwner}/order/${orderID}/orderNo`),
    activeList: (params: URLSearchParams) => axios.get<PaginatedResult<OrderActiveListItem[]>>(`/owner/${store.commonStore.selectedOwner}/order/active`, { params }).then(responseBody),
    activeDetails: (orderId: number) => requests.get<OrderActiveDetails>(`/owner/${store.commonStore.selectedOwner}/order/${orderId}/active`),
    cancel: (id: number, cancellationReason: number) => requests.putWithoutBody(`/owner/${store.commonStore.selectedOwner}/order/${id}/cancel/${cancellationReason}`),
    history: (params: URLSearchParams) => axios.get<PaginatedResult<OrderHistoryListItem[]>>(`/owner/${store.commonStore.selectedOwner}/order/history`, { params }).then(responseBody),
    historyDetails: (id: number) => requests.get<OrderDetailsForHistory>(`/owner/${store.commonStore.selectedOwner}/order/${id}/history`),
}

const Packages = {
    auctionPackages: (auctionID: number) => axios.get(`/owner/${store.commonStore.selectedOwner}/auction/${auctionID}/package`)
}

const Parts = {
    auctionParts: (auctionID: number) => axios.get(`/owner/${store.commonStore.selectedOwner}/auction/${auctionID}/part`)
}

const Prices = {
    list: (auctionID: number, params: URLSearchParams) => axios.get<PaginatedResult<PriceListItemDto[]>>(`/owner/${store.commonStore.selectedOwner}/auction/${auctionID}/price`, { params }).then(responseBody),
    makeWinner: (auctionId: number, priceListItemDto: PriceListItemDto) => requests.put(`/owner/${store.commonStore.selectedOwner}/auction/${auctionId}/price/winner`, priceListItemDto),
    getWinnerPrice: (auctionId: number) => requests.get<WinnerPrice>(`/owner/${store.commonStore.selectedOwner}/auction/${auctionId}/price/winner`)
}

const Selections = {
    cancellationReason: () => requests.get<CancellationReason[]>('data/cancellationReason'),
    billToList: () => requests.get<BillTo[]>('data/billTo'),
    currencies: () => requests.get<Currency[]>('data/currencies'),
    contractTypes: () => requests.get<ContractType[]>('data/contractTypes'),
    countries: () => requests.get<SupplierCountry[]>('/data/country'),
    primaryReasons: () => requests.get<OrderReason[]>('data/orderReason/primary'),
    secondaryReasons: (primaryReasonId: string) => requests.get<OrderReason[]>(`data/orderReason/${primaryReasonId}/secondary`),
    modes: () => requests.get<Mode[]>('data/modes'),
    transportTypes: () => requests.get<TransportType[]>('data/transportTypes'),
    serviceLevels: () => requests.get<ServiceLevel[]>('data/serviceLevels'),
    additionalCosts: () => requests.get<AdditionalCostType[]>('data/additionalCosts'),
    responsibleParties: () => requests.get<ResponsibleParty[]>('data/responsibleParties')
}

const Settings = {
    getOwnerFooter: () => axios.get(`/owner/${store.commonStore.selectedOwner}/settings/emailFooter`),
    updateOwnerFooter: (setting: Setting) => requests.post(`/owner/${store.commonStore.selectedOwner}/settings/emailFooter`, setting)
}

const Supplier = {
    getDunsNamesList: () => requests.get<SupplierNameDunsDto[]>('/supplier/nameduns'),
    byDuns: (duns: string) => requests.get<SupplierDto>(`/supplier/${duns}`)
}

const agent = {
    Account,
    Approvals,
    Approvers,
    Auctions,
    Carriers,
    Contracts,
    Docks,
    Documents,
    FollowUp,
    File,
    ManageDirectlyFromEmail,
    Orders,
    Packages,
    Parts,
    Prices,
    Selections,
    Settings,
    Supplier,
}

export default agent;

