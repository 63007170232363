import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import customHistory from '../..';
import { OrderActiveListItem } from '../../app/models/orderActiveListItem';
import { useStore } from '../../app/store/store';
import ConfirmAuctionCancellationModal from '../activeAuctions/ConfirmAuctionCancellationModal';
import LoadingDataComponent from '../common/LoadingDataComponent';
import RefreshTableRemainingTime from '../common/RefreshTableRemainingTime';
import TablePagination from '../common/TablePagination';
import ActiveOrderDetailsModal from './ActiveOrderDetailModal';
import ActiveOrdersTable from './ActiveOrdersTable';

export default observer(function ActiveOrdersPage() {
    const { activeOrdersStore, commonStore } = useStore();
    const { t } = useTranslation();

    function handleRowCommandButton(action: string, selectedOrder: OrderActiveListItem | null) {
        activeOrdersStore.setRowCommandAction(action);
        activeOrdersStore.setSelectedOrder(selectedOrder);
    }

    function handleHideModal() {
        activeOrdersStore.setSelectedOrder(null);
        activeOrdersStore.setRowCommandAction(null);
    }

    useEffect(() => {
        activeOrdersStore.loadActiveOrders();
    }, [activeOrdersStore, commonStore.selectedOwner])

    return (
        <>
            <Container fluid className='mt-3'>
                <Row className='justify-content-center g-3'>
                    {activeOrdersStore.loadingData ?
                        <LoadingDataComponent height="300px" />
                        :
                        activeOrdersStore.orders.length === 0 ?
                            <>
                                <Col md={8} className='mt-3'>
                                    <Alert variant='info' className='text-center shadow'>
                                        <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                        <br />
                                        {t('activeOrdersNotFound1of2')}
                                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => customHistory.push('/orders/new')}>{t('clickHere')}</span>
                                        {t('activeOrdersNotFound2of2')}
                                    </Alert>
                                </Col>
                                <Col xs={8} className="text-center text-md-end mt-0">
                                    <RefreshTableRemainingTime reloadFunction={() => activeOrdersStore.loadActiveOrders()} />
                                </Col>
                            </>
                            :
                            <Col xs={12}>
                                <div className="shadow p-3 rounded bg-white">
                                    <ActiveOrdersTable
                                        orders={activeOrdersStore.orders}
                                        handleRowCommandButtonClick={handleRowCommandButton}
                                        pagination={activeOrdersStore.pagination}
                                        sort={(val) => activeOrdersStore.handleSort(val)}
                                        queryParams={activeOrdersStore.pagingParams}
                                    />
                                    {activeOrdersStore.pagination &&
                                        <Row>
                                            <Col xs={12} md={6} className="">
                                                <TablePagination
                                                    pagination={activeOrdersStore.pagination}
                                                    changePage={activeOrdersStore.changePage}
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className="text-center text-md-end pt-2">
                                                <RefreshTableRemainingTime reloadFunction={() => activeOrdersStore.loadActiveOrders()} />
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Col>}
                </Row>
                {activeOrdersStore.selectedOrder &&
                    <ActiveOrderDetailsModal
                        show={activeOrdersStore.rowCommandAction === 'showDetailsModal'}
                        setShow={handleHideModal}
                        orderId={activeOrdersStore.selectedOrder.orderId} />}
                {activeOrdersStore.selectedOrder &&
                    <ConfirmAuctionCancellationModal
                        objectType="order"
                        show={activeOrdersStore.rowCommandAction === 'showConfirmlDeleteModal'}
                        setShow={handleHideModal}
                    />}
            </Container>
        </>
    )
});