import { observer } from 'mobx-react-lite';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../app/store/store';
import ApprovalsList from '../common/ApprovalsList';
import PriceList from './AuctionWinnerPriceTable';

export default observer(function ApprovingWinningAuction() {
    const { finishedAuctionsStore } = useStore();

    const { t } = useTranslation();
    return (
        <Row>
            <Col xs={12} className="text-center">
                <p className="my-3 fs-5 fw-bold text-primary">{t("approvingWinningAuction")}</p>
            </Col>
            {finishedAuctionsStore.selectedAuctionWinner ?
                <Col xs={12} >
                    <PriceList currency={finishedAuctionsStore.selectedAuction?.currency.currencyId!} price={finishedAuctionsStore.selectedAuctionWinner}  />
                    {/**/}
                </Col>
                : null}
            <Col xs={12} className="mt-2" >
                {finishedAuctionsStore.selectedAuctionApprovalListItems.length > 0 ?
                    <ApprovalsList approvals={finishedAuctionsStore.selectedAuctionApprovalListItems} showStatusForFinishedAuction={true} />
                    : 
                    <Alert variant='info' className='text-center shadow p-2'>
                        <span style={{ fontSize: 20 }}><InfoCircle /></span>
                        <br />
                        {t('priceApprovalNotNecesarry')}
                    </Alert>
                }
            </Col>
        </Row>
    )
})