import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { CarrierUserCreateUpdateDto } from "../carrierUserCreateUpdateDto";

class CarrierUserCreateUpdateValidator extends Validator<CarrierUserCreateUpdateDto>
{
    constructor() {
        super();

        this.ruleFor('companyId')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('name')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('email')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .emailAddress().withMessage(i18next.t("valEmailInvalidFormat"))

        this.ruleFor('langId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
    }
}

export default CarrierUserCreateUpdateValidator;