import { Validator } from 'fluentvalidation-ts';
import i18next from 'i18next';
import { OrderCreateDto } from '../orderCreateDto';
import { isIntegerGreatherThan, isNumericFieldEmpty, endDateAfterStartDate } from './validateFunctions';

class OrderCreateValidator extends Validator<OrderCreateDto> {
    constructor() {
        super();

        this.ruleFor('carrierId')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('contractId')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('modeId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('transportTypeId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('pickUpDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('deliveryDateTime')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('deliveryDateTime')
            .must((x, field) => endDateAfterStartDate(field.pickUpDateTime, field.deliveryDateTime)!)
            .withMessage(i18next.t("valDeliveryDateGreatherThanPickup"))
            .when(x => x.pickUpDateTime !== null && x.deliveryDateTime !== null)

        this.ruleFor('serviceLevelId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('primaryReasonId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('responsiblePartyId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('billToId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('billToValue')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('billAgreedWith')
            .maxLength(35).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "35"));

        this.ruleFor('logisticRefNo')
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('followUpId')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('weight').greaterThanOrEqualTo(0)
            .withMessage(i18next.t("valValueCannotBeNegative"))

        this.ruleFor('weight')
            .greaterThan(0).withMessage(i18next.t("valRequiredField"))
            .when(x => x.contractType === "W")

        this.ruleFor('weight')
            .lessThan(99999).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "5"))
            .when(x => !isNaN(x.weight));

        this.ruleFor('distance')
            .greaterThan(0).withMessage(i18next.t("valRequiredField"))
            .when(x => x.contractType === "D")

        this.ruleFor('distance')
            .lessThan(99999).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "5"))
            .when(x => !isNaN(x.distance));

        // pickup
        this.ruleFor('pickupDuns')
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('pickupName')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(50).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "50"));

        this.ruleFor('pickupAddress')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(100).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "100"));

        this.ruleFor('pickupZipCode')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('pickupCity')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(35).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "35"));

        this.ruleFor('pickupCountryCodeId')
            .notEmpty().withMessage(i18next.t("valRequiredField"));

        this.ruleFor('pickupContactName')
            .maxLength(50).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "50"));

        this.ruleFor('pickupContactPhoneNo')
            .maxLength(15).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "15"));

        this.ruleFor('pickupContactEmail')
            .maxLength(50).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "50"))
            .emailAddress().withMessage(i18next.t("valEmailInvalidFormat"))
            .when(x => x.pickupContactEmail !== '');

        // delivery
        this.ruleFor('deliveryDuns')
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('deliveryName')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(50).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "50"));

        this.ruleFor('deliveryAddress')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(100).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "100"));

        this.ruleFor('deliveryZipCode')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(10).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "10"));

        this.ruleFor('deliveryCity')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(35).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "35"));

        this.ruleFor('deliveryCountryCodeId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('deliveryContactName')
            .maxLength(50).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "50"));

        this.ruleFor('deliveryContactPhoneNo')
            .maxLength(15).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "15"));

        this.ruleFor('deliveryContactEmail')
            .maxLength(50).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "50"))
            .emailAddress().withMessage(i18next.t("valEmailInvalidFormat"))
            .when(x => x.deliveryContactEmail !== '');

        this.ruleFor('totalPrice')
            .notNull().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('totalPrice')
            .lessThan(9999999).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "7"))
            .when(x => !isNaN(x.totalPrice));

        this.ruleFor('remarks')
            .maxLength(250).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "250"));

        this.ruleFor('userId')
            .notNull().withMessage(i18next.t("valUserProblem"))

        this.ruleFor('userSource')
            .notEmpty().withMessage(i18next.t("valUserProblem"))
            .notNull().withMessage(i18next.t("valUserProblem"))
    }
}

export default OrderCreateValidator