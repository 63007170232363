import React, { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import agent from '../../app/api/agent';
import { AttachmentListItem } from '../../app/models/attachmentListItem';
import { AttachmentsList } from './AttachmentsList';
import LoadingDataComponent from './LoadingDataComponent';

interface Props {
    show: boolean,
    hideModal: () => void,
    parentType: "auction" | "order",
    parentId: number
}

export function AttachmentModal({ show, hideModal, parentType, parentId }: Props) {

    const [attachments, setAttachments] = useState<AttachmentListItem[]>([]);
    const [isDownloading, setIsDownloading] = useState(true);
    const { t } = useTranslation();

    function loadAttachments() {
        setIsDownloading(true);

        if (parentType === "auction") {
            agent.File.auctionAttachments(parentId)
                .then(response => { setAttachments(response); })
                .then(() => setIsDownloading(false));
        }

        if (parentType === "order") {
            agent.File.orderAttachments(parentId)
                .then(response => { setAttachments(response); })
                .then(() => setIsDownloading(false));
        }
    }

    return (
        <Modal show={show} onHide={hideModal} onShow={loadAttachments} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <InfoCircle className='me-1 pb-1' height={30} width={30} />
                    {parentType === "auction" ?
                        `${t("auction")} ${parentId}`
                        :
                        `${t("order")} ${parentId}`
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-font-responsive">
                {isDownloading ?
                    <Row>
                        <Col>
                            <LoadingDataComponent height="240px" />
                        </Col>
                    </Row>
                    :
                    <>
                        <Container>
                            {attachments.length >= 0 ?
                                <Row className="mb-3">
                                    <Col className="px-0 px-sm-2 px-md-3">
                                        <h5 className="mb-0">{t("attachments")}:</h5>
                                        <AttachmentsList attachments={attachments} />
                                    </Col>
                                </Row>
                                : null}
                        </Container>
                    </>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}