import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Approval } from '../../app/models/approval';

interface Props {
    approvals: Approval[],
    showStatusForFinishedAuction?: boolean
}

export default function ApprovalsList({ approvals, showStatusForFinishedAuction = false }: Props) {
    const { t } = useTranslation();

    const columns: string[] = [
        "status",
        "email",
        "approvalDateTime",
        "ipAddress",
    ]

    const getStatusName = (status: Boolean) => {
        if (showStatusForFinishedAuction) {
            if (status === null) return <span className="text-primary fw-bold">{t("sended")}</span>;
            if (status) return <span className="text-success fw-bold">{t("accepted")}</span >;
            return <span className="text-danger fw-bold">{t("rejected")}</span>;

        } else {
            if (status === null) return t("sended");
            if (status) return t("accepted");
            return t("rejected");
        }
    }

    return (
        <Table responsive hover size="sm" className="table-content-centered">
            <thead className="table-light" >
                <tr>
                    {columns.map((headerPhrase, index) => (
                        <th key={index}>{t(headerPhrase)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {approvals.map((approval, index) => (
                    <tr key={index}>
                        <td>{getStatusName(approval.status)}</td>
                        <td>{approval.email}</td>
                        <td>{approval.approvalDateTime && moment(new Date(approval.approvalDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{approval.fromIP}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}