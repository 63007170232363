import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Package } from '../../app/models/package';

interface Props {
    packages: Package[]
}

export default function PackageList({ packages }: Props) {
    const { t } = useTranslation();

    return (
        <Table responsive hover size="sm" className="table-content-centered">
            <thead className="table-light">
                <tr>
                    <th>{t("length")} (mm)</th>
                    <th>{t("width")} (mm)</th>
                    <th>{t("height")} (mm)</th>
                    <th>{t("weight")} (kg)</th>
                    <th>{t("quantity")}</th>
                </tr>
            </thead>
            <tbody>
                {packages.map((pckg) => (
                    <tr key={pckg.packageId}>
                        <td>{pckg.length}</td>
                        <td>{pckg.width}</td>
                        <td>{pckg.height}</td>
                        <td>{pckg.weight}</td>
                        <td>{pckg.qty}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}