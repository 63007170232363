import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean,
    confirmAction: () => void;
    setShow: (show: boolean) => void;
    message: string;
    confirmationButtonVariant? : 'success' | 'danger'
}

export default function ConfirmModal({ show, confirmAction, setShow, message, confirmationButtonVariant = 'danger' }: Props) {
    const handleClose = () => setShow(false);
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <InfoCircle className='me-1 pb-1' height={30} width={30} />
                    { t("alert")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col className='text-center text-danger font-weight-bold'>
                            <p style={{wordBreak: "break-word"}} className="h5 fw-bold">{message}</p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={confirmationButtonVariant} onClick={confirmAction}>
                    { t("yes")}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    { t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}