import { AxiosResponse } from "axios";

export function printFile(response: AxiosResponse<any, any>) {
    var blob = new Blob([response.data], { type: 'application/pdf' });
    var blobURL = URL.createObjectURL(blob);

    var iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus();
            iframe.contentWindow!.print();
        }, 1);
    };
}

