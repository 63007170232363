import { Validator } from "fluentvalidation-ts";
import { UserLoginDto } from "../userLoginDto";
import i18next from "i18next";

class UserLoginDtoValidator extends Validator<UserLoginDto> {
    constructor() {
        super();

        this.ruleFor('login')
            .notEmpty()
            .withMessage(i18next.t("valLoginRequired"));

        this.ruleFor('password')
            .notEmpty()
            .withMessage(i18next.t("valPasswordRequired"));
    }
}

export default UserLoginDtoValidator;