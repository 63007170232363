import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import customHistory from '../..';
import LoadingDataComponent from '../common/LoadingDataComponent';
import { useStore } from '../../app/store/store';
import TablePagination from '../common/TablePagination';
import ActiveAuctionsTable from './ActiveAuctionsTable';
import ConfirmAuctionCancellationModal from './ConfirmAuctionCancellationModal';
import ActiveAuctionDetailsModal from './ActiveAuctionDetailsModal';
import RefreshTableRemainingTime from '../common/RefreshTableRemainingTime';
import { AuctionActive } from '../../app/models/auctionActive';

export default observer(function ActiveAuctionsPage() {
    const { activeAuctionsStore, commonStore } = useStore();

    function handleRowCommandButton(action: string, selectedAuction: AuctionActive | null) {
        activeAuctionsStore.setRowCommandAction(action);
        activeAuctionsStore.setSelectedAuction(selectedAuction);
    }

    function handleHideModal() {
        activeAuctionsStore.setSelectedAuction(null);
        activeAuctionsStore.setRowCommandAction(null);
    }

    useEffect(() => {
        activeAuctionsStore.loadActiveAuctions();
    }, [activeAuctionsStore, commonStore.selectedOwner])

    const { t } = useTranslation();

    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3'>
                {activeAuctionsStore.loadingData ?
                    <LoadingDataComponent height="300px" />
                    :
                    activeAuctionsStore.auctions.length === 0 ?
                        <>
                            <Col md={8} className='mt-3'>
                                <Alert variant='info' className='text-center shadow'>
                                    <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                    <br />
                                    {t('activeAuctionsNotFound1of2')}
                                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => customHistory.push('/auctions/new')}>{t('clickHere')}</span>
                                    {t('activeAuctionsNotFound2of2')}
                                </Alert>
                            </Col>
                            <Col xs={8} className="text-center text-md-end mt-0">
                                <RefreshTableRemainingTime reloadFunction={() => activeAuctionsStore.loadActiveAuctions()} />
                            </Col>
                        </>
                        :
                        <Col xs={12}>
                            <div className="shadow p-3 rounded bg-white">
                                <ActiveAuctionsTable
                                    auctions={activeAuctionsStore.auctions}
                                    handleRowCommandButtonClick={handleRowCommandButton}
                                    pagination={activeAuctionsStore.pagination}
                                    sort={(val) => activeAuctionsStore.handleSort(val)}
                                    queryParams={activeAuctionsStore.pagingParams}
                                />
                                {activeAuctionsStore.pagination &&
                                    <Row>
                                        <Col xs={12} md={6} className="">
                                            <TablePagination
                                                pagination={activeAuctionsStore.pagination}
                                                changePage={activeAuctionsStore.changePage}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} className="text-center text-md-end pt-2">
                                            <RefreshTableRemainingTime reloadFunction={() => activeAuctionsStore.loadActiveAuctions()} />
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </Col>}
            </Row>
            {activeAuctionsStore.selectedAuction &&
                <ActiveAuctionDetailsModal
                    show={activeAuctionsStore.rowCommandAction === 'showDetailsModal'}
                    setShow={handleHideModal}
                    auction={activeAuctionsStore.selectedAuction} />}
            {activeAuctionsStore.selectedAuction &&
                <ConfirmAuctionCancellationModal
                    objectType="auction"
                    show={activeAuctionsStore.rowCommandAction === 'showConfirmlDeleteModal'}
                    setShow={handleHideModal}
                />}
        </Container>
    )
})