import React, { ChangeEvent } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { PackageCreateDto } from '../../app/models/packageCreateDto';
import IntegerInput from '../common/IntegerInput';

interface Props {
    packages: PackageCreateDto[],
    onChange: (a: any, val: any) => void;
    getListFunction?: (packages: PackageCreateDto[]) => void;
    setSumOfWeightFunctionOutsideFirmik?: (weight: number) => void;
    autoSumPackages: boolean;
    setAutoSumPackages: (val: boolean) => void;
    initialNewPackageData: PackageCreateDto;
    newPackage: PackageCreateDto;
    setNewPackage: (pck: PackageCreateDto) => void;
}

export default function AddingPackageModule({ packages, onChange, autoSumPackages, newPackage, setNewPackage, initialNewPackageData, getListFunction = undefined, setSumOfWeightFunctionOutsideFirmik, setAutoSumPackages }: Props) {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewPackage({ ...newPackage, [name]: value })
    }

    const handleAddNewPackage = (newPackage: PackageCreateDto) => {
        packages.push(newPackage);
        onChange("packages", packages);
        getListFunction && getListFunction(packages);
        setNewPackage(initialNewPackageData);

        recalculateSumOfWeight(packages)
        setAutoSumPackages(true);
    }

    const handleAddKeyPress = (e: any) => {
        if (e.key === "Enter") {
            isNewPackageCorrect() && handleAddNewPackage(newPackage)
        }
    }

    const handleDeletePackage = (index: number) => {
        var packageToRemove = packages[index];
        var newPackages = packages.filter(item => item !== packageToRemove)
        onChange("packages", newPackages);
        getListFunction && getListFunction(newPackages);
        recalculateSumOfWeight(newPackages)
        newPackages.length === 0 ? setAutoSumPackages(false) : setAutoSumPackages(true);
    }

    const recalculateSumOfWeight = (pcks: PackageCreateDto[]) => {
        const sumWeight: number = pcks.reduce(function (pv, cv) {
            return parseInt(pv.toString()) + (parseInt(cv.qty!.toString()) * parseInt(cv.weight!.toString()));
        }, 0);
        setSumOfWeightFunctionOutsideFirmik && setSumOfWeightFunctionOutsideFirmik(sumWeight);
        onChange("weight", sumWeight);
    }

    const isNewPackageCorrect = () => {
        return newPackage.length &&
            newPackage.width &&
            newPackage.height &&
            newPackage.qty;
    }

    const { t } = useTranslation();
    const inputClassName = "input-min-width-80px";


    return (
        <div className="table-responsive mt-0  table-content-centered">
            <Table size="sm" className="pt-0">
                <thead className="gray-bg">
                    <tr>
                        <th className="ps-2 ps-md-4">#</th>
                        <th className="ps-2">{t('length')} [mm]:</th>
                        <th className="ps-2">{t('width')} [mm]:</th>
                        <th className="ps-2">{t('height')} [mm]:</th>
                        <th className="ps-2">{t('weight')} [kg]:</th>
                        <th className="ps-2">{t('quantity')}:</th>
                        <th className="ps-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {packages.map((pckg, i) => (
                        <tr key={i + 1}>
                            <td className="ps-2 ps-md-4">{i + 1}</td>
                            <td className="ps-2">{pckg.length}</td>
                            <td className="ps-2">{pckg.width}</td>
                            <td className="ps-2">{pckg.height}</td>
                            <td className="ps-2">{pckg.weight}</td>
                            <td className="ps-2">{pckg.qty}</td>
                            <td>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <span className='cursor-pointer mx-1' onClick={() => handleDeletePackage(i)}>
                                        <XCircleFill className='text-danger' height={22} width={22} />
                                    </span>
                                </span>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td>
                            <IntegerInput
                                id="packageLength"
                                name="length"
                                size="sm"
                                step={1}
                                className={inputClassName}
                                value={newPackage.length}
                                change={handleInputChange} />
                        </td>
                        <td>
                            <IntegerInput
                                id="packageWidth"
                                name="width"
                                size="sm"
                                step={1}
                                className={inputClassName}
                                value={newPackage.width}
                                change={handleInputChange} />
                        </td>
                        <td>
                            <IntegerInput
                                id="packageHeight"
                                name="height"
                                size="sm"
                                step={1}
                                className={inputClassName}
                                value={newPackage.height}
                                change={handleInputChange} />
                        </td>
                        <td>
                            <IntegerInput
                                id="packageWeight"
                                name="weight"
                                size="sm"
                                step={1}
                                className={inputClassName}
                                value={newPackage.weight}
                                change={handleInputChange} />
                        </td>
                        <td>
                            <IntegerInput
                                id="packageQty"
                                name="qty"
                                size="sm"
                                step={1}
                                className={inputClassName}
                                value={newPackage.qty}
                                change={handleInputChange} />
                        </td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span
                                    className={isNewPackageCorrect() ? 'cursor-pointer mx-1' : 'mx-1'}
                                    onKeyPress={(e) => handleAddKeyPress(e)}
                                    onClick={() => isNewPackageCorrect() && handleAddNewPackage(newPackage)}
                                >
                                    <PlusCircleFill
                                        className={isNewPackageCorrect() ? 'text-success smooth-color-trans' : 'text-secondary smooth-color-trans'}
                                        height={22}
                                        width={22}
                                        tabIndex={0}
                                    />
                                </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}