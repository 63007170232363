import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { InfoCircle, Clipboard, ClipboardCheck } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean,
    onClose: () => void,
    infoText: string | undefined,
    copyText?: string | undefined
}

export default function StaticBackDropInfoModal({ show, onClose, infoText, copyText = undefined }: Props) {
    const { t } = useTranslation();
    const [textCopied, setTextCopied] = useState<boolean>(false);

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onShow={() => setTextCopied(false)}
            centered
            onHide={() => onClose()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <InfoCircle className='me-1 pb-1' height={30} width={30} />Info
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} className="my-4 text-center fw-bold h5">
                        {infoText}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {copyText &&
                    <Button variant={textCopied ? "success" : "primary"} className="smooth-color-trans" onClick={() => {
                        navigator.clipboard.writeText(copyText);
                        setTextCopied(true);
                    }}>
                        {textCopied ? <ClipboardCheck className="me-2" /> : <Clipboard className="me-2" />}
                        {textCopied ? t("orderNoCopied") : t("orderNoCopy")}
                    </Button>
}
                <Button variant="secondary" onClick={() => onClose()}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}