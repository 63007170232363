export default interface PaginationModel {
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
    totalPages: number
}

export class PaginatedResult<T> {
    data: T;
    pagination: PaginationModel;

    constructor(data: T, pagination: PaginationModel) {
        this.data = data;
        this.pagination = pagination;
    }
}