import React, { useEffect } from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import { EnvelopePaper, FileEarmarkSpreadsheetFill, FilePdf, InfoCircle } from 'react-bootstrap-icons';
import agent from '../../app/api/agent';
import { useStore } from '../../app/store/store';
import LoadingDataComponent from '../common/LoadingDataComponent';
import TablePagination from '../common/TablePagination';
import HistoryAuctionTable from './HistoryAuctionTable';
import { observer } from 'mobx-react-lite';
import AuctionDetailsPanel from './AuctionDetailsPanel';
import { useTranslation } from 'react-i18next';
import { printFile } from '../../app/api/printFile';
import { AttachmentModal } from '../common/AttachmentsModal';
import { HistoryOrderTable } from './HistoryOrderTable';
import { OrderDetailsPanel } from './OrderDetailsPanel';
import AuctionsFilterForm from './AuctionsFilterForm';
import OrdersFilterForm from './OrdersFilterForm';
import storage from '../../app/store/storage';

export default observer(function HistoryPage() {
    const { t } = useTranslation();
    const { historyPageStore, historyAuctionsStore, historyOrdersStore, commonStore } = useStore();

    const lang = storage.Lang.get();

    const handleClickPrintPDFAuctionDetailsReport = () => {
        agent.Documents.auctionDetailsPDF(historyAuctionsStore.auctionDetails?.auctionId!)
            .then((response) => { printFile(response); });
    }

    const handleClickPrintPDFOrderDetailsReport = () => {
        agent.Documents.orderDetailsPDF(historyOrdersStore.orderDetails?.orderId!, lang)
            .then((response) => { printFile(response); });
    }

    useEffect(() => {
        if (historyPageStore.dataSourceType === "auctions") {
            var retrievedObject = storage.LocalStorage.get("auctions-filter-form-params")
            retrievedObject && historyAuctionsStore.setPagingParams(retrievedObject);

            historyAuctionsStore.loadHistoryAuctions();
        } else {
            var retrievedObject2 = storage.LocalStorage.get("orders-filter-form-params")
            retrievedObject2 && historyOrdersStore.setPagingParams(retrievedObject2);

            historyOrdersStore.loadHistoryOrders();
        }

    }, [historyPageStore.dataSourceType, historyAuctionsStore, historyOrdersStore, commonStore.selectedOwner]);

    useEffect(() => {
        historyAuctionsStore.loadAuctionDetails(historyAuctionsStore.selectedAuctionId)
        // eslint-disable-next-line
    }, [historyAuctionsStore.selectedAuctionId]);

    useEffect(() => {
        historyOrdersStore.loadOrderDetails(historyOrdersStore.selectedOrderId)
        // eslint-disable-next-line
    }, [historyOrdersStore.selectedOrderId]);

    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3'>
                <Col xs={12}>
                    <div className="shadow p-3 rounded h-100 bg-white">
                        <Row>
                            {historyPageStore.dataSourceType === "auctions" ?
                                <Col xs={12} className="mb-2">

                                    <span className="text-success" style={{ cursor: "pointer" }} onClick={() => { historyAuctionsStore.getXLSHistoryReport(storage.Lang.get()) }}>
                                        <FileEarmarkSpreadsheetFill width={32} height={32} />
                                    </span>
                                    <span
                                        className={historyAuctionsStore.auctionDetails ? "text-danger smooth-color-trans cursor-pointer ms-2" : "smooth-color-trans cursor-not-allowed ms-2"}
                                        onClick={historyAuctionsStore.auctionDetails ? () => handleClickPrintPDFAuctionDetailsReport() : undefined}>
                                        <FilePdf width={32} height={32} />
                                    </span>
                                    <span
                                        className={historyAuctionsStore.auctionDetails ? "text-primary smooth-color-trans cursor-pointer ms-2" : "smooth-color-trans cursor-not-allowed ms-2"}
                                        onClick={historyAuctionsStore.auctionDetails ? () => historyAuctionsStore.getXLSMailingReport(lang) : undefined}>
                                        <EnvelopePaper width={32} height={32} />
                                    </span>
                                    {historyAuctionsStore.generatingFile ?
                                        <span>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="ms-3 text-success"
                                            />
                                        </span>
                                        : undefined}
                                </Col> : null}
                            {historyPageStore.dataSourceType === "orders" ?
                                <Col xs={12} className="mb-2">

                                    <span className="text-success" style={{ cursor: "pointer" }} onClick={() => historyOrdersStore.getXLSHistoryReport(lang)}>
                                        <FileEarmarkSpreadsheetFill width={32} height={32} />
                                    </span>
                                    <span
                                        className={historyOrdersStore.orderDetails ? "text-danger smooth-color-trans cursor-pointer ms-2" : "smooth-color-trans cursor-not-allowed ms-2"}
                                        onClick={historyOrdersStore.orderDetails ? () => handleClickPrintPDFOrderDetailsReport() : undefined}>
                                        <FilePdf width={32} height={32} />
                                    </span>
                                    <span
                                        className={historyOrdersStore.orderDetails ? "text-primary smooth-color-trans cursor-pointer ms-2" : "smooth-color-trans cursor-not-allowed ms-2"}
                                        onClick={historyOrdersStore.orderDetails ? () => historyOrdersStore.getXLSMailingReport(lang) : undefined}>
                                        <EnvelopePaper width={32} height={32} />
                                    </span>
                                    {historyOrdersStore.generatingFile ?
                                        <span>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="ms-3 text-success"
                                            />
                                        </span>
                                        : undefined}
                                </Col> : null}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {historyPageStore.dataSourceType === "auctions" ?
                                    <AuctionsFilterForm /> : null}
                                {historyPageStore.dataSourceType === "orders" ?
                                    <OrdersFilterForm /> : null}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {historyPageStore.dataSourceType === "auctions" ?
                <Row className='justify-content-center g-3 mt-2'>
                    {historyAuctionsStore.loadingData ?
                        <LoadingDataComponent height="300px" />
                        :
                        historyAuctionsStore.historyAuctions.length === 0 ?
                            <Col md={8} className='mt-3'>
                                <Alert variant='info' className='text-center shadow'>
                                    <>
                                        <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                        <br />
                                        {t('finishedAuctionsNotFound')}
                                    </>
                                </Alert>
                            </Col>
                            :
                            <>
                                <Col xs={12} className='mt-0' >
                                    <div className="shadow p-3 rounded bg-white">
                                        <HistoryAuctionTable
                                            auctions={historyAuctionsStore.historyAuctions}
                                            pagination={historyAuctionsStore.pagination}
                                            sort={(val) => historyAuctionsStore.handleSort(val)}
                                            queryParams={historyAuctionsStore.pagingParams}
                                            selectedAuctionId={historyAuctionsStore.selectedAuctionId}
                                            selectAuction={(id) => historyAuctionsStore.setSelectedAuctionId(id)}
                                            showAttachments={(val) => historyAuctionsStore.setAttachmentsModalAuctionId(val)}

                                        />
                                        {historyAuctionsStore.pagination &&
                                            <TablePagination
                                                pagination={historyAuctionsStore.pagination}
                                                changePage={historyAuctionsStore.changePage}
                                            />
                                        }
                                    </div>
                                </Col>
                                {historyAuctionsStore.auctionDetails || historyAuctionsStore.loadingDetails ?
                                    <Col xs={12}>
                                        <AuctionDetailsPanel model={historyAuctionsStore.auctionDetails} loadingDetails={historyAuctionsStore.loadingDetails} />
                                    </Col>
                                    : null}
                            </>
                    }
                </Row>
                :
                null}
            {historyPageStore.dataSourceType === "orders" ?
                <Row className='justify-content-center g-3 mt-2'>
                    {historyOrdersStore.loadingData ?
                        <LoadingDataComponent height="300px" />
                        :
                        historyOrdersStore.historyOrders.length === 0 ?
                            <Col md={8} className='mt-3'>
                                <Alert variant='info' className='text-center shadow'>
                                    <>
                                        <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                        <br />
                                        {t('ordersNotFound')}
                                    </>
                                </Alert>
                            </Col>
                            :
                            <>
                                <Col xs={12} className='mt-0' >
                                    <div className="shadow p-3 rounded bg-white">
                                        <HistoryOrderTable
                                            orders={historyOrdersStore.historyOrders}
                                            pagination={historyOrdersStore.pagination}
                                            sort={(val) => historyOrdersStore.handleSort(val)}
                                            queryParams={historyOrdersStore.pagingParams}
                                            selectedOrderId={historyOrdersStore.selectedOrderId}
                                            selectOrder={(id) => historyOrdersStore.setSelectedOrderId(id)}
                                            showAttachments={(val) => historyOrdersStore.setAttachmentsModalOrderId(val)}

                                        />
                                        {historyOrdersStore.pagination &&
                                            <TablePagination
                                                pagination={historyOrdersStore.pagination}
                                                changePage={historyOrdersStore.changePage}
                                            />
                                        }
                                    </div>
                                </Col>
                                {historyOrdersStore.orderDetails || historyOrdersStore.loadingDetails ?
                                    <Col xs={12}>
                                        <OrderDetailsPanel model={historyOrdersStore.orderDetails} loadingDetails={historyOrdersStore.loadingDetails} />
                                    </Col>
                                    : null}
                            </>
                    }
                </Row>
                :
                null
            }
            {historyAuctionsStore.attachmentsModalAuctionID !== null ?
                <AttachmentModal
                    show={historyAuctionsStore.attachmentsModalAuctionID !== null}
                    hideModal={() => historyAuctionsStore.setAttachmentsModalAuctionId(null)}
                    parentType="auction"
                    parentId={historyAuctionsStore.attachmentsModalAuctionID}
                /> : null}
            {historyOrdersStore.attachmentsModalOrderID !== null ?
                <AttachmentModal
                    show={historyOrdersStore.attachmentsModalOrderID !== null}
                    hideModal={() => historyOrdersStore.setAttachmentsModalOrderId(null)}
                    parentType="order"
                    parentId={historyOrdersStore.attachmentsModalOrderID}
                /> : null}
        </Container>
    )
})

