import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useStore } from '../../app/store/store';
import PriceTable from './PricesTable';



export default observer(function WinnerPanel() {
    const { finishedAuctionsStore } = useStore();
    const { t } = useTranslation();

    const hanndleMakeWinner = () => {
        finishedAuctionsStore.chooseWinner()
            .then(() => toast.success(t("winnerChosenSuccessfully")))
            .catch(() => toast.error(t("winnerChosenUnsuccessfully")));
    }

    return (
        <>
            <Row>
                <PriceTable selectingMode="winner" />
            </Row>
            <Row className="mt-3">
                <Col className="text-center">
                    <Button
                        disabled={!finishedAuctionsStore.selectedWinnerPrice}
                        variant="success"
                        onClick={() => hanndleMakeWinner()}
                        className="mx-1" >
                        {t("winner")}
                    </Button>
                    <Button
                        variant="secondary"
                        className="mx-1"
                        onClick={() => finishedAuctionsStore.setSelectedWinnerPrice(null)}>
                        {t("clear")}
                    </Button>
                    <Button
                        variant="secondary"
                        className="mx-1"
                        onClick={() => finishedAuctionsStore.setSelectedAuction(null)}>
                        {t("cancel")}
                    </Button>
                </Col>
            </Row>
        </>
    )
})