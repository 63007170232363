import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    size?: "sm" | "lg" | undefined,
    step?: number,
    value?: any,
    change: (e: ChangeEvent<HTMLInputElement>) => void,
    id: string,
    name: string,
    zeroAlwaysVisible?: boolean,
    min?: number,
    isInvalid?: boolean,
    className?: string | undefined
}

export default function IntegerInput({ size = undefined, step = 1, value, change, id, name, min = 0, isInvalid = false, zeroAlwaysVisible = false, className = undefined }: Props) {

    // check max .net integer length 
    // (becouse control property maxLength not working with type = "number")
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (parseInt(e.target.value) <= 2147483647 ||
            e.target.value === '' ||
            e.target.value === '0') {

            // remove leading zero
            if (e.target.value !== '') {
                e.target.value = parseInt(e.target.value.replace(/^0+/, '0')).toString();
            }
            change(e);
        }
    };

    return (
        <Form.Control
            id={id}
            name={name}
            size={size}
            type="number"
            min={min}
            step={step}
            value={zeroAlwaysVisible ? value || '' || 0 : value || ''}
            isInvalid={isInvalid}
            onKeyPress={(e) => {
                (!/[0-9]/.test(e.key)) && e.preventDefault();
            }}
            onMouseUp={(e: any) => { (e.target.value < 0) && e.preventDefault(); }}
            onChange={handleChange}
            className={className}
        />
    )
}