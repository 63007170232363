import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { CarrierListItem } from "../models/carrierListItem";
import { ContractListItem } from "../models/contractListItem";
import { ContractType } from "../models/contractType";
import { ContractCreateUpdateDto } from "../models/contractCreateUpdateDto";
import { Currency } from "../models/currency";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsContractsTable } from "../models/queryParamsContractsTable";

export default class ContractsStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingData = true;
    setLoadingData = (val: boolean) => this.loadingData = val;

    contracts: ContractListItem[] = [];
    setContracts = (val: ContractListItem[]) => this.contracts = val;

    contractTypes: ContractType[] = [];
    setContractTypes = (val: ContractType[]) => this.contractTypes = val;

    currencies: Currency[] = [];
    setCurrencies = (val: Currency[]) => this.currencies = val;

    carriersHavingContract: CarrierListItem[] = [];
    setCarriersHavingContract = (val: CarrierListItem[]) => this.carriersHavingContract = val;

    selectedCarrier: CarrierListItem | undefined = undefined;
    setSelectedCarrier = (carrier: CarrierListItem | undefined) => this.selectedCarrier = carrier;

    selectedFormCarrier: CarrierListItem | undefined = undefined;
    setSelectedFormCarrier = (carrier: CarrierListItem | undefined) => this.selectedFormCarrier = carrier;

    selectedContract: ContractListItem | null = null
    setSelectedContract = (val: ContractListItem | null) => this.selectedContract = val;

    selectedTab: "addContract" | "editContract" | "deleteContract" = "addContract";
    setSelectedTab = (val: "addContract" | "editContract" | "deleteContract") => this.selectedTab = val;

    showSelectCarrierModal: boolean = false;
    setShowSelectCarrierModal = (val: boolean) => this.showSelectCarrierModal = val;

    pagingParams: QueryParamsContractsTable = new QueryParamsContractsTable(1, 10, "Cofor", "ASC", undefined);
    setPagingParams = (params: QueryParamsContractsTable) => this.pagingParams = params;

    pagination: PaginationModel | null = null;
    setPagination = (pagination: PaginationModel) => this.pagination = pagination;

    resetParams = () => this.setPagingParams(new QueryParamsContractsTable(1, 10, "Cofor", "ASC", undefined));

    carrierChangedFrom: "select" | "modal" = "select";
    setCarrierChangedFrom = (val: "select" | "modal") => this.carrierChangedFrom = val;

    blockAutoChangeFormCarrier: boolean = false;
    setBlockAutoChangeFormCarrier = (val: boolean) => this.blockAutoChangeFormCarrier = val;

    loadContracts = (reset?: boolean) => {
        try {
            return agent.Contracts.list(reset ? undefined : this.selectedCarrier?.id, this.getAxiosParams())
                .then(response => {
                    this.setContracts(response.data);
                    this.setPagination(response.pagination);
                })
                .then(() => this.setLoadingData(false))
        } catch (e) {
            console.log(e);
        }
    }

    addContract = (dto: ContractCreateUpdateDto) => {
        return agent.Contracts.add(dto)
            .then(() => {
                this.setSelectedContract(null);
                this.loadContracts();
                this.loadCarriersHavingContract();
            });
    }

    updateContract = (dto: ContractCreateUpdateDto) => {
        return agent.Contracts.update(this.selectedContract?.companyId!, this.selectedContract?.contractId!, dto)
            .then(() => {
                this.setSelectedContract(null);

                this.loadContracts();
                this.loadCarriersHavingContract();
                this.setSelectedFormCarrier(undefined);
                this.setSelectedTab("addContract");
            });
    }

    deleteContract = () => {
        return agent.Contracts.delete(this.selectedContract?.companyId!, this.selectedContract?.contractId!)
            .then(() => {
                if (this.contracts.filter(c => c.companyId === this.selectedContract?.companyId!).length <= 1) {
                    this.setSelectedCarrier(undefined);
                    this.setCarriersHavingContract([]);
                    this.loadCarriersHavingContract();
                }
                this.setSelectedTab("addContract");
                this.setSelectedContract(null);
                this.setBlockAutoChangeFormCarrier(false);
            });
    }

    loadCarriersHavingContract = () => {
        agent.Carriers.getOwnerCarrierList(true, false)
            .then((response) => this.setCarriersHavingContract(response));
    }

    loadContractTypes = () => {
        agent.Selections.contractTypes()
            .then(response => this.setContractTypes(response));
    }

    loadCurrencies = () => {
        agent.Selections.currencies().then(response => {
            this.setCurrencies(response);
        })
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }

        if (this.pagingParams.carrier !== undefined) {
            params.append('carrier', this.pagingParams?.carrier.toString());
        }

        return params;
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsContractsTable(this.pagingParams.pageNumber, this.pagingParams.pageSize, fieldName, sortDirection, this.pagingParams.carrier)
        );
        this.loadContracts();
    }

    handleChangePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsContractsTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection, this.pagingParams.carrier))
        this.loadContracts();
    }

    initValue: ContractCreateUpdateDto = {
        companyId: undefined,
        carrierName: '',
        name: "",
        contractTypeId: "D",
        price: 0,
        currencyId: ""
    }
}


