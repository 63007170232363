import React from 'react';
import { Pagination } from 'react-bootstrap';
import PaginationModel from '../../app/models/paginatedResult';

interface Props {
    size?: 'sm' | 'lg' | undefined,
    pagination: PaginationModel,
    changePage: (newPageNo: number) => void
}

export default function TablePagination({ size = undefined, pagination, changePage }: Props) {
    const { currentPage, totalPages } = pagination;
    const paginationItems: number[] = []

    let i: number = currentPage <= 5 ? 1 : currentPage - 4
    
    while (paginationItems.length < 8 && i <= totalPages) {
        paginationItems.push(i);
        i++;
    }

    return (
        <Pagination size={size} className="mb-0 mt-1 justify-content-center justify-content-md-start">
            <Pagination.First onClick={() => changePage(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1} />
            {paginationItems.map(item => (
                <Pagination.Item key={item} onClick={item === currentPage ? undefined : () => changePage(item)} active={item === currentPage} >{item}</Pagination.Item>
            ))}
            <Pagination.Next onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => changePage(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    )
}