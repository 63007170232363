import axios, { AxiosResponse } from "axios";
import { googleKey, OLSRouterURL } from '../../config';
import { GoogleAPILocation } from "../models/googleAPILocation";
import { OLSMapRouteModel } from "../models/olsMapRouteModel";

var axiosInstance = axios.create();
delete axiosInstance.defaults.headers.common['Authorization'];

axiosInstance.defaults.baseURL = OLSRouterURL;
delete axiosInstance.defaults.headers.common['Authorization']

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axiosInstance.get<T>(url).then(responseBody)
}

const OLSRoute = {
    get: (srcLat: number, srcLon: number, dstLat: number, dstLon: number) => requests.get<OLSMapRouteModel>(`${OLSRouterURL}/route/v1/driving/${srcLat},${srcLon};${dstLat},${dstLon}?overview=false&alternatives=false&steps=false`)
}

const Google = {
    getLocationCoordinates: (street: string, city: string, countryCode: string) => requests.get<GoogleAPILocation>(`https://maps.google.com/maps/api/geocode/json?address=${street},${city.replace(" ", "+")}&components=country:${countryCode}&sensor=false&key=${googleKey}`)
}

const externalAgent = {
    OLSRoute,
    Google
}

export default externalAgent;

