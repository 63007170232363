import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Image, Spinner } from 'react-bootstrap';
import UserLoginDtoValidator from '../../app/models/validators/userLoginDtoValidator';
import { useStore } from '../../app/store/store';
import logo from '../../data/img/logo.png'
import ValidationErrorList from '../error/ValidationErrorList';


export default observer(function LoginForm() {
    const initValues = { login: '', password: '', error: null }
    const { userStore } = useStore();
    const [apiErrors, setApiErrors] = useState(null);
    const [validateOnChange, setValidateOnChange] = useState(false)

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-white shadow rounded bg-white">
                            <Card.Header className='p-4'>
                                <Image fluid src={logo} />
                            </Card.Header>
                            <div className="card-body px-5 pt-5 pb-3">
                                <Formik
                                    initialValues={initValues}
                                    validateOnChange={validateOnChange}
                                    validateOnBlur={true}
                                    validate={values => new UserLoginDtoValidator().validate(values)}
                                    onSubmit={(values) => userStore.login(values)
                                        .catch(err => {
                                            setValidateOnChange(true);
                                            setApiErrors(err);
                                    })}
                                >
                                    {({ values: loginData, handleSubmit, isSubmitting, handleChange, errors, resetForm }) => (
                                        <Form onSubmit={handleSubmit} autoComplete='off' className='row g-3'>
                                            <Col xs={12}>
                                                <Form.Label htmlFor="login">Login:</Form.Label>
                                                <Form.Control
                                                    id='login'
                                                    name='login'
                                                    type='text'
                                                    maxLength={20}
                                                    className={errors.login || apiErrors ? "is-invalid" : undefined}
                                                    value={loginData.login}
                                                    onChange={handleChange} />
                                                <Form.Control.Feedback type='invalid' className="mt-0">{errors.login}</Form.Control.Feedback>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Label htmlFor="password">Password:</Form.Label>
                                                <Form.Control
                                                    id='password'
                                                    name='password'
                                                    type='password'
                                                    maxLength={35}
                                                    className={errors.password || apiErrors ? "is-invalid" : undefined}
                                                    value={loginData.password}
                                                    onChange={handleChange} />
                                                <Form.Control.Feedback type='invalid' className="mt-0">{errors.password}</Form.Control.Feedback>
                                            </Col>
                                            <Col xs={12} className='text-center'>
                                                <Button
                                                    variant='success'
                                                    className="mx-1"
                                                    type='submit'
                                                    onClick={() => setValidateOnChange(true)}
                                                >
                                                    {isSubmitting ? <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="me-1"
                                                    /> : null}
                                                    {isSubmitting ? 'Logging' : 'Log in'}
                                                </Button>
                                                <Button variant='secondary' className="mx-1" type='reset' onClick={() => {
                                                    setApiErrors(null);
                                                    setValidateOnChange(false);
                                                    resetForm();
                                                }} >Clear</Button>
                                            </Col>
                                            <Col xs={12} className='text-center mt-0' style={{ minHeight: "60px" }}>
                                                {apiErrors && 
                                                    <ValidationErrorList errorList={apiErrors} />}
                                            </Col>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})