import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AdditionalCost } from '../../app/models/additionalCost';
import storage from '../../app/store/storage';
import { getAdditionalCostsTypeName } from './functions/langObjectTranslation';

interface Props {
    additionalCosts: AdditionalCost[],
    currencySymbol: string
}

export default function AdditionalCostsList({ additionalCosts, currencySymbol }: Props) {
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    const getCostName = (cost: AdditionalCost) => {
        if (cost.hasCustomType) {
            return cost.description
        }
        return getAdditionalCostsTypeName(cost.additionalCostType, lang);
    }

    return (
        <Table responsive hover size="sm" className="table-content-centered">
            <thead className="table-light" >
                <tr>
                    <th>{t("name")}</th>
                    <th>{t("price")}</th>
                </tr>
            </thead>
            <tbody>
                {additionalCosts.map((cost, index) => (
                    <tr key={index}>
                        <td>{getCostName(cost)}</td>
                        <td>{cost.price} {currencySymbol}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}