import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function LoadingAppComponent() {

    const { t } = useTranslation();

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
                        <div className='row'>
                            <div className='col-12'>
                                <Spinner animation="border" variant="secondary" className='spinner-50px' />
                            </div>
                            <div className='col-12 mt-3'>
                                <h5>
                                    {t('loadingApp')}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}