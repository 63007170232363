import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { FollowUpFormModel } from "../FollowUpFormModel";

export default class FollowUpFormModelValidator extends Validator<FollowUpFormModel> {

    constructor() {
        super();

        this.ruleFor('name')
            .notEmpty().withMessage(i18next.t("valRequiredField"))

        this.ruleFor('email')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .emailAddress().withMessage(i18next.t("valEmailInvalidFormat"))

        this.ruleFor('langId')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
    }

}