import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import { pl, enGB, de } from "date-fns/locale";
import { useStore } from '../../app/store/store';
import { QueryParamsHistoryTable } from '../../app/models/queryParamsHistoryTable';
import { fixTimezoneForOnChangeCalendarEvent, fixTimezoneForSelectedCalendarDate } from '../common/functions/dateTimeManipulation';
import storage from '../../app/store/storage';

interface HistoryDataFilter {
    searchPhrase: string | undefined,
    searchIn: "OrderNo",
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    dateField: string | undefined,
    status: string | undefined,
}

export default function OrdersFilterForm() {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { t } = useTranslation();
    const { historyPageStore, historyOrdersStore } = useStore();

    const initData: HistoryDataFilter = {
        searchPhrase: undefined,
        searchIn: "OrderNo",
        dateFrom: undefined,
        dateTo: undefined,
        dateField: "PickupDateTime",
        status: "",
    }

    const handleSubmit = (val: HistoryDataFilter, actions: FormikHelpers<HistoryDataFilter>) => {
        const params = new QueryParamsHistoryTable(1, 10, "CreateDateTime", "DESC", val.searchPhrase == null ? undefined : val.searchPhrase, val.searchIn, val.dateFrom, val.dateTo, val.dateField, val.status)
        storage.LocalStorage.set("orders-filter-form-params", params);

        historyOrdersStore.setPagingParams(params);
        historyOrdersStore.loadHistoryOrders();
    }

    const getInitData = () => {
        var retrievedObject: HistoryDataFilter = storage.LocalStorage.get("orders-filter-form-params");
        if (retrievedObject) {
            return retrievedObject;
        }
        else {
            return initData;
        }
    }

    const handleClearForm = () => {
        const params = new QueryParamsHistoryTable(1, 10, "CreateDateTime", "DESC");
        storage.LocalStorage.remove("orders-filter-form-params");

        historyOrdersStore.setPagingParams(params);
        historyOrdersStore.loadHistoryOrders();
    }

    const handleDataSourceTypeChanged = (val: string) => {
        if (val === "auctions" || val === "orders") {
            historyPageStore.setDataSourceType(val);
        }
    }

    registerLocale("pl", pl);
    registerLocale("en", enGB);
    registerLocale("de", de);
    const lang = storage.Lang.get();

    return (
        <Formik
            enableReinitialize
            initialValues={getInitData()}
            onSubmit={(val, actions) => handleSubmit(val, actions)}
            validateOnBlur={true}
            validateOnChange={validateOnChange}
        >
            {({ values: filterData, handleChange, handleSubmit, setFieldValue, errors, resetForm }) => (

                <Form>
                    <Row>
                        <Col xs={12} sm={6} md={4} xl={3} >
                            <Form.Label htmlFor="premInbPhrase">{t("dataType")}:</Form.Label>
                            <Form.Select
                                id="dateField"
                                aria-label="type of truck"
                                name="dateField"
                                onChange={(e) => handleDataSourceTypeChanged(e.target.value)}
                                value={historyPageStore.dataSourceType}
                            >
                                <option value={'auctions'} >{t("auctions")}</option>
                                <option value={'orders'} >{t("orders")}</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} md={4} xl={3} >
                            <Form.Label htmlFor="searchPhrase">{t("search")}:</Form.Label>
                            <Form.Control
                                type="text"
                                id="searchPhrase"
                                name="searchPhrase"
                                maxLength={20}
                                value={filterData.searchPhrase || ''}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} xl={3} >
                            <Form.Label htmlFor="searchIn">{t("searchIn")}:</Form.Label>
                            <Form.Select
                                id="searchIn"
                                aria-label="type of truck"
                                name="searchIn"
                                onChange={handleChange}
                                value={filterData.searchIn}
                                disabled
                            >
                                <option value={'OrderNo'} >{t("orderNo")}</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} md={4} xl={3} >
                            <Form.Label htmlFor="status">{t("status")}:</Form.Label>
                            <Form.Select
                                id="status"
                                aria-label="type of truck"
                                name="status"
                                onChange={handleChange}
                                value={filterData.status}
                            >
                                <option value={''} >{t("all")}</option>
                                <option value={'resolved'} >{t("resolved")}</option>
                                <option value={'cancelled'} >{t("cancelled")}</option>
                                <option value={'rejected'} >{t("rejected")}</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} md={4} xl={3} >
                            <Form.Label htmlFor="from">{t("from")}:</Form.Label>
                            <DatePicker
                                name="dateFrom"
                                className={!!errors.dateFrom ? "form-control is-invalid" : "form-control"}
                                selected={fixTimezoneForSelectedCalendarDate(filterData.dateFrom)}
                                onChange={(date: any) => setFieldValue('dateFrom', fixTimezoneForOnChangeCalendarEvent(date))}
                                todayButton={t('today')}
                                locale={lang}
                                dateFormat="dd.MM.yyyy HH:mm"
                                placeholderText="__.__.____ __:__"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                autoComplete="off"
                            />
                            <div className="text-danger fs-14px mt-4px" >{errors.dateFrom}</div>
                        </Col>
                        <Col xs={12} sm={6} md={4} xl={3} >
                            <Form.Label htmlFor="from">{t("to")}:</Form.Label>
                            <DatePicker
                                name="dateTo"
                                className={!!errors.dateTo ? "form-control is-invalid" : "form-control"}
                                selected={fixTimezoneForSelectedCalendarDate(filterData.dateTo)}
                                onChange={(date: any) => setFieldValue('dateTo', fixTimezoneForOnChangeCalendarEvent(date))}
                                todayButton={t('today')}
                                locale={lang}
                                dateFormat="dd.MM.yyyy HH:mm"
                                placeholderText="__.__.____ __:__"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                autoComplete="off"
                            />
                            <div className="text-danger fs-14px mt-4px" >{errors.dateTo}</div>
                        </Col>
                        <Col xs={12} sm={6} md={4} xl={3} >
                            <Form.Label htmlFor="dateField">{t("dateType")}:</Form.Label>
                            <Form.Select
                                id="dateField"
                                aria-label="type of truck"
                                name="dateField"
                                onChange={handleChange}
                                value={filterData.dateField}
                            >
                                <option value={'PickUpDateTime'} >{t("pickupdatetime")}</option>
                                <option value={'DeliveryDateTime'} >{t("deliverydatetime")}</option>
                                <option value={'CreateDateTime'} >{t("createDateTime")}</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} md={4} xl={3} className="text-center text-sm-start mt-4">
                            <Button className="mt-2 mx-1" type="submit" onClick={(e) => { e.preventDefault(); setValidateOnChange(true); handleSubmit(); }}>
                                {t("search")}
                            </Button>
                            <Button variant="secondary" className="mt-2 mx-1" onClick={() => { handleClearForm(); resetForm(); }}>
                                {t("clear")}
                            </Button>
                        </Col>
                    </Row>
                {/*    <PersistFormikValues name="orders-filter-form" />*/}
                </Form>
            )}
        </Formik>
    )
}