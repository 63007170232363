import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { CarrierListItem } from "../models/carrierListItem";
import { CarrierUserListItemDto } from "../models/carrierUserListItemDto";
import { CarrierUserCreateUpdateDto } from "../models/carrierUserCreateUpdateDto";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsCarriersUsersTable } from "../models/queryParamsCarrierUsersTable";

export default class CarrierUsersStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingData = true;
    setLoadingData = (loadingData: boolean) => this.loadingData = loadingData;

    users: CarrierUserListItemDto[] = [];
    setUsers = (users: CarrierUserListItemDto[]) => this.users = users;

    carriersHavingUsers: CarrierListItem[] = [];
    setCarriersHavingUsers = (val: CarrierListItem[]) => this.carriersHavingUsers = val;

    selectedCarrier: CarrierListItem | undefined = undefined;
    setSelectedCarrier = (carrier: CarrierListItem | undefined) => this.selectedCarrier = carrier;

    selectedFormCarrier: CarrierListItem | undefined = undefined;
    setSelectedFormCarrier = (carrier: CarrierListItem | undefined) => this.selectedFormCarrier = carrier;

    selectedUser: CarrierUserListItemDto | null = null;
    setSelectedUser = (user: CarrierUserListItemDto | null) => this.selectedUser = user;

    selectedTab: "addUser" | "editUser" | "deleteUser" = "addUser";
    setSelectedTab = (tabName: "addUser" | "editUser" | "deleteUser") => this.selectedTab = tabName;

    showSelectCarrierModal: boolean = false;
    setShowSelectCarrierModal = (val: boolean) => this.showSelectCarrierModal = val;

    pagingParams: QueryParamsCarriersUsersTable = new QueryParamsCarriersUsersTable(1, 10, "CompanyCofor", "ASC", undefined);
    setPagingParams = (params: QueryParamsCarriersUsersTable) => this.pagingParams = params;

    pagination: PaginationModel | null = null
    setPagination = (pagination: PaginationModel) => this.pagination = pagination;

    resetParams = () => this.setPagingParams(new QueryParamsCarriersUsersTable(1, 10, "CompanyCofor", "ASC", undefined));

    carrierChangedFrom: "select" | "modal" = "select";
    setCarrierChangedFrom = (val: "select" | "modal") => this.carrierChangedFrom = val;

    blockAutoChangeFormCarrier: boolean = false;
    setBlockAutoChangeFormCarrier = (val: boolean) => this.blockAutoChangeFormCarrier = val;

    loadUsers = (reset?: boolean) => {
        try {
            agent.Carriers.getOwnerUsers(reset ? undefined : this.selectedCarrier?.id, this.getAxiosParams())
                .then(response => {
                    this.setUsers(response.data);
                    this.setPagination(response.pagination);
                })
                .then(() => this.setLoadingData(false))
        } catch (e) {
            console.log(e);
        }
    }

    addUser = (user: CarrierUserCreateUpdateDto) => {
        return agent.Carriers.addUser(user)
            .then(() => {
                this.setSelectedUser(null);
                this.loadUsers();
                this.loadCarriersHavingUsers();
            })
    }

    updateUser = (user: CarrierUserCreateUpdateDto) => {
        return agent.Carriers.updateUser(this.selectedUser?.companyId!, this.selectedUser?.userId!, user)
            .then(() => {
                this.loadUsers();
                this.loadCarriersHavingUsers();
                this.setSelectedFormCarrier(undefined);
                this.setSelectedTab("addUser");
            })
            .finally(() => this.setSelectedUser(null));
    }

    deleteUser = (id: number) => {
        return agent.Carriers.deleteUser(id, this.selectedUser?.companyId!)
            .then(() => {
                if (this.users.filter(c => c.companyId === this.selectedUser?.companyId!).length <= 1) {
                    this.setSelectedCarrier(undefined);
                    this.setCarriersHavingUsers([]);
                    this.loadCarriersHavingUsers();
                }
                this.setSelectedTab("addUser");
                this.setSelectedUser(null);
                this.setBlockAutoChangeFormCarrier(false);
            })
            .finally(() => this.setSelectedUser(null));

    }

    loadCarriersHavingUsers = () => {
        agent.Carriers.getOwnerCarrierList(false, true)
            .then((response) => this.setCarriersHavingUsers(response));
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsCarriersUsersTable(1, this.pagingParams.pageSize, fieldName, sortDirection, this.pagingParams.carrier)
        );
        this.loadUsers();
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }

        if (this.pagingParams.carrier !== undefined) {
            params.append('carrier', this.pagingParams?.carrier.toString());
        }

        return params;
    }

    handleChangePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsCarriersUsersTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection, this.pagingParams.carrier))
        this.loadUsers();
    }

    initValue: CarrierUserCreateUpdateDto = {
        companyId: undefined,
        carrierName: '',
        name: "",
        mobilePhone: "",
        email: "",
        langId: "",
        auctions: true
    }

}