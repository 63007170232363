import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Approver } from "../models/approver";
import { Currency } from "../models/currency";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsTable } from "../models/queryParamsTable";

export default class ApproversStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingData = true;
    setLoadingData = (loadingData: boolean) => this.loadingData = loadingData;

    approvers: Approver[] = [];
    setApprovers = (approvers: Approver[]) => this.approvers = approvers;

    selectedApprover: Approver | null = null;
    setSelectedApprover = (approver: Approver | null) => this.selectedApprover = approver;

    selectedTab: "addApprover" | "editApprover" | "deleteApprover" = "addApprover";
    setSelectedTab = (tabName: "addApprover" | "editApprover" | "deleteApprover") => this.selectedTab = tabName;

    currencies: Currency[] = []
    setCurrencies = (currencies: Currency[]) => this.currencies = currencies;

    pagination: PaginationModel | null = null
    setPagination = (pagination: PaginationModel) => this.pagination = pagination;

    pagingParams: QueryParamsTable = new QueryParamsTable(1, 10, "Amount", "ASC");
    setPagingParams = (params: QueryParamsTable) => this.pagingParams = params;

    resetParams = () => this.setPagingParams(new QueryParamsTable(1, 10, "Amount", "ASC"));

    addApprover = (approver: Approver) => {
        return agent.Approvers.add(approver)
            .then(() => {
                this.setSelectedApprover(null);
                this.loadApprovers();
            })
    }

    updateApprover = (approver: Approver) => {
        return agent.Approvers.update(approver, this.selectedApprover?.amount!, this.selectedApprover?.currency!, this.selectedApprover?.email!)
            .then(() => {
                this.loadApprovers(approver);
                var modifiedApprover = this.approvers.find(a => a.amount === approver.amount && a.currency === approver.currency && a.email === approver.email)
                modifiedApprover && this.setSelectedApprover(modifiedApprover);
            })
            .catch(() => this.setSelectedApprover(null));
    }

    deleteApprover = () => {
        return agent.Approvers.delete(this.selectedApprover?.amount!, this.selectedApprover?.currency!, this.selectedApprover?.email!)
            .then(() => {
                this.loadApprovers();
                this.setSelectedTab("addApprover");
            })
            .finally(() => this.setSelectedApprover(null));
    }

    loadApprovers = (approver?: Approver) => {
        try {
            agent.Approvers.list(this.getAxiosParams())
                .then(response => {
                    this.setApprovers(response.data);
                    this.setPagination(response.pagination);
                })
                .then(() => this.setLoadingData(false)).then(() => {

                    var modifiedApprover = approver ? this.approvers.find(a => a.amount === approver.amount && a.currency === approver.currency && a.email === approver.email) : null;
                    modifiedApprover && this.setSelectedApprover(modifiedApprover);
                })
        } catch (e) {
            console.log(e);
        }
    }

    loadCurrencies = () => {
        try {
            agent.Selections.currencies().then(response => {
                this.setCurrencies(response);
            })
        } catch (e) {
            console.log(e);
        }
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsTable(this.pagingParams.pageNumber, this.pagingParams.pageSize, fieldName, sortDirection)
        );
        this.loadApprovers();
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }
        return params;
    }

    changePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection))
        this.loadApprovers();
    }

}