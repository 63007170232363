import moment from 'moment';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuctionDetailsForApproveWinnerDto } from '../../../app/models/auctionDetailsForApproveWinnerDto';
import { getSupplierCountryName, getModeName } from '../../common/functions/langObjectTranslation';
import PackageList from '../../common/PackageList';
import PartsList from '../../common/PartsList';

interface Props {
    model: AuctionDetailsForApproveWinnerDto;
}

export default function AuctionDetailsPanelApproveWinner({ model }:Props) {
    const { t } = useTranslation();

    const labelColumnClass = "text-end fw-bolder px-1 px-md-2";
    const valueColumnClass = "text-start align-self-center px-1 px-md-2";

    return (
        <Card className="text-center">
            <Card.Header className="fw-bold">
                <span>
                    {t("details")}
                </span>
            </Card.Header>
            <Card.Body>
                <Row>
                    {model.mode &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("mode")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {getModeName(model.mode, model.langId)}
                                </Col>
                            </Row>
                        </Col>
                    }
                    {model.followUpName &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("followupname")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.followUpName}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                {model.parts.length > 0 ?
                    <Row className="mt-2">
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={3} className="text-center text-md-end fw-bolder">
                                    {t("partsList")}:
                                </Col>
                                <Col xs={12} md={9} className="text-start px-0">
                                    <PartsList parts={model.parts} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : null}
                {model.packages.length > 0 ?
                    <Row className="mt-2">
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={3} className="text-center text-md-end fw-bolder">
                                    {t("packagesList")}:
                                </Col>
                                <Col xs={12} md={9} className="text-start px-0">
                                    <PackageList packages={model.packages} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : null}
                <Row>
                    {model.weight &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("weight")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.weight}
                                </Col>
                            </Row>
                        </Col>
                    }


                </Row>
                <Row>
                    {model.supplierDuns &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("supplierduns")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.supplierDuns}
                                </Col>
                            </Row>
                        </Col>
                    }
                    {model.supplierName &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("suppliername")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.supplierName}
                                </Col>
                            </Row>
                        </Col>
                    }

                </Row>
                <Row>
                    {model.supplierAddress &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("street")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.supplierAddress}
                                </Col>
                            </Row>
                        </Col>
                    }
                    {model.supplierCity &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("city")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.supplierCity}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                <Row>
                    {model.supplierZipCode &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("zipcode")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.supplierZipCode}
                                </Col>
                            </Row>
                        </Col>
                    }
                    {model.supplierCountry &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("country")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {getSupplierCountryName(model.supplierCountry, model.langId)}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("pickupdatetime")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {moment(new Date(model.pickUpDateTime!)).format("DD.MM.YYYY HH:mm")}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className={labelColumnClass} >
                                {t("deliverydatetime")}:
                            </Col>
                            <Col xs={6} className={valueColumnClass} >
                                {moment(new Date(model.deliveryDateTime!)).format("DD.MM.YYYY HH:mm")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {model.unloadingDock &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("unloadingdock")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.unloadingDock}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                <Row>
                    {model.remarks &&
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className={labelColumnClass} >
                                    {t("remarks")}:
                                </Col>
                                <Col xs={6} className={valueColumnClass} >
                                    {model.remarks}
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>)
}