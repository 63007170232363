import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ActiveAuctionStore from "./activeAuctionStore";
import UserStore from "./userStore";
import FinishedAuctionsStore from "./finishedAuctionsStore";
import CarrierUsersStore from "./carrierUsersStore";
import ApproversStore from "./approversStore";
import HistoryAuctionStore from "./historyAuctionsStore";
import ContractsStore from "./contractsStore";
import FollowUpStore from "./followUpStore";
import { HistoryPageStore } from "./historyPageStore";
import { HistoryOrdersStore } from "./historyOrdersStore";
import ActiveOrdersStore from "./activeOrdersStore";

interface Store {
    activeAuctionsStore: ActiveAuctionStore,
    activeOrdersStore: ActiveOrdersStore,
    approversStore: ApproversStore,
    carrierUsersStore: CarrierUsersStore,
    commonStore: CommonStore,
    contractsStore: ContractsStore,
    finishedAuctionsStore: FinishedAuctionsStore,
    followUpStore: FollowUpStore,
    historyPageStore : HistoryPageStore,
    historyAuctionsStore : HistoryAuctionStore,
    historyOrdersStore: HistoryOrdersStore,
    userStore: UserStore,
}

export const store: Store = {
    activeAuctionsStore: new ActiveAuctionStore(),
    activeOrdersStore: new ActiveOrdersStore(),
    approversStore: new ApproversStore(),
    carrierUsersStore: new CarrierUsersStore(),
    commonStore: new CommonStore(),
    contractsStore: new ContractsStore(),
    finishedAuctionsStore: new FinishedAuctionsStore(),
    followUpStore: new FollowUpStore(),
    historyPageStore: new HistoryPageStore(),
    historyAuctionsStore: new HistoryAuctionStore(),
    historyOrdersStore: new HistoryOrdersStore(),
    userStore: new UserStore (),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}