import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DurationFormModel } from '../../app/models/durationFormModel';
import DurationFormModelValidator from '../../app/models/validators/createPlayOffValidator';
import { useStore } from '../../app/store/store';
import IntegerInput from '../common/IntegerInput';

interface Props {
    SubmitDisabled : boolean
}

export default observer(function PlayOffForm({ SubmitDisabled } : Props) {
    const { finishedAuctionsStore } = useStore();
    const { t } = useTranslation();
    const [validateOnChange, setValidateOnChange] = useState(false);

    const initValues: DurationFormModel = {
        duration: finishedAuctionsStore.selectedAuction ? finishedAuctionsStore.selectedAuction.duration : null
    }

    const handleSubmit = (val: DurationFormModel, actions: FormikHelpers<DurationFormModel>) => {
        finishedAuctionsStore.makePlayOff(val.duration!)
            .then(() => toast.success(t("playOffBegun")))
            .catch(() => toast.error(t("playOffBegunUnsuccessfully")));
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initValues}
                onSubmit={(val, actions) => handleSubmit(val, actions)}
                onReset={() => { finishedAuctionsStore.setSelectedPlayOffPrices([]); setValidateOnChange(false); }}
                validate={formValues => new DurationFormModelValidator().validate(formValues)}
                validateOnChange={validateOnChange}
                validateOnBlur={true}
            >   
                {({ values: value, handleChange, errors, resetForm, handleSubmit }) => (
                    <Form onSubmit={ handleSubmit }>
                        <Col md={4}>
                            <Form.Label htmlFor="duration" className="required">{t("duration")}:</Form.Label>
                            <IntegerInput
                                id="duration"
                                name="duration"
                                change={handleChange}
                                value={value.duration}
                                isInvalid={!!errors.duration}
                            />
                            <Form.Control.Feedback type='invalid'>{errors.duration}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <Button
                                disabled={SubmitDisabled}
                                variant="success"
                                onClick={(e) => { setValidateOnChange(true); e.preventDefault(); handleSubmit();   }}
                                className="mx-1"
                                type="submit" >
                                {t("makePlayoff")}
                            </Button>
                            <Button
                                variant="secondary"
                                className="mx-1"
                                onClick={() => resetForm()}>
                                {t("clear")}
                            </Button>
                            <Button
                                variant="secondary"
                                className="mx-1"
                                onClick={() => finishedAuctionsStore.setSelectedAuction(null)}>
                                {t("cancel")}
                            </Button>
                        </Col>
                    </Form>
                    )}
           
            </Formik>
        </>
    )
})