import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { Approver } from "../approver";

class ApproverValidator extends Validator<Approver>
{
    constructor() {
        super();

        this.ruleFor('currency')
            .notNull().withMessage(i18next.t("valRequiredField"))
            .maxLength(3).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "3"));

        this.ruleFor('email')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .emailAddress().withMessage(i18next.t("valEmailInvalidFormat"))
            .maxLength(100).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "100"));

        this.ruleFor('name')
            .notEmpty().withMessage(i18next.t("valRequiredField"))
            .maxLength(100).withMessage(i18next.t("valMaxLength").replace("#maxLength#", "100"));

    }
}

export default ApproverValidator