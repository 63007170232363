import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import { CaretRightFill, ClipboardCheck, HandIndexThumb, PersonFillSlash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import agent from '../../app/api/agent';
import { CarrierListItem } from '../../app/models/carrierListItem';
import customHistory from "../..";

interface Props {
    show: boolean,
    setShow: (show: boolean) => void;
    setValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    selectCarrier: (contract: CarrierListItem | undefined) => void;
    selectedCarrierId: number | undefined;
    limitedToContainsContracts: boolean;
    blockWithoutContactUsers: boolean;
}

export default observer( function SelectCarrierModal({ show, setShow, setValue, selectCarrier, selectedCarrierId, limitedToContainsContracts = false, blockWithoutContactUsers = false }: Props) {
    const [carriers, setCarriers] = useState<CarrierListItem[]>([]);
    const [inputFilterPhrase, setinputFilterPhrase] = useState<string>("");
    const [usedFilterPhrase, setUsedFilterPhrase] = useState<string>("");
    const [isDownloading, setIsDownloading] = useState(true);

    const getCarriersData = () => {
        agent.Carriers.getOwnerCarrierList(limitedToContainsContracts, false)
            .then(response => { setCarriers(response); })
            .finally(() => setIsDownloading(false))
    }

    function handleSelectClick(id: number, name: string, carrier: CarrierListItem) {
        selectCarrier(carrier);

        setCarriers([]);
        setShow(false);
    }

    const { t } = useTranslation();

    const handleSearchInputChange = (e: any) => {
        setinputFilterPhrase(e.target.value);
        setUsedFilterPhrase(e.target.value);
    }

    function handleClearClick() {
        setinputFilterPhrase("");
        setUsedFilterPhrase("");
    }

    const handleCloseClick = () => {
        setShow(false);
        setCarriers([]);
    }

    const tooltipMessage = <>
        {t("noRecipientsInfo")}
        <strong >
            {t("settings")}
            <CaretRightFill />
            {t("carriersusers")}.
        </strong>
    </>

    const carrierItemBlocked = (carrier: CarrierListItem) => {
        if (blockWithoutContactUsers) {
            return selectedCarrierId === carrier.id || carrier.containsContactUsers === false;
        }
        else {
            return selectedCarrierId === carrier.id
        }
    }

    const rowClassName = (carrier: CarrierListItem) => {
        if (blockWithoutContactUsers) {
            return carrier.containsContactUsers === false ? "text-secondary" : selectedCarrierId === carrier.id ? "selected-table-row fw-bolder" : undefined
        }
        else {
            return selectedCarrierId === carrier.id ? "selected-table-row fw-bolder" : undefined
        }
    }

    const redirectToUserSettings = (carrier: CarrierListItem) => {
        customHistory.push("/settings/contact");
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} onShow={getCarriersData} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <ClipboardCheck className='me-1 pb-1' height={30} width={30} />
                    {t('selectCarrierFromList')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-1 px-sm-2 px-md-3 modal-font-responsive">
                <Container>
                    <Row className="mb-2">
                        <Col lg={7} className="px-2">
                            <InputGroup>
                                <Form.Control
                                    placeholder={t('search')}
                                    aria-label="Search supplier"
                                    value={inputFilterPhrase}
                                    onChange={(e) => handleSearchInputChange(e)}
                                />
                                <Button variant="outline-secondary" onClick={handleClearClick}>{t('clear')}</Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="minh-200px--maxh-30vh">
                        {isDownloading ?
                            <Col className="px-0">
                                <div style={{ height: "100%" }} className="d-flex flex-column">
                                    <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                                        <Spinner className="spinner-100px" animation="border" variant="secondary" />
                                    </div>
                                </div>
                            </Col>
                            :
                            <Col className="px-0">
                                <Table className="table-header-centered responsive-table-padding">
                                    <thead className="sticky-top top-0 bg-white" >
                                        <tr>
                                            <th><span className="invisible-on-mobile">{t('select')}:</span></th>
                                            <th style={{ whiteSpace: "nowrap" }}>{t('cofor')}:</th>
                                            <th style={{ whiteSpace: "nowrap" }}>{t('carrierName')}:</th>
                                            {blockWithoutContactUsers ? <th></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {carriers
                                            .filter(elem => elem.name.toUpperCase().includes(usedFilterPhrase.toUpperCase()) || elem.cofor?.toUpperCase().includes(usedFilterPhrase.toUpperCase()))
                                            .map((carrier, index) => (
                                                <tr
                                                    key={index}
                                                    className={rowClassName(carrier)}>
                                                    <td>
                                                        <Button
                                                            size="sm"
                                                            variant="outline-primary"
                                                            className="invisible-on-mobile"
                                                            disabled={carrierItemBlocked(carrier)}
                                                            onClick={() => handleSelectClick(carrier.id, carrier.name, carrier)}>{t('select')}
                                                        </Button>
                                                        <span
                                                            className={carrierItemBlocked(carrier) ?
                                                                'cursor-not-allowed mx-1 visible-on-mobile text-secondary'
                                                                :
                                                                'cursor-pointer mx-1 visible-on-mobile text-primary'}
                                                            onClick={ carrierItemBlocked(carrier) ?
                                                                undefined
                                                                :
                                                                () => handleSelectClick(carrier.id, carrier.name, carrier)}
                                                        >
                                                            <span style={{ whiteSpace: 'nowrap', textDecoration: "none" }}>
                                                                <HandIndexThumb className='me-1' height={21} width={21} />
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>{carrier.cofor?.replace("��", "\xa0\xa0")}</td>
                                                    <td>{carrier.name}</td>
                                                    {blockWithoutContactUsers ?
                                                        <td>
                                                            {carrier.containsContactUsers === false ?
                                                                <OverlayTrigger
                                                                    key={index}
                                                                    placement="left"
                                                                    overlay={
                                                                        <Tooltip color="primary" id={`tooltip-${index}`}>
                                                                            {tooltipMessage}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <PersonFillSlash className="text-danger cursor-pointer" width={24} height={24} onClick={() => redirectToUserSettings(carrier)} />
                                                                </OverlayTrigger>
                                                                : null}
                                                        </td>
                                                        : null}
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Col>
                        }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseClick}>
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
})