import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { AuctionActive } from "../models/auctionActive";
import PaginationModel from "../models/paginatedResult";
import { QueryParamsTable } from "../models/queryParamsTable";
import { store } from '../store/store';


export default class ActiveAuctionStore {
    auctions: AuctionActive[] = [];
    selectedAuction: AuctionActive | null = null;
    rowCommandAction: string | null = null;
    loadingData = true;

    pagination: PaginationModel | null = null
    pagingParams: QueryParamsTable = new QueryParamsTable(1, 10, "Left2End", "ASC");
    loadingNext: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadActiveAuctions = () => {
        agent.Auctions.active(this.getAxiosParams())
            .then(response => {
                this.setAuctions(response.data);
                this.setPagination(response.pagination);
                this.setLoadingData(false);
            })
            .then(() => this.setLoadingData(false))
            .catch()
            .finally(() => store.commonStore.selectedOwner);
    }

    handleSort = (fieldName: string | undefined) => {
        const sortDirection = this.pagingParams.sortBy !== fieldName ? "ASC" : this.pagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPagingParams(
            new QueryParamsTable(this.pagingParams.pageNumber, this.pagingParams.pageSize, fieldName, sortDirection)
        );
        this.loadActiveAuctions();
    }

    cancelSelectedAuction = (selectedReason: string) => {
        return agent.Auctions.cancel(this.selectedAuction?.auctionId!, Number(selectedReason)).then(() => {
            this.setSelectedAuction(null);
            this.setRowCommandAction(null);
            this.loadActiveAuctions();
        })
    }

    getAxiosParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams?.pageNumber.toString())
        params.append('pageSize', this.pagingParams?.pageSize.toString())

        if (this.pagingParams.sortBy !== undefined && this.pagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pagingParams?.sortBy.toString())
            params.append('sortDirection', this.pagingParams?.sortDirection.toString())
        }
        return params;
    }

    setAuctions = (auctions: AuctionActive[]) => {
        this.auctions = auctions;
    }

    setLoadingData = (loadingData: boolean) => {
        this.loadingData = loadingData;
    }

    setPagination = (pagination: PaginationModel) => {
        this.pagination = pagination;
    }

    setSelectedAuction = (auction: AuctionActive | null) => {
        this.selectedAuction = auction;
    }

    setRowCommandAction = (actionName: string | null) => {
        this.rowCommandAction = actionName;
    }

    setPagingParams = (params: QueryParamsTable) => {
        this.pagingParams = params;
    }

    setLoadingNext = (loadingNext: boolean) => {
        this.loadingNext = loadingNext;
    }

    changePage = (newPageNo: number) => {
        this.setPagingParams(new QueryParamsTable(newPageNo, this.pagingParams.pageSize, this.pagingParams.sortBy, this.pagingParams.sortDirection))
        this.loadActiveAuctions();
    }
}