import React, { useState } from 'react';
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Part } from '../../app/models/part';
import { InfoCircle } from 'react-bootstrap-icons';
import { Package } from '../../app/models/package';
import LoadingDataComponent from '../common/LoadingDataComponent';
import { useTranslation } from 'react-i18next';
import agent from '../../app/api/agent';
import PartsList from '../common/PartsList';
import PackageList from '../common/PackageList';
import { AttachmentListItem } from '../../app/models/attachmentListItem';
import { AttachmentsList } from '../common/AttachmentsList';
import { getTransportTypeName } from '../common/functions/langObjectTranslation';
import storage from '../../app/store/storage';
import { AuctionActive } from '../../app/models/auctionActive';

interface Props {
    show: boolean,
    auction: AuctionActive,
    setShow: (show: boolean) => void;
}

export default function ActiveAuctionDetailsModal({ show, auction, setShow }: Props) {
    const [parts, setParts] = useState<Part[]>([]);
    const [packages, setPackages] = useState<Package[]>([]);
    const [attachments, setAttachments] = useState<AttachmentListItem[]>([]);
    const handleClose = () => setShow(false);
    const [isDownloading, setIsDownloading] = useState(true);
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    function getTablesData() {
        setIsDownloading(true);
        agent.Parts.auctionParts(auction.auctionId)
            .then(reponse => {
                setParts(reponse.data);
            })

        agent.Packages.auctionPackages(auction.auctionId)
            .then(reponse => {
                setPackages(reponse.data);
            });

        agent.File.auctionAttachments(auction.auctionId)
            .then(response => { setAttachments(response); })
            .then(() => setIsDownloading(false));
    }


    return (
        <Modal show={show} onHide={handleClose} onShow={getTablesData} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <InfoCircle className='me-1 pb-1' height={30} width={30} />
                    {t("auctionDetails")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-font-responsive">
                {isDownloading ?
                    <Row>
                        <Col>
                            <LoadingDataComponent height="240px" />
                        </Col>
                    </Row>
                    :
                    <>
                        <Container>
                            <Row>
                                <Col className="px-0 px-sm-2 px-md-3">
                                    {parts.length === 0 ?
                                        <Alert variant="secondary" className="text-center">
                                            <InfoCircle height={24} width={24} />
                                            <br />
                                            {t("noPartsInAuction")}
                                        </Alert>
                                        :
                                        <>
                                            <h5>{t("partsList")}:</h5>
                                            <PartsList parts={parts} />
                                        </>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="px-1 px-sm-2 px-md-3 modal-font-responsive">
                                    {packages.length === 0 ?
                                        <Alert variant="secondary" className="text-center">
                                            <InfoCircle height={24} width={24} />
                                            <br />
                                            {t("noPackagesInAuction")}
                                        </Alert>
                                        :
                                        <>
                                            <h5>{t("packagesList")}:</h5>
                                            <PackageList packages={packages} />
                                        </>
                                    }
                                </Col>
                            </Row>
                            {attachments.length > 0 ?
                                <Row className="mb-3">
                                    <Col className="px-0 px-sm-2 px-md-3">
                                    <h5 className="mb-0">{t("attachments")}:</h5>
                                        <AttachmentsList attachments={attachments} />
                                    </Col>
                                </Row>
                                : null}
                        </Container>
                    </>}
                <Row>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <strong>{t("transportType")}: </strong><span>{getTransportTypeName(auction.transportType, lang)}</span>
                    </Col>
                    <Col xs={12}>
                        <strong>{t("totalweight")}: </strong><span>{auction.weight} kg</span>
                    </Col>
                    <Col xs={12}>
                        <strong>{t("currency")}: </strong><span>{auction.currency.currencySymbol}</span>
                    </Col>
                    <Col xs={12}>
                        <strong>{t("remarks")}: </strong>{auction.remarks ? auction.remarks : '---'}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}