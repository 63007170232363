import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import agent from '../../app/api/agent';
import { CancellationReason } from '../../app/models/cancellationReason';
import storage from '../../app/store/storage';
import { useStore } from '../../app/store/store';
import { getCancellationReasonText } from '../common/functions/langObjectTranslation';

interface Props {
    objectType: "auction" | "order",
    show: boolean,
    setShow: (show: boolean) => void
}

export default function ConfirmAuctionCancellationModal({ objectType, show, setShow }: Props) {
    const [cancellationReasons, setCancellationReasons] = useState<CancellationReason[]>([]);
    const [selectedReason, setSelectedReason] = useState<string>("X")
    const { activeAuctionsStore, activeOrdersStore } = useStore();
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    function getCancellationReasons() {
        agent.Selections.cancellationReason().then(response => {
            setCancellationReasons(response);
        });
    }

    function handleSelectChange(e: any) {
        setSelectedReason(e.target.value);
    }

    const handleDeleteAuction = () => {
        if (objectType === "auction") {
            activeAuctionsStore.cancelSelectedAuction(selectedReason)
                .then(() => toast.success(t("auctionCancelledSuccessfully")))
                .catch(() => toast.error(t("auctionCancelledUnsuccessfully")))
        }
        else if (objectType === "order") {
            activeOrdersStore.cancelSelectedOrder(selectedReason)
                .then(() => toast.success(t("orderCancelledSuccessfully")))
                .catch(() => toast.error(t("orderCancelledUnsuccessfully")))
        }
    }

    const confirmText = objectType === "auction" ? t("askConfirmCancelAuction") : t("askConfirmCancelOrder");

    return (
        <Modal show={show} onHide={() => setShow(false)} onEnter={getCancellationReasons} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <ExclamationTriangle className='me-1 pb-1' height={30} width={30} />
                    {t("alert")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col className='my-2 text-center text-danger fw-bold'>
                            <p className="text-danger fw-bold">{confirmText}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label htmlFor="cancellationReason">{t("selectCancelationReason")}:</Form.Label>
                            <Form.Select id="cancellationReason" aria-label="Cancellation reasons" onChange={handleSelectChange}>
                                <option value="X">{t("chooseReason")}</option>
                                {cancellationReasons.map((cr, index) => (
                                    <option key={index} value={cr.cancelationReasonId}>{getCancellationReasonText(cr, lang)}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={selectedReason === "X"} variant="danger" onClick={() => handleDeleteAuction()}>
                    {t("yes")}
                </Button>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}