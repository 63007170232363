import { makeAutoObservable, runInAction } from "mobx";
import customHistory from "../..";
import Logout from "../../features/users/Logout";
import i18n from "../../i18Next";
import agent from "../api/agent";
import { LogoutDto } from "../models/logoutDto";
import { UpdateUserLanguage } from "../models/updateUserLanguage";
import { User } from "../models/user";
import { UserLoginDto } from "../models/userLoginDto";
import { store } from "./store";



export default class UserStore {
    user: User | null = null;
    selectedOwner: string | null = null;

    setSelectedOwner = (val: string) => {
        this.selectedOwner = val;
    }

    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn() {
        return !!this.user
    }

    login = async (creds: UserLoginDto) => {
        try {
            const user = await agent.Account.login(creds)
                
            i18n.changeLanguage(user.langId.toLowerCase());
            localStorage.setItem('langID', user.langId)
            runInAction(() => this.user = user);
            customHistory.push("/auctions/active")

            if (user.role === "GMDB") {
                store.commonStore.setSelectedOwner(user.supportedPlants[0].ownerId.toString());
                store.commonStore.setSelectedPlantName(user.supportedPlants[0].companyName);
            } else {
                if (user.supportedPlants.length > 0) {
                    if (!(user.supportedPlants.some(p => p.ownerId.toString() === window.localStorage.getItem('ownerId')))) {
                        store.commonStore.setSelectedOwner(user.supportedPlants[0].ownerId.toString());
                        store.commonStore.setSelectedPlantName(user.supportedPlants[0].companyName);
                    }
                }
            }

        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        if (this.user) {
            const logoutDto: LogoutDto = {
                userId: this.user.userId,
                userSource: this.user.source
            }

            agent.Account.logout(logoutDto);
        }

        this.user = null;
        customHistory.push("/login")
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => this.user = user)
        } catch (e) {
            console.log(e);
        }
    }

    changeLanguage = async (lang: UpdateUserLanguage) => {
        var promise = agent.Account.updateLanguage(lang);
        localStorage.setItem('langID', lang.langId)
        this.getUser();
        return promise;
    }
}