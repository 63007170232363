import React, { ChangeEvent } from 'react';
import { Form, Table } from 'react-bootstrap';
import { PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { AdditionalCostType } from '../../app/models/additionalCostType';
import { CreateAdditionalCostDto } from '../../app/models/createAdditionalCostDto';
import { getadditionalCostsName, getCurrencySymbol } from './functions/langObjectTranslation';
import PriceInput from './PriceInput';

interface Props {
    additionalCostsListData: AdditionalCostType[],
    newOrderAdditionalCosts: CreateAdditionalCostDto[], 
    onChange: (a: any, val: any) => void; 
    initialNewAdditionalCostData: CreateAdditionalCostDto;
    newAdditionalCost: CreateAdditionalCostDto; 
    setNewAdditionalCost: (pck: CreateAdditionalCostDto) => void;
    currencyId: string;
    lang: string;
    setSelectedAdditionalCosts: (additionalCosts: CreateAdditionalCostDto[]) => void;
    costChange: (costs: CreateAdditionalCostDto[]) => void;
}

export default function AddingAdditionalCostsModule({ additionalCostsListData, newOrderAdditionalCosts, onChange, newAdditionalCost, setNewAdditionalCost, initialNewAdditionalCostData, currencyId, lang, setSelectedAdditionalCosts, costChange }: Props) {

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewAdditionalCost({ ...newAdditionalCost, [name]: value })
    }

    const handleSelectChange = (event:  ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setNewAdditionalCost({ ...newAdditionalCost, [name]: value })
    }

    const handleAddKeyPress = (e: any) => {
        if (e.key === "Enter" ) {
            isNewServiceCorrect() && handleAddNewService(newAdditionalCost)
        }
    }

    const handleAddNewService = (newService: CreateAdditionalCostDto) => {
        newOrderAdditionalCosts.push(newService);
        costChange(newOrderAdditionalCosts);
    }

    const handleDeleteService = (index: number) => {
        var costToRemove = newOrderAdditionalCosts[index];
        var newServices = newOrderAdditionalCosts.filter(item => item !== costToRemove)
        costChange(newServices);
    }

    const isNewServiceCorrect = () => {
        return newAdditionalCost.additionalCostTypeId !== "" &&
            newAdditionalCost.price
    }

    const { t } = useTranslation();
    const inputClassName = "input-min-width-80px";

    const getCostDescription = (id: string) => {
        const cost = additionalCostsListData.find(c => c.id === id);
        return getadditionalCostsName(cost!, lang);
    }

    const isOtherTypeCost = (id: string) => {
        return id === "CC";
    }

    return (
        <div className="table-responsive mt-0  table-content-centered">
            <Table size="sm" className="pt-0">
                <thead className="gray-bg">
                    <tr>
                        <th className="ps-2 ps-md-4">#</th>
                        <th className="ps-2">{t('description')}:</th>
                        {isOtherTypeCost(newAdditionalCost.additionalCostTypeId) ? <th className="ps-2"></th> : undefined}
                        <th className="ps-2">{t('price')}:</th>
                        <th className="ps-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {newOrderAdditionalCosts.map((service, i) => (
                        <tr key={i + 1}>
                            <td className="ps-2 ps-md-4">{i + 1}</td>
                            <td className="ps-2">{isOtherTypeCost(service.additionalCostTypeId) ? service.description : getCostDescription(service.additionalCostTypeId)}</td>
                            {isOtherTypeCost(newAdditionalCost.additionalCostTypeId) ? <td className="ps-2"></td> : undefined}
                            <td className="ps-2">{service.price}{getCurrencySymbol(currencyId)}</td>
                            <td>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <span className='cursor-pointer mx-1' onClick={() => handleDeleteService(i)}>
                                        <XCircleFill className='text-danger' height={22} width={22} />
                                    </span>
                                </span>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td>
                            <Form.Select
                                id="additionalCostTypeId"
                                size="sm"
                                aria-label="additional cost"
                                name="additionalCostTypeId"
                                value={newAdditionalCost.additionalCostTypeId}
                                onChange={handleSelectChange}>
                                <option value={''} >{t("chooseOption")}</option>
                                {additionalCostsListData.map(cost => (
                                    <option key={cost.id} value={cost.id} >{getadditionalCostsName(cost, lang)}</option>

                                ))}
                            </Form.Select>
                        </td>
                        {isOtherTypeCost(newAdditionalCost.additionalCostTypeId) ?

                            <td>
                                <Form.Control
                                    id="description"
                                    name="description"
                                    size="sm"
                                    type="text"
                                    className={inputClassName}
                                    value={newAdditionalCost.description}
                                    onChange={handleInputChange}
                                    maxLength={50} />
                            </td>
                            : undefined}
                        <td>
                            <PriceInput
                                id="servicePrice"
                                name="price"
                                size="sm"
                                step={1}
                                className={inputClassName}
                                value={newAdditionalCost.price}
                                change={handleInputChange}
                                currency={currencyId}
                            />
                        </td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span
                                    className={isNewServiceCorrect() ? 'cursor-pointer mx-1' : 'mx-1'}
                                    onKeyPress={(e) => handleAddKeyPress(e)}
                                    onClick={() => isNewServiceCorrect() && handleAddNewService(newAdditionalCost)}
                                >
                                    <PlusCircleFill
                                        className={isNewServiceCorrect() ? 'text-success smooth-color-trans' : 'text-secondary smooth-color-trans'}
                                        height={22}
                                        width={22}
                                        tabIndex={0}
                                    />
                                </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}