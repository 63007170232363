import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Alert, Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { FollowUpSettingsListItem } from '../../../app/models/followUpSettingsListItem';
import { useStore } from '../../../app/store/store';
import LoadingDataComponent from '../../common/LoadingDataComponent';
import TablePagination from '../../common/TablePagination';
import DeleteFollowUpPanel from './DeleteFollowUpPanel';
import FollowUpForm from './FollowUpForm';
import FollowUpTable from './FollowUpTable';

export default observer(function NewComponent() {
    const { followUpStore, commonStore } = useStore();
    const { followUps, loadFollowUps, selectedFollowUp, setSelectedFollowUp, selectedTab, setSelectedTab, handleSort, resetPagingParams } = followUpStore;
    const { t } = useTranslation();

    useEffect(() => {
        resetPagingParams();
        loadFollowUps();
        // eslint-disable-next-line
    }, [commonStore.selectedOwner]);

    const handleTabSelect = (val: "addFollowUp" | "editFollowUp" | "deleteFollowUp") => {
        setSelectedTab(val);
    }

    function handleRowCommandButton(tabName: "addFollowUp" | "editFollowUp" | "deleteFollowUp", selectedFollowUp: FollowUpSettingsListItem | null) {
        setSelectedTab(tabName);
        setSelectedFollowUp(selectedFollowUp);
    }

    return (
        <>
            <Container fluid className='mt-3'>
                <Row className='justify-content-center g-3'>
                    {followUpStore.loadingData ?
                        < LoadingDataComponent height="300px" />
                        :
                        <>
                            <Col xs={12} lg={8} >
                                <div className="shadow p-3 rounded h-100 bg-white">
                                    <Row className="mt-2">
                                        <Col xs={12}>
                                            {
                                                followUpStore.followUps.length === 0 ?
                                                    <Col xs={12} className='mt-3 text-center'>
                                                        <Alert variant='info' className='text-center shadow'>
                                                            <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                                            <br />
                                                            {t('followupsNotFound')}
                                                        </Alert>
                                                    </Col>
                                                    :
                                                    <>
                                                        <FollowUpTable
                                                            followUps={followUps}
                                                            selectedFollowUp={selectedFollowUp}
                                                            selectedTab={selectedTab}
                                                            handleRowCommandButtonClick={handleRowCommandButton}
                                                            pagination={followUpStore.pagination}
                                                            sort={(val) => handleSort(val)}
                                                            queryParams={followUpStore.pagingParams}
                                                        />
                                                        {followUpStore.pagination &&
                                                            <TablePagination
                                                                pagination={followUpStore.pagination}
                                                                changePage={followUpStore.changePage}
                                                            />
                                                        }
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="shadow p-3 rounded h-100 bg-white">
                                    {followUpStore.selectedFollowUp ?
                                        <>
                                            <Tab.Container defaultActiveKey="editUser">
                                                <Row>
                                                    <Col xs={12}>
                                                        <Nav
                                                            variant="tabs"
                                                            justify={true}
                                                            onSelect={(val: any) => handleTabSelect(val)}
                                                        >
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    active={followUpStore.selectedTab === "editFollowUp"}
                                                                    eventKey="editFollowUp">
                                                                    {t("editFollowUp")}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    active={followUpStore.selectedTab === "deleteFollowUp"}
                                                                    eventKey="deleteFollowUp"
                                                                >
                                                                    {t("deleteFollowUp")}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Tab.Content>
                                                            <Tab.Pane
                                                                active={followUpStore.selectedTab === "editFollowUp"}
                                                                eventKey="editFollowUp"
                                                            >
                                                                <FollowUpForm />
                                                            </Tab.Pane>
                                                            <Tab.Pane
                                                                active={followUpStore.selectedTab === "deleteFollowUp"}
                                                                eventKey="deleteFollowUp"
                                                            >
                                                                <DeleteFollowUpPanel />
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>
                                        </>
                                        :
                                        <>
                                            <Row>
                                                <Col xs={12} className="text-center">
                                                    <p className="my-4 fs-5 fw-bold text-primary">{t("addNewFollowUp")}</p>
                                                </Col>
                                                <Col xs={12} >
                                                    <FollowUpForm />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </div>
                            </Col>
                        </>
                    }
                </Row>
            </Container>
        </>
    )
})