import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, InfoCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { OrderActiveListItem } from '../../app/models/orderActiveListItem';
import PaginationModel from '../../app/models/paginatedResult';
import { QueryParamsTable } from '../../app/models/queryParamsTable';
import storage from '../../app/store/storage';
import { getSupplierCountryName } from '../common/functions/langObjectTranslation';

interface Props {
    orders: OrderActiveListItem[],
    handleRowCommandButtonClick: (action: string, selectedOrderId: OrderActiveListItem | null) => void,
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsTable
}

export default function ActiveOrdersTable({ orders, handleRowCommandButtonClick, pagination, sort, queryParams }: Props) {
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    const columns = [
        { labelPhrase: "orderId", accessor: "OrderId" },
        { labelPhrase: "pickupdatetime", accessor: "PickupDateTime" },
        { labelPhrase: "deliverydatetime", accessor: "DeliveryDateTime" },
        { labelPhrase: "pickupDuns", accessor: "PickupDuns" },
        { labelPhrase: "pickupDuns", accessor: "PickupDuns" },
        { labelPhrase: "pickupCountry", accessor: "PickupCountry" },
        { labelPhrase: "status", accessor: "" },
        { labelPhrase: "", accessor: "" },
    ];

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            {Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)}
        </tr>
    )

    return (

        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => (
                        <th
                            key={index}
                            onClick={column.accessor
                                ? () => sort(column.accessor)
                                : undefined}
                            className={queryParams.sortBy === column.accessor
                                ? "th-sorted-by"
                                : undefined}
                            style={column.accessor
                                ? { cursor: "pointer" }
                                : undefined}
                        >
                            {t(column.labelPhrase)}
                            {queryParams.sortBy === column.accessor
                                ? queryParams.sortDirection === "ASC"
                                    ? <CaretUpFill className="ms-1" />
                                    : <CaretDownFill className="ms-1" />
                                : null}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {orders && orders.map((order) => (
                    <tr key={order.orderId}>
                        <td>{order.orderId}</td>
                        <td>{moment(new Date(order.pickupDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(new Date(order.deliveryDateTime)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{order.pickupDuns}</td>
                        <td>{getSupplierCountryName(order.pickupCountry, lang)}</td>
                        <td><span className="text-warning">{t("notApproved")}</span></td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span className='cursor-pointer mx-1' onClick={() => { handleRowCommandButtonClick('showDetailsModal', order) }}>
                                    <InfoCircleFill className='text-info' height={21} width={21} />
                                </span>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('showConfirmlDeleteModal', order)}>
                                    <XCircleFill className='text-danger' height={21} width={21} />
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
                {emptyRows}
            </tbody>
        </Table>

    )
}