import React, { useEffect } from 'react';
import { Alert, Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import LoadingDataComponent from '../common/LoadingDataComponent';
import { useStore } from '../../app/store/store';
import { InfoCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import FinishedAuctionsTable from './FinishedAuctionsTable';
import TablePagination from '../common/TablePagination';
import { observer } from 'mobx-react-lite';
import BeginAgainForm from './BeginAgainForm';
import CancelAuctionPanel from './CancelAuctionPanel';
import RefreshTableRemainingTime from '../common/RefreshTableRemainingTime';
import PlayOffPanel from './PlayOffPanel';
import WinnerPanel from './WinnerPanel';
import ApprovingWinningAuction from './ApprovingWinningAuctionPanel';
import { AuctionFinished } from '../../app/models/auctionFinished';
import { AttachmentModal } from '../common/AttachmentsModal';

export default observer(function FinishedAuctionPage() {
    const { finishedAuctionsStore, commonStore } = useStore();

    function handleDetailsButtonClick(action: string, selectedAuction: AuctionFinished | null) {
    }

    useEffect(() => {
        finishedAuctionsStore.loadFinishedAuctions();
        finishedAuctionsStore.setSelectedTab(finishedAuctionsStore.selectedTab);
    }, [finishedAuctionsStore, commonStore.selectedOwner])

    const { t } = useTranslation();

    const handleTabSelect = (val: "beginAgain" | "cancel" | "winner" | "playOff") => {
        finishedAuctionsStore.setSelectedTab(val);
    }

    const gridColumnsWidth = finishedAuctionsStore.selectedAuction ? 6 : 12;
    const cardColumnsWidth = 12 - gridColumnsWidth;

    const refreshDataTimerTick = () => {
        finishedAuctionsStore.loadFinishedAuctions();

        if (finishedAuctionsStore.selectedAuction) {
            if (finishedAuctionsStore.selectedAuction.finished === false && finishedAuctionsStore.selectedAuction.canceledFinishedDateTime !== null) {
                finishedAuctionsStore.loadSelectedAuctionApprovalListItems();
            }
        }
    }

    return (
        <Container fluid className='mt-3'>
            <Row className='justify-content-center g-3'>
                {finishedAuctionsStore.loadingAuctionsData ?
                    <LoadingDataComponent height="300px" />
                    :
                    finishedAuctionsStore.finishedAuctions.length === 0 ?
                        <>
                            <Col md={8} className='mt-3'>
                                <Alert variant='info' className='text-center shadow'>
                                    <span style={{ fontSize: 40 }}><InfoCircle /></span>
                                    <br />
                                    {t('finishedAuctionsNotFound')}
                                </Alert>
                            </Col>
                            <Col xs={8} className="text-center text-md-end mt-0">
                                <RefreshTableRemainingTime reloadFunction={() => refreshDataTimerTick()} />
                            </Col>
                        </>
                        :
                        <>
                            <Col xs={12} lg={gridColumnsWidth} >
                                <div className="shadow p-3 rounded bg-white">
                                    <FinishedAuctionsTable
                                        auctions={finishedAuctionsStore.finishedAuctions}
                                        handleRowCommandButtonClick={handleDetailsButtonClick}
                                        pagination={finishedAuctionsStore.auctionsPagination}
                                        sort={(val) => finishedAuctionsStore.handleAuctionsSort(val)}
                                        queryParams={finishedAuctionsStore.auctionsPagingParams}
                                        selectedAuction={finishedAuctionsStore.selectedAuction}
                                        selectAuction={(val) => finishedAuctionsStore.setSelectedAuction(val)}
                                        showAttachments={(val) => finishedAuctionsStore.setAttachmentsModalAuctionId(val)}
                                    />
                                    {finishedAuctionsStore.auctionsPagination &&
                                        <Row>
                                            <Col xs={12} md={6} className="">
                                                <TablePagination
                                                    pagination={finishedAuctionsStore.auctionsPagination}
                                                    changePage={finishedAuctionsStore.changePage}
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className="text-center text-md-end pt-2">
                                                <RefreshTableRemainingTime reloadFunction={() => refreshDataTimerTick()} />
                                            </Col>
                                        </Row>
                                    }

                                </div>
                            </Col>
                            {finishedAuctionsStore.selectedAuction &&
                                <Col lg={cardColumnsWidth}>
                                    {finishedAuctionsStore.selectedAuction &&
                                        <div className="shadow p-3 rounded h-100 bg-white">
                                            {finishedAuctionsStore.selectedAuction.finished === false && finishedAuctionsStore.selectedAuction.canceledFinishedDateTime !== null ?
                                                <>
                                                    <ApprovingWinningAuction />
                                                </>
                                                :
                                                <>
                                                    <Tab.Container defaultActiveKey="beginAgain">
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Nav
                                                                    variant="tabs"
                                                                    justify={true}
                                                                    onSelect={(val: any) => handleTabSelect(val)}
                                                                >
                                                                    <Nav.Item>
                                                                        <Nav.Link
                                                                            active={finishedAuctionsStore.selectedTab === "beginAgain"}
                                                                            eventKey="beginAgain">
                                                                            {t("beginAgain")}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link
                                                                            active={finishedAuctionsStore.selectedTab === "cancel"}
                                                                            eventKey="cancel"
                                                                        >
                                                                            {t("cancel")}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                    {finishedAuctionsStore.selectedAuction.pricesCount > 0 ?
                                                                        <>
                                                                            <Nav.Link
                                                                                active={finishedAuctionsStore.selectedTab === "winner"}
                                                                                eventKey="winner"
                                                                            >
                                                                                {t("chooseWinner")}
                                                                            </Nav.Link>
                                                                            <Nav.Link
                                                                                active={finishedAuctionsStore.selectedTab === "playOff"}
                                                                                eventKey="playOff"
                                                                            >
                                                                                {t("makePlayoff")}
                                                                            </Nav.Link>
                                                                        </>
                                                                        : null}
                                                                </Nav>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <Tab.Content>
                                                                    <Tab.Pane
                                                                        active={finishedAuctionsStore.selectedTab === "beginAgain"}
                                                                        eventKey="beginAgain"
                                                                    >
                                                                        <BeginAgainForm />
                                                                    </Tab.Pane>
                                                                    <Tab.Pane
                                                                        active={finishedAuctionsStore.selectedTab === "cancel"}
                                                                        eventKey="second"
                                                                    >
                                                                        <CancelAuctionPanel />
                                                                    </Tab.Pane>
                                                                    {finishedAuctionsStore.selectedAuction.pricesCount > 0 ?
                                                                        <>
                                                                            <Tab.Pane
                                                                                active={finishedAuctionsStore.selectedTab === "winner"}
                                                                                eventKey="second"
                                                                            >
                                                                                <WinnerPanel />
                                                                            </Tab.Pane>
                                                                            <Tab.Pane
                                                                                active={finishedAuctionsStore.selectedTab === "playOff"}
                                                                                eventKey="second"
                                                                            >
                                                                                <PlayOffPanel />
                                                                            </Tab.Pane>
                                                                        </>
                                                                        : null}
                                                                </Tab.Content>
                                                            </Col>
                                                        </Row>
                                                    </Tab.Container>
                                                </>
                                            }
                                        </div>
                                    }
                                </Col>
                            }
                        </>
                }
            </Row>
            {finishedAuctionsStore.attachmentsModalAuctionID !== null ?
                <AttachmentModal
                    show={finishedAuctionsStore.attachmentsModalAuctionID !== null}
                    hideModal={() => finishedAuctionsStore.setAttachmentsModalAuctionId(null)}
                    parentType="auction"
                    parentId={finishedAuctionsStore.attachmentsModalAuctionID}
                /> : null}
        </Container>
    )
})