import { t } from "i18next";
import { AdditionalCostType } from "../../../app/models/additionalCostType";
import { BillTo } from "../../../app/models/billTo";
import { CancellationReason } from "../../../app/models/cancellationReason";
import { ContractType } from "../../../app/models/contractType";
import { MessageType } from "../../../app/models/messageType";
import { Mode } from "../../../app/models/mode";
import { OrderReason } from "../../../app/models/orderReasonDto";
import { ResponsibleParty } from "../../../app/models/responsibleParty";
import { ServiceLevel } from "../../../app/models/serviceLevel";
import { Status } from "../../../app/models/status";
import { SupplierCountry } from "../../../app/models/supplierCountry";
import { TransportType } from "../../../app/models/transportType";

export const getTransportTypeName = (tt: TransportType, lang: string | undefined) => {
    if (lang === undefined) return tt.transportTypeEn;

    switch (lang.toLowerCase()) {
        case "en":
            return tt.transportTypeEn;
        case "de":
            return tt.transportTypeDe;
        case "pl":
            return tt.transportTypePl;
        default:
            return tt.transportTypeEn;
    }
}

export const getSupplierCountryName = (scc: SupplierCountry, lang: string | undefined) => {
    if (lang === undefined) return scc.supplierCountryCodeEn;

    switch (lang.toLowerCase()) {
        case "en":
            return scc.supplierCountryCodeEn;
        case "de":
            return scc.supplierCountryCodeDe;
        case "pl":
            return scc.supplierCountryCodePl;
        default:
            return scc.supplierCountryCodeEn;
    }
}

export const getModeName = (tt: Mode, lang: string | undefined) => {
    if (lang === undefined) return tt.modeEn;

    switch (lang.toLowerCase()) {
        case "en":
            return tt.modeEn;
        case "de":
            return tt.modeDe;
        case "pl":
            return tt.modePl;
        default:
            return tt.modeEn;
    }
}

export const getMessageTypeName = (mt: MessageType, lang: string | undefined) => {
    if (lang === undefined) return mt.nameEn;

    switch (lang.toLowerCase()) {
        case "en":
            return mt.nameEn;
        case "de":
            return mt.nameDe;
        case "pl":
            return mt.namePl;
        default:
            return mt.nameEn;
    }
}

export const getCurrencySymbol = (currencyID: string) => {
    if (currencyID === undefined) return '';

    switch (currencyID.toUpperCase()) {
        case "EUR":
            return "€";
        case "USD":
            return "$";
        case "GBP":
            return "£";
        case "PLN":
            return "zł";
        default:
            return currencyID;
    }
}

export const getCancellationReasonText = (reason: CancellationReason, lang: string | undefined) => {
    if (lang === undefined) return reason.cancelationReasonEn;

    switch (lang.toLowerCase()) {
        case "en":
            return reason.cancelationReasonEn;
        case "pl":
            return reason.cancelationReasonPl;
        case "de":
            return reason.cancelationReasonDe;
        default:
            return reason.cancelationReasonEn;
    }
}

export const getOrderReasonName = (reason: OrderReason, lang: string | undefined) => {
    if (lang === undefined) return reason.reasonEN;

    switch (lang.toLowerCase()) {
        case "en":
            return reason.reasonEN;
        case "pl":
            return reason.reasonPL;
        case "de":
            return reason.reasonDE;
        default:
            return reason.reasonEN;
    }
}


export const getServiceLevel = (sl: ServiceLevel, lang: string | undefined) => {
    if (lang === undefined) return sl.serviceLevelEN;

    switch (lang.toLowerCase()) {
        case "en":
            return sl.serviceLevelEN;
        case "de":
            return sl.serviceLevelDE;
        case "pl":
            return sl.serviceLevelPL;
        default:
            return sl.serviceLevelEN;
    }
}

export const getResponsiblePartyName = (rp: ResponsibleParty, lang: string | undefined) => {
    if (lang === undefined) return rp.responsiblePartyEN;

    switch (lang.toLowerCase()) {
        case "en":
            return rp.responsiblePartyEN;
        case "de":
            return rp.responsiblePartyDE;
        case "pl":
            return rp.responsiblePartyPL;
        default:
            return rp.responsiblePartyEN;
    }
}

export const getBillToName = (bt: BillTo, lang: string | undefined) => {
    if (lang === undefined) return bt.billToEN;

    switch (lang.toLowerCase()) {
        case "en":
            return bt.billToEN;
        case "de":
            return bt.billToDE;
        case "pl":
            return bt.billToPL;
        default:
            return bt.billToEN;
    }
}

export const getadditionalCostsName = (ac: AdditionalCostType, lang: string | undefined) => {
    if (lang === undefined) return ac.nameEn;

    switch (lang.toLowerCase()) {
        case "en":
            return ac.nameEn;
        case "de":
            return ac.nameDe;
        case "pl":
            return ac.namePl;
        default:
            return ac.nameEn;
    }
}

export const getAdditionalCostsTypeName = (ac: AdditionalCostType, lang: string | undefined) => {
    if (lang === undefined) return ac.nameEn;

    switch (lang.toLowerCase()) {
        case "en":
            return ac.nameEn;
        case "de":
            return ac.nameDe;
        case "pl":
            return ac.namePl;
        default:
            return ac.nameEn;
    }
}

export const getContractTypeName = (ct: ContractType, lang: string | undefined) => {
    if (lang === undefined) return ct.typeEN;

    switch (lang.toLowerCase()) {
        case "en":
            return ct.typeEN;
        case "de":
            return ct.typeDE;
        case "pl":
            return ct.typePL;
        default:
            return ct.typeEN;
    }
}

export const getStatusName = (status: Status, lang: string | undefined) => {
    if (lang === undefined) return status.nameEn;
    if (status === null) {
        return "";
    }

    switch (lang.toLowerCase()) {
        case "en":
            return status.nameEn;
        case "de":
            return status.nameDe;
        case "pl":
            return status.namePl;
        default:
            return status.nameEn;
    }
}

export const getContractName = (contractTypeId: string, contractName: string, contractPrice: number, currencySymbol: string) => {

    switch (contractTypeId) {
        case "D":
            return `${contractName} (${contractPrice}${currencySymbol}/km)`;
        case "W":
            return `${contractName} (${contractPrice}${currencySymbol}/kg)`;
        case "F":
            return `${contractName} (${t("fixedPrice")}: ${contractPrice}${currencySymbol})`;
        default:
            return `${contractName}`
    }
}