import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/layout/App';
import './app/layout/style.css';
import 'react-toastify/dist/ReactToastify.min.css'
import { store, StoreContext } from './app/store/store';
import { createBrowserHistory } from 'history';
import { CustomRouter } from './app/customRouter/customRouter';
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const customHistory = createBrowserHistory();
export default customHistory;

export interface BrowserRouterProps {
    basename?: string;
    children?: React.ReactNode;
    window?: Window;
}

root.render(
    <StoreContext.Provider value={store}>
        <CustomRouter history={customHistory}>
            <App />
        </CustomRouter >
    </StoreContext.Provider>

);

