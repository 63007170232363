import React, { useState } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import agent from '../../app/api/agent';
import { downloadFile } from '../../app/api/downloadFile';
import { AttachmentListItem } from '../../app/models/attachmentListItem';

interface Props {
    attachments: AttachmentListItem[]
}

export function AttachmentsList({ attachments }: Props) {
    const [downloadingID, setDownloadingID] = useState<number | undefined>(undefined);


    const handleDownloadButtonClick = (uploadId: number, fileName: string) => {
        setDownloadingID(uploadId);
        agent.File.download(uploadId)
            .then((response) => {
                downloadFile(response, fileName);
            })
            .finally(() => setDownloadingID(undefined));

    }

    return (
        <ListGroup variant="flush">
            {attachments.map((attachment: AttachmentListItem, i) => (
                <ListGroup.Item key={i} style={{ padding: "4px 8px" }}>
                    <span className='cursor-pointer text-decoration-none text-black mx-1' onClick={() => handleDownloadButtonClick(attachment.uploadId, attachment.fileName)} >
                        <span className="text-left">{i + 1}. {attachment.fileName} ({attachment.size})</span>
                        <span className="text-success fw-bold text-center ms-2" style={{ fontSize: 22 }}><Download /></span>
                        {downloadingID === attachment.uploadId ?
                            <span>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1 text-success"
                                />
                            </span>
                            : null}
                    </span>
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}