import React from 'react';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, PencilSquare, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { ContractListItem } from '../../../app/models/contractListItem';
import PaginationModel from '../../../app/models/paginatedResult';
import { QueryParamsContractsTable } from '../../../app/models/queryParamsContractsTable';
import storage from '../../../app/store/storage';
import { getContractTypeName } from '../../common/functions/langObjectTranslation';

interface Props {
    contracts: ContractListItem[],
    selectedContract: ContractListItem | null,
    selectedTab: "addContract" | "editContract" | "deleteContract"
    handleRowCommandButtonClick: (action: "addContract" | "editContract" | "deleteContract", selectedUser: ContractListItem | null) => void,
    isCarrierColumnsHidden: boolean,
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsContractsTable,
}

export default function ContractsTable({ contracts, selectedContract, selectedTab, handleRowCommandButtonClick, isCarrierColumnsHidden, pagination, sort, queryParams }: Props) {
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    const columns = [
        { labelPhrase: "cofor", accessor: "Cofor", hideWithCarrierColumn: true },
        { labelPhrase: "carrierName", accessor: "CompanyName", hideWithCarrierColumn: true },
        { labelPhrase: "contractName", accessor: "Name", hideWithCarrierColumn: false },
        { labelPhrase: "contractType", accessor: "ContractTypeId", hideWithCarrierColumn: false },
        { labelPhrase: "price", accessor: "Price", hideWithCarrierColumn: false },
        { labelPhrase: "", accessor: "", hideWithCarrierColumn: false }
    ]

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            {isCarrierColumnsHidden ?
                Array(columns.length - 2).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)
                :
                Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)
            }
        </tr>
    )

    return (
        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => {
                        if (!column.hideWithCarrierColumn || (!isCarrierColumnsHidden || !column.hideWithCarrierColumn)) {
                            return (<th key={index}
                                onClick={column.accessor
                                    ? () => sort(column.accessor)
                                    : undefined}
                                className={queryParams.sortBy === column.accessor
                                    ? "th-sorted-by"
                                    : undefined}
                                style={column.accessor
                                    ? { cursor: "pointer" }
                                    : undefined}
                            >
                                {t(column.labelPhrase)}
                                {queryParams.sortBy === column.accessor
                                    ? queryParams.sortDirection === "ASC"
                                        ? <CaretUpFill className="ms-1" />
                                        : <CaretDownFill className="ms-1" />
                                    : null}
                            </th>)
                        }
                        return null;
                    })}
                </tr>
            </thead>
            <tbody>
                {contracts.map(contract => (
                    <tr
                        key={contract.contractId}
                        className={contract.contractId === selectedContract?.contractId
                            ? selectedTab === "editContract" ? "selected-table-row" : "selected-table-row-delete"
                            : undefined}
                    >
                        {!isCarrierColumnsHidden && <td>{contract.companyCofor}</td>}
                        {!isCarrierColumnsHidden && <td>{contract.companyName}</td>}
                        <td>{contract.name}</td>
                        <td>{getContractTypeName(contract.contractType, lang)}</td>
                        <td>{contract.price} {contract.currency.currencySymbol}</td>
                        <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('editContract', contract)}>
                                    <PencilSquare className='text-info' height={21} width={21} />
                                </span>
                                <span className='cursor-pointer mx-1' onClick={() => handleRowCommandButtonClick('deleteContract', contract)}>
                                    <XCircleFill className='text-danger' height={21} width={21} />
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
                {emptyRows}
            </tbody>
        </Table>
    )
}

