import moment from "moment";

export const isNumericFieldEmpty = (val: number | null) => {
    return !(val?.toString() === '' || val?.toString() === undefined);
}

export const isIntegerGreatherThan = (val: number | null, minValue: number) => {
    if (val?.toString() === '') {
        return true;
    } else if (val?.toString() === null) {
        return true;
    } else {
        return Number(val) > minValue;
    }
}

export const endDateAfterStartDate = (startDate: Date | null, endDate: Date | null ) => {
    if (startDate === null || endDate === null) {
        return false;
    }
    return startDate < endDate;
}

export const endDateNotGreatherThanNoOfDays = (startDate: Date | null, endDate: Date | null, noOfDays: number) => {
    if (startDate === null || endDate === null) {
        return false;
    }
    return moment(startDate).add(noOfDays, 'day').toDate() > endDate;
}
