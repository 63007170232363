import moment from 'moment';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OrderApproveDetails } from '../../../app/models/orderApproveDetails';
import { getContractName, getBillToName, getContractTypeName, getModeName, getOrderReasonName, getResponsiblePartyName, getServiceLevel, getSupplierCountryName, getTransportTypeName } from '../../common/functions/langObjectTranslation';

interface Props {
    model: OrderApproveDetails;
}

export default function OrderDetailsPanelApproveWinner({ model} : Props) {
    const { t } = useTranslation();

    return (
        <Card className="text-center">
            <Card.Header className="fw-bold">
                <span>
                    {t("details")}
                </span>
            </Card.Header>
            <Card.Body>
                <Row>
                    {model.orderId ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("orderId")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.orderId}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                    {model.followUpName ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("requestedBy")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.followUpName}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("pickupdatetime")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {moment(new Date(model.pickupDateTime)).format("DD.MM.YYYY HH:mm")}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("deliverydatetime")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {moment(new Date(model.deliveryDateTime)).format("DD.MM.YYYY HH:mm")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("contract")}:
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("companyName")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.contractCompanyName}
                            </Col>
                        </Row>
                    </Col>
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("dunscofor")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.contractCompanyCode}
                                </Col>
                            </Row>
                        </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("contractName")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getContractName(model.contractType.typeId, model.contractName, model.contractPrice, model.currencySymbol)}
                            </Col>
                        </Row>
                    </Col>
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("contractType")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                {getContractTypeName(model.contractType, model.langId)}
                                </Col>
                            </Row>
                        </Col> 
                </Row>
                <Row className="mt-4">
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("totalPrice")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                <strong>{model.totalPrice} {model.currencySymbol}</strong>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("mode")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getModeName(model.mode, model.langId)}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("transportType")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getTransportTypeName(model.transportType, model.langId)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {model.weight ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("weight")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.weight} kg
                                </Col>
                            </Row>
                        </Col> : null
                    }
                    {model.distance ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("distance")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.distance} km
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("primaryReason")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getOrderReasonName(model.primaryReason, model.langId)}
                            </Col>
                        </Row>
                    </Col>
                    {model.secondaryReason ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("secondaryReason")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {getOrderReasonName(model.secondaryReason, model.langId)}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("serviceLevel")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getServiceLevel(model.serviceLevel, model.langId)}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("responsibleParty")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getResponsiblePartyName(model.responsibleParty, model.langId)} km
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("billTo")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getBillToName(model.billTo, model.langId)}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {getBillToName(model.billTo, model.langId)}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.billToValue}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {model.billAgreedWith ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("billAgreedWith")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.billAgreedWith}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                    {model.logisticRefNo ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("logisticRefNo")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.logisticRefNo}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    {model.remarks ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("remarks")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.remarks}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row className="mt-4">
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("pickupName")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.pickupName}
                            </Col>
                        </Row>
                    </Col>
                    {model.pickupDuns ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("pickupDuns")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.pickupDuns}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("pickupAddress")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.pickupAddress}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("pickupZipCode")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.pickupZipCode}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("pickupCity")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.pickupCity}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("pickupCountry")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getSupplierCountryName(model.pickupCountry, model.langId)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {model.pickupContactName ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("pickupContactName")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.pickupContactName}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                    {model.pickupContactPhoneNo ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("pickupContactPhoneNo")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.pickupContactPhoneNo}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    {model.pickupContactEmail ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("pickupContactEmail")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.pickupContactEmail}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row className="mt-4">
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("deliveryName")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.deliveryName}
                            </Col>
                        </Row>
                    </Col>
                    {model.deliveryDuns ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("deliveryDuns")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.deliveryDuns}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("deliveryAddress")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.deliveryAddress}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("deliveryZipCode")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.deliveryZipCode}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("deliveryCity")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {model.deliveryCity}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={6} className="text-end fw-bolder">
                                {t("deliveryCountry")}:
                            </Col>
                            <Col xs={6} className="text-start">
                                {getSupplierCountryName(model.deliveryCountry, model.langId)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {model.deliveryContactName ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("deliveryContactName")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.deliveryContactName}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                    {model.deliveryContactPhoneNo ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("deliveryContactPhoneNo")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.deliveryContactPhoneNo}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
                <Row>
                    {model.deliveryContactEmail ?
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={6} className="text-end fw-bolder">
                                    {t("deliveryContactEmail")}:
                                </Col>
                                <Col xs={6} className="text-start">
                                    {model.deliveryContactEmail}
                                </Col>
                            </Row>
                        </Col> : null
                    }
                </Row>
            </Card.Body>
        </Card>)
}