import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, HandIndexThumb, Search } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { OrderHistoryListItem } from '../../app/models/orderHistoryListItem';
import PaginationModel from '../../app/models/paginatedResult';
import { QueryParamsHistoryTable } from '../../app/models/queryParamsHistoryTable';
import storage from '../../app/store/storage';
import { getStatusName } from '../common/functions/langObjectTranslation';

interface Props {
    orders: OrderHistoryListItem[],
    pagination: PaginationModel | null,
    sort: (fieldName: string) => void,
    queryParams: QueryParamsHistoryTable,
    selectedOrderId: number | null,
    selectOrder: (id: number | null) => void,
    showAttachments: (orderId: number) => void
}

export function HistoryOrderTable({ orders, pagination, sort, queryParams, selectedOrderId, selectOrder, showAttachments }: Props) {
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    const columns = [
        { labelPhrase: "orderNo", accessor: "OrderNo" },
        { labelPhrase: "pickupdatetime", accessor: "PickupDateTime" },
        { labelPhrase: "deliverydatetime", accessor: "DeliveryDateTime" },
        { labelPhrase: "totalPrice", accessor: "TotalPrice" },
        { labelPhrase: "attachments", accessor: "NoOfAttachments" },
        { labelPhrase: "createDateTime", accessor: "CreateDateTime" },
        { labelPhrase: "status", accessor: "Status" },
        { labelPhrase: "", accessor: "" }
    ];

    const handleShowAttachmentsButtonClick = (orderId: number) => {
        showAttachments(orderId);
    }

    const calculateNoOfEmptyRowsToFillIn = () => {
        if (pagination!.currentPage * pagination!.itemsPerPage < pagination!.totalItems) return 0;
        return (pagination!.totalPages * pagination!.itemsPerPage) - pagination!.totalItems
    }

    const emptyRows = Array(calculateNoOfEmptyRowsToFillIn()).fill(0).map((_, i) =>
        <tr key={i}>
            {Array(columns.length).fill(0).map((_, j) => <td key={j}>&nbsp;</td>)}
        </tr>
    )

    return (
        <Table responsive striped bordered hover size="sm" className="table-content-centered unbreakable-cell-text">
            <thead className="gray-bg">
                <tr>
                    {columns.map((column, index) => (
                        <th
                            key={index}
                            onClick={column.accessor
                                ? () => sort(column.accessor)
                                : undefined}
                            className={queryParams.sortBy === column.accessor
                                ? "th-sorted-by"
                                : undefined}
                            style={column.accessor
                                ? { cursor: "pointer" }
                                : undefined}
                        >
                            {t(column.labelPhrase)}
                            {queryParams.sortBy === column.accessor
                                ? queryParams.sortDirection === "ASC"
                                    ? <CaretUpFill className="ms-1" />
                                    : <CaretDownFill className="ms-1" />
                                : null}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {orders.map((order, index) => (
                    <tr key={index} className={order.orderId === selectedOrderId ? "selected-table-row" : undefined}
                    >
                        <td>{order.orderNo}</td>
                        <td>{moment(new Date(order.pickupDateTime!)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(new Date(order.deliveryDateTime!)).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{order.totalPrice} {order.currencySymbol}</td>
                        <td>{order.noOfAttachments} {order.noOfAttachments > 0 ?
                            <span className='cursor-pointer' onClick={() => { handleShowAttachmentsButtonClick(order.orderId) }}>
                                <Search className='text-primary' height={21} width={21} />
                            </span>
                            : null}
                        </td>
                        <td>{moment(new Date(order.createDateTime!)).format("DD.MM.YYYY HH:mm")}</td>
                        <td className={order.status?.statusId !== "A" ? "text-danger" : undefined}>{getStatusName(order.status, lang)}</td>
                        <td>
                            <span
                                className='cursor-pointer mx-1'
                                onClick={() => { selectOrder(order.orderId); }}
                            >
                                <span style={{ whiteSpace: 'nowrap', textDecoration: "none" }}>
                                    <HandIndexThumb className='text-primary me-1' height={21} width={21} />
                                    {t("select").toLowerCase()}
                                </span>
                            </span>
                        </td>
                    </tr>
                )
                )}
                {emptyRows}
            </tbody>
        </Table>
    )
}