import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ContractType } from '../../../app/models/contractType';
import { ContractCreateUpdateDto } from '../../../app/models/contractCreateUpdateDto';
import ContractCreateUpdateValidator from '../../../app/models/validators/contractCreateUpdateValidator';
import { useStore } from '../../../app/store/store';
import { getContractTypeName, getCurrencySymbol } from '../../common/functions/langObjectTranslation';
import PriceInput from '../../common/PriceInput';
import ValidationErrorList from '../../error/ValidationErrorList';

interface Props {
    selectedId?: number | undefined;
    contractTypes: ContractType[];
}

export default observer(function ContractForm({ contractTypes }: Props) {
    const { t } = useTranslation();
    const storageLang = localStorage.getItem("langID");
    const lang = storageLang ? storageLang.toLowerCase() : "en";

    const { contractsStore, commonStore } = useStore();
    const { selectedContract, setSelectedContract,
        selectedFormCarrier, setSelectedFormCarrier,
        selectedTab, setSelectedTab,
        addContract, updateContract,
        setShowSelectCarrierModal, currencies,
        initValue, carrierChangedFrom,
        blockAutoChangeFormCarrier, setBlockAutoChangeFormCarrier    } = contractsStore;

    const [validateOnChange, setValidateOnChange] = useState(false);
    const [apiErrors, setApiErrors] = useState(null);

    const selectedContractValue: ContractCreateUpdateDto = {
        companyId: selectedContract?.companyId,
        carrierName: selectedContract?.companyName!,
        name: selectedContract?.name!,
        contractTypeId: selectedContract?.contractType.typeId!,
        price: selectedContract?.price!,
        currencyId: selectedContract?.currency.currencyId!
    }

    const getPriceCurrencyText = (newContract: ContractCreateUpdateDto) => {
        switch (newContract.contractTypeId) {
            case "D":
                return `${getCurrencySymbol(newContract.currencyId)} / km`
            case "W":
                return `${getCurrencySymbol(newContract.currencyId)} / kg`
            case "F":
                return `${getCurrencySymbol(newContract.currencyId)}`
            default:
                return `${getCurrencySymbol(newContract.currencyId)}`
        }
    }

    const handleSubmit = (val: ContractCreateUpdateDto, actions: FormikHelpers<ContractCreateUpdateDto>) => {
        if (selectedTab === "addContract") {
            addContract(val)
                .then(() => {
                    toast.success(t("contractAddedSuccessfully"));
                    actions.resetForm();
                })
                .catch((err) => {
                    toast.error(t("contractAddedUnsuccessfully"));
                    setApiErrors(err);
                })
                .finally(() => actions.setSubmitting(false))
        }
        else {
            updateContract(val)
                .then(() => {
                    toast.success(t("contractUpdatedSuccessfully"));
                    actions.resetForm();
                })
                .catch((err) => {
                    toast.error(t("contractUpdatedUnsuccessfully"));
                    setApiErrors(err);
                })
                .finally(() => actions.setSubmitting(false));
        }
    }

    useEffect(() => {
        document.getElementById("resetContractForm")?.click();
    }, [commonStore.selectedOwner])

    useEffect(() => {
        document.getElementById("trigger")?.click();
    }, [selectedFormCarrier])

    const isFormEmpty = (newContract: ContractCreateUpdateDto) => {
        return newContract.name === initValue.name &&
            newContract.contractTypeId === initValue.contractTypeId &&
            newContract.currencyId === initValue.currencyId &&
            newContract.price === initValue.price
    }

    const handleTriggerCall = (newContract: ContractCreateUpdateDto ,setValue: any, setFieldTouched: any) => {

        const copySelectedCarrierToForm: boolean = (selectedTab === "addContract" &&
            (!blockAutoChangeFormCarrier && isFormEmpty(newContract) && carrierChangedFrom === "select")) ||
            (carrierChangedFrom === "modal")

        if (copySelectedCarrierToForm) {
            setValue("companyId", selectedFormCarrier?.id);
            setValue("carrierName", selectedFormCarrier ? selectedFormCarrier.name : '');
            validateOnChange && setTimeout(() => setFieldTouched("companyId", true));
        }
    }

    const clearForm = () => {
        setApiErrors(null);
        setSelectedFormCarrier(undefined);
        setValidateOnChange(false);
        setBlockAutoChangeFormCarrier(false);
    }

    return (
            <Formik
            enableReinitialize
            validateOnChange={validateOnChange}
            validateOnBlur={true}
            validate={formValues => new ContractCreateUpdateValidator().validate(formValues)}
            initialValues={selectedContract ? selectedContractValue : initValue}
            onSubmit={(val, actions) => handleSubmit(val, actions)}
            onReset={() => clearForm()}
            >
            {({ values: newContract, handleChange, handleSubmit, setFieldValue, resetForm, errors, isSubmitting, setSubmitting, setFieldTouched }) => (
                    <Form>
                        <Row className="mt-3">
                            <Col xs={12}>
                                <Form.Label htmlFor="carrierId" className="required">{t('carrier')}:</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        id="carrierId"
                                        disabled={true}
                                        name="carrierId"
                                        value={newContract.carrierName}
                                        onChange={handleChange}
                                        maxLength={10}
                                        isInvalid={!!errors.companyId}
                                    />
                                    <Button variant="outline-secondary" disabled={selectedTab === "editContract"} id="selectCarrier" onClick={() => setShowSelectCarrierModal(true)} >
                                        {t('select')}
                                    </Button>
                                </InputGroup>
                                <div className="text-danger fs-14px mt-4px" >{errors.companyId}</div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={6} lg={12} xxl={6}>
                                <Form.Label htmlFor="name" className="required">{t('contractName')}:</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={newContract.name}
                                    onChange={handleChange}
                                    maxLength={100}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type='invalid' >{errors.name}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                                <Form.Label htmlFor="contractTypeId" className="required">{t('contractType')}:</Form.Label>
                                <Form.Select
                                    id="contractTypeId"
                                    name="contractTypeId"
                                    value={newContract.contractTypeId}
                                    onChange={handleChange}
                                    isInvalid={!!errors.contractTypeId}
                                >
                                    {contractTypes.map((c, index) => (
                                        <option key={index} value={c.typeId}>{getContractTypeName(c, lang)}</option>))}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>{errors.contractTypeId}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} md={6} lg={12} xxl={6}>
                                <Form.Label htmlFor="currencyId" className="required">{t('currency')}:</Form.Label>
                                <Form.Select
                                    id="currencyId"
                                    name="currencyId"
                                    value={newContract.currencyId}
                                    onChange={handleChange}
                                    isInvalid={!!errors.currencyId}
                                >
                                    <option value="">{t('choosecurrency')}</option>
                                    {currencies.map((c, index) => (
                                        <option key={index} value={c.currencyId}>{c.currencySymbol}</option>))}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>{errors.currencyId}</Form.Control.Feedback>
                            </Col>
                            <Col xs={12} md={6} lg={12} xxl={6} className="mt-3 mt-md-0 mt-lg-3 mt-xxl-0">
                                <Form.Label htmlFor="price" className="required">{t("totalPrice")}:</Form.Label>
                                <InputGroup>
                                    <PriceInput
                                        id="price"
                                        name="price"
                                        value={newContract.price}
                                        change={handleChange}
                                        isInvalid={!!errors.price}
                                        currency={newContract.currencyId}
                                        step={0.01}
                                        zeroAlwaysVisible={true}
                                        customCurrencyText={getPriceCurrencyText(newContract)}
                                    />

                                </InputGroup>
                                <div className="text-danger fs-14px mt-4px" >{errors.price}</div>
                            </Col>

                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="text-center">
                                <Button id="btnSubmit" variant="primary" className="mx-1" type="submit" onClick={(e: any) => { e.preventDefault(); setSubmitting(true); setValidateOnChange(true); handleSubmit(); }}>
                                    {isSubmitting ? <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-1"
                                    /> : null}
                                    {isSubmitting ? (t("saving")) : t("save")}
                                </Button>
                                {selectedTab === "addContract" ?
                                    <Button variant="secondary" id="resetContractForm" className="mx-1" type="reset" onClick={() => {
                                        resetForm({ values: initValue });
                                        clearForm();
                                    }} >
                                        {t("clear")}
                                    </Button>
                                    :
                                    <Button variant="secondary" className="mx-1" onClick={() => {
                                        setSelectedTab("addContract");
                                        setSelectedContract(null);
                                        setSelectedFormCarrier(undefined);
                                    }} >
                                        {t("cancel")}
                                    </Button>
                                }
                                <Button
                                    id="trigger"
                                    name="trigger"
                                    style={{ display: "none" }}
                                    onClick={(e) => { e.preventDefault(); handleTriggerCall(newContract, setFieldValue, setFieldTouched) }}>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="text-center">
                                {apiErrors !== null ?
                                    <ValidationErrorList errorList={apiErrors} /> : null}
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
    )
})