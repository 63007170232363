import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Approval } from "../models/approval";
import { BeginAgainFinishedAuction } from "../models/beginAgainFinishedAuction";
import { CreatePlayOff } from "../models/createPlayOff";
import { AuctionFinished } from "../models/auctionFinished";
import PaginationModel from "../models/paginatedResult";
import { PriceListItemDto } from "../models/priceListItemDto";
import { QueryParamsTable } from "../models/queryParamsTable";
import { WinnerPrice } from "../models/winnerPriceDto";

export default class FinishedAuctionsStore {
    finishedAuctions: AuctionFinished[] = [];
    selectedAuction: AuctionFinished | null = null;
    attachmentsModalAuctionID: number | null = null;
    selectedTab: "beginAgain" | "cancel" | "winner" | "playOff" = "cancel";
    loadingAuctionsData = true;
    loadingPricesData = true;
    selectedAuctionPrices: PriceListItemDto[] = [];
    selectedWinnerPrice: PriceListItemDto | null = null;
    selectedPlayOffPrices: PriceListItemDto[] = [];
    selectedAuctionWinner: WinnerPrice | null = null;
    selectedAuctionApprovalListItems: Approval[] = []

    auctionsPagination: PaginationModel | null = null
    auctionsPagingParams: QueryParamsTable = new QueryParamsTable(1, 10, "PickUpDateTime", "DESC");

    pricesPagination: PaginationModel | null = null
    pricesPagingParams: QueryParamsTable = new QueryParamsTable(1, 10, "Price", "DESC");

    loadingNext: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadFinishedAuctions = () => {
        try {
            return agent.Auctions.finished(this.getAxiosAuctionsParams())
                .then(response => {
                    this.setFinishedAuctions(response.data);
                    this.setAuctionsPagination(response.pagination);
                })
                .then(() => this.setLoadingAuctionsData(false))
        } catch (e) {
            console.log(e);
        }
    }

    loadAuctionPrices = () => {
        return agent.Prices.list(this.selectedAuction?.auctionId!, this.getAxiosPricesParams())
            .then(response => {
                this.setSelectedAuctionPrices(response.data);
                this.setPricesPagination(response.pagination);
            })
            .then(() => this.setLoadingPricesData(false))
    }

    loadAuctionWinner = () => {
        return agent.Prices.getWinnerPrice(this.selectedAuction?.auctionId!)
            .then(response => {
                this.setSelectedAuctionWinner(response);
            })
    }

    loadSelectedAuctionApprovalListItems = () => {
        return agent.Approvals.getApprovalListItems(this.selectedAuction?.auctionId!)
            .then(response => {
                this.setSelectedAuctionApprovalListItems(response);
            })
    }

    handleAuctionsSort = (fieldName: string | undefined) => {
        const sortDirection = this.auctionsPagingParams.sortBy !== fieldName ? "ASC" : this.auctionsPagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setAuctionsPagingParams(
            new QueryParamsTable(this.auctionsPagingParams.pageNumber, this.auctionsPagingParams.pageSize, fieldName, sortDirection)
        );
        this.loadFinishedAuctions();
    }

    handlePricesSort = (fieldName: string | undefined) => {
        const sortDirection = this.pricesPagingParams.sortBy !== fieldName ? "ASC" : this.pricesPagingParams.sortDirection === "ASC" ? "DESC" : "ASC";
        this.setPricesPagingParams(
            new QueryParamsTable(this.pricesPagingParams.pageNumber, this.pricesPagingParams.pageSize, fieldName, sortDirection)
        );
        this.loadAuctionPrices();
    }

    getAxiosAuctionsParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.auctionsPagingParams?.pageNumber.toString())
        params.append('pageSize', this.auctionsPagingParams?.pageSize.toString())

        if (this.auctionsPagingParams.sortBy !== undefined && this.auctionsPagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.auctionsPagingParams?.sortBy.toString())
            params.append('sortDirection', this.auctionsPagingParams?.sortDirection.toString())
        }
        return params;
    }

    getAxiosPricesParams = () => {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pricesPagingParams?.pageNumber.toString())
        params.append('pageSize', this.pricesPagingParams?.pageSize.toString())

        if (this.pricesPagingParams.sortBy !== undefined && this.pricesPagingParams.sortDirection !== undefined) {
            params.append('sortBy', this.pricesPagingParams?.sortBy.toString())
            params.append('sortDirection', this.pricesPagingParams?.sortDirection.toString())
        }
        return params;
    }

    beginAgainAuction = (dto: BeginAgainFinishedAuction) => {
        return agent.Auctions.beginAgain(this.selectedAuction?.auctionId!, dto)
            .then(() => {
                this.setSelectedAuction(null);
                this.loadFinishedAuctions();
            })
    }

    cancelAuction = (selectedReason: string) => {
        return agent.Auctions.cancel(this.selectedAuction?.auctionId!, Number(selectedReason))
            .then(() => {
                this.setSelectedAuction(null);
                this.loadFinishedAuctions();
            })
    }

    chooseWinner = () => {
        return agent.Prices.makeWinner(this.selectedAuction?.auctionId!, this.selectedWinnerPrice!)
            .then(() => {
                this.setSelectedAuction(null);
                this.loadFinishedAuctions();
            })
    }

    makePlayOff = (duration: number) => {
        var dto: CreatePlayOff = {
            auctionId: this.selectedAuction!.auctionId,
            duration: duration,
            prices: this.selectedPlayOffPrices
        }

        return agent.Auctions.makePlayOff(this.selectedAuction!.auctionId, dto)
            .then(() => {
                this.setSelectedAuction(null);
                this.loadFinishedAuctions();
                this.setSelectedPlayOffPrices([])
            });
    }

    setFinishedAuctions = (finishedAuctions: AuctionFinished[]) => {
        this.finishedAuctions = finishedAuctions;
    }

    setSelectedAuction = (newAuction: AuctionFinished | null) => {
        this.setSelectedTab("beginAgain");
        this.selectedAuction = newAuction;

        if (newAuction && newAuction.finished === false && newAuction.canceledFinishedDateTime !== null) {
            this.loadAuctionWinner();
            this.loadSelectedAuctionApprovalListItems();
        }
        else {
            newAuction && this.loadAuctionPrices();
            newAuction && this.setSelectedPlayOffPrices([]);
            newAuction && this.setSelectedWinnerPrice(null);
        }
    }

    setAttachmentsModalAuctionId = (auctionId: number | null) => {
        this.attachmentsModalAuctionID = auctionId;
    }

    setLoadingAuctionsData = (loadingData: boolean) => {
        this.loadingAuctionsData = loadingData;
    }

    setLoadingPricesData = (loadingData: boolean) => {
        this.loadingPricesData = loadingData;
    }

    setAuctionsPagination = (pagination: PaginationModel) => {
        this.auctionsPagination = pagination;
    }

    setPricesPagination = (pagination: PaginationModel) => {
        this.pricesPagination = pagination;
    }

    setAuctionsPagingParams = (params: QueryParamsTable) => {
        this.auctionsPagingParams = params;
    }

    setPricesPagingParams = (params: QueryParamsTable) => {
        this.pricesPagingParams = params;
    }

    setLoadingNext = (loadingNext: boolean) => {
        this.loadingNext = loadingNext;
    }

    setSelectedTab = (tabName: "beginAgain" | "cancel" | "winner" | "playOff") => {
        this.selectedTab = tabName;
    }

    changePage = (newPageNo: number) => {
        this.setAuctionsPagingParams(new QueryParamsTable(newPageNo, this.auctionsPagingParams.pageSize, this.auctionsPagingParams.sortBy, this.auctionsPagingParams.sortDirection))
        this.loadFinishedAuctions();
    }

    setSelectedAuctionPrices = (prices: PriceListItemDto[]) => {
        this.selectedAuctionPrices = prices;
    }

    setSelectedWinnerPrice = (price: PriceListItemDto | null) => {
        this.selectedWinnerPrice = price;
    }

    setSelectedPlayOffPrices = (prices: PriceListItemDto[]) => {
        this.selectedPlayOffPrices = prices;
    }

    setSelectedAuctionWinner = (winner: WinnerPrice) => {
        this.selectedAuctionWinner = winner;
    }

    setSelectedAuctionApprovalListItems = (list: Approval[]) => {
        this.selectedAuctionApprovalListItems = list;
    }
}