import { Validator } from "fluentvalidation-ts";
import i18next from "i18next";
import { CreatePlayOff } from "../createPlayOff";
import { DurationFormModel } from "../durationFormModel";
import { isIntegerGreatherThan, isNumericFieldEmpty } from "./validateFunctions";

class DurationFormModelValidator extends Validator<DurationFormModel> {
    constructor() {
        super();

        this.ruleFor('duration')
            .must(x => isNumericFieldEmpty(x)!)
            .withMessage(i18next.t("valRequiredField"))
            .when(x => x !== undefined)
            .must(x => isIntegerGreatherThan(x, 0)!)
            .withMessage(i18next.t("valValueGreatherThan").replace("#value#", "0"))
    }
}

export default DurationFormModelValidator;