import React from 'react'
import { EmojiFrown } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

export default function PageNotFoundComponent() {
    const { t } = useTranslation();

    return (
        <div className="vh-80 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
                        <div className='row'>
                            <div className='col-12' style={{ fontSize: '10em' }}>
                                <EmojiFrown  />
                            </div>
                            <div className='col-12 mt-3'>
                                <h1>
                                    {t('ups')}
                                </h1>
                            </div>
                            <div className='col-12'>
                                <h3>
                                    {t('pageNotFound')}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
}