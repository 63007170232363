import moment from 'moment';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PackageList from '../common/PackageList';
import PartsList from '../common/PartsList';
import ApprovalsList from '../common/ApprovalsList';
import MailingHistoryList from './MailingHistoryList';
import PriceList from '../common/PricesList';
import LoadinDataComponent from '../common/LoadingDataComponent';
import { getCancellationReasonText, getModeName } from '../common/functions/langObjectTranslation';
import storage from '../../app/store/storage';
import { AuctionDetailsForHistory } from '../../app/models/auctionDetailsForHistory';

interface Props {
    model: AuctionDetailsForHistory | null,
    loadingDetails: Boolean
}

export default function AuctionDetailsPanel({ model, loadingDetails }: Props) {
    const { t } = useTranslation();
    const lang = storage.Lang.get();

    return (
        <div className="shadow p-3 rounded h-100 bg-white">
            {loadingDetails ?
                <Card className="text-center">
                    <Card.Header className="fw-bold">{t("details")}</Card.Header>
                    <Card.Body>
                        <LoadinDataComponent height="260px" />
                    </Card.Body>
                </Card>
                :
                    model && 
                <>
                    <Card className="text-center">
                        <Card.Header className="fw-bold">{t("details")}</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Row>
                                            <Col xs={6} className="text-end fw-bolder">
                                                {t("auctionId")}:
                                            </Col>
                                            <Col xs={6} className="text-start">
                                                {model.auctionId}
                                            </Col>
                                        </Row>
                                    </Col>
                                    {model.orderNo &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("orderNo")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.orderNo}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {model.mode &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("mode")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                {getModeName(model.mode, lang)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {model.followUpName &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("followupname")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.followUpName}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {model.parts.length > 0 ?
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={12} md={3} className="text-center text-md-end fw-bolder">
                                                    {t("partsList")}:
                                                </Col>
                                                <Col xs={12} md={9} className="text-start px-0">
                                                    <PartsList parts={model.parts} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        : null}
                                </Row>
                                <Row>
                                    {model.packages.length > 0 ?
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={12} md={3} className="text-center text-md-end fw-bolder">
                                                    {t("packagesList")}:
                                                </Col>
                                                <Col xs={12} md={9} className="text-start px-0">
                                                    <PackageList packages={model.packages} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        : null}
                                </Row>
                                <Row>
                                    {model.weight &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("weight")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.weight}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {model.supplierDuns &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("supplierduns")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.supplierDuns}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {model.supplierName &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("suppliername")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.supplierName}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {model.supplierAddress &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("street")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.supplierAddress}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {model.supplierZipCode &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("zipcode")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.supplierZipCode}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {model.supplierCity &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("city")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.supplierCity}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {model.supplierCountry &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("country")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.supplierCountry.supplierCountryCodeEn}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Row>
                                            <Col xs={6} className="text-end fw-bolder">
                                                {t("pickupdatetime")}:
                                            </Col>
                                            <Col xs={6} className="text-start">
                                                {moment(new Date(model.pickUpDateTime!)).format("DD.MM.YYYY HH:mm")}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Row>
                                            <Col xs={6} className="text-end fw-bolder">
                                                {t("deliverydatetime")}:
                                            </Col>
                                            <Col xs={6} className="text-start">
                                                {moment(new Date(model.deliveryDateTime!)).format("DD.MM.YYYY HH:mm")}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    {model.unloadingDock &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("unloadingdock")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.unloadingDock}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {model.remarks &&
                                        <Col xs={12} lg={6}>
                                            <Row>
                                                <Col xs={6} className="text-end fw-bolder">
                                                    {t("remarks")}:
                                                </Col>
                                                <Col xs={6} className="text-start">
                                                    {model.remarks}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                {
                                    model.cancelationReason === null ?
                                        <Row>
                                            {model.canceledFinishedByUserName &&
                                                <Col xs={12} lg={6}>
                                                    <Row>
                                                        <Col xs={6} className="text-end fw-bolder">
                                                            {t("winnerChosenBy")}:
                                                        </Col>
                                                        <Col xs={6} className="text-start">
                                                            {model.canceledFinishedByUserName}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        :
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <Row>
                                                    <Col xs={6} className="text-end fw-bolder">
                                                        {t("cancelledBy")}:
                                                    </Col>
                                                    <Col xs={6} className="text-start">
                                                        {model.canceledFinishedByUserName}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Row>
                                                    <Col xs={6} className="text-end fw-bolder">
                                                        {t("cancellationReason")}:
                                                    </Col>
                                                    <Col xs={6} className="text-start">
                                                        {getCancellationReasonText(model.cancelationReason,lang)}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                }
                            </Card.Body>
                    </Card>
                    {model.prices.length > 0 &&
                        <Card className="text-center mt-2">
                            <Card.Header className="fw-bold">{t("prices")}</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        <PriceList prices={model.prices} currency={model.currency.currencyId} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>}
                    {model.approvals.length > 0 &&
                        <Card className="text-center mt-2">
                            <Card.Header className="fw-bold">{t("approvingWinningAuction")}</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        <ApprovalsList approvals={model.approvals} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>}
                    {model.mailingHistories.length > 0 && <Card className="text-center mt-2">
                        <Card.Header className="fw-bold">{t("mailingHistory")}</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <MailingHistoryList mails={model.mailingHistories} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>}
                </>
            
            }

            
        </div>
    )
}